.nw_banner h1 {
  padding: 35px 50px;
  text-align: center;
  color: #fff;
  font-size: 52px;
}

.nw_banner {
  /* background: url(../images/nw_banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 175px 0px;
  text-align: center;
}

.nw_banner button {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 150px;
  color: #fff;
}

.nw_own {
  background: #f9fbff;
  padding: 50px 30px;
  font-family: "Roboto Condensed", sans-serif;
}

.nw_own h2 span {
  font-size: 18px;
  color: #949495;
}

.nw_own h2 {
  font-size: 30px;
  color: #264350;
  font-weight: 600;
}

.nw_own p {
  font-size: 22px;
}

.nw_own button {
  background: #264350;
  border: 0;
  padding: 5px 25px;
  border-radius: 50px;
  color: #fff;
  margin-top: 20px;
}

.nw_own img {
  max-width: 90px;
}

.nw_own p {
  font-size: 22px;
  padding: 15px 0;
  letter-spacing: 0px;
}

.nw_wh_start {
  padding: 50px 30px;
  max-width: 991px;
  margin: 0 auto;
}

.nw_wh_start ul li img {
  max-width: 150px;
}

.nw_wh_start ul li {
  display: inline-block;
  padding: 12px 12px;
  margin: 20px 50px;
}

.nw_wh_start ul {
  padding: 0;
  text-align: center;
}

.nw_wh_start h3 {
  text-align: center;
}

.nw_wh_start h4 {
  color: #595858;
  padding: 10px 0px;
  max-width: 768px;
  text-align: left;
  margin: 0 auto;
  font-weight: 700;
}

.nw_wh_start button {
  padding: 10px 20px;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #ccc;
}

.nw_wh_start {
  text-align: center;
}

.nw_sports {
  max-width: 870px;
  margin: 0 auto;
}

.nw_sports .card,
.nw_featured .card {
  border: 0;
  position: relative;
  align-items: center;
}

.nw_sports .card-body,
.nw_featured .card-body {
  padding: 0;
}

.nw_sports .card-footer,
.nw_featured .card-footer {
  background: #00000073;
  border: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 25px 0;
}

.nw_sports .card-footer button,
.nw_featured .card-footer button {
  border: 1px solid #ccc;
  background: transparent;
  color: #fff;
  padding: 5px 25px;
  border-radius: 10px;
}

.nw_own1 {
  max-width: 100%;
  margin: 0 auto;
}

.nw_wh_start p {
  max-width: 768px;
  margin: 0 auto;
  font-size: 22px;
  text-align: left;
}

.nw_featured .card-footer {
  left: 5px;
}

.nw_featured .card-footer {
  max-width: 490px;
}

.nw_featured h2 {
  padding: 50px 0;
}

.nw_featured {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
}

.nw_next_purc {
  /* background: url(../images/nw_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

.nw_next_purc h3 {
  color: #fff;
  font-size: 30px;
  padding: 50px 5px;
  font-weight: 200;
}

.nw_next_purc p {
  color: #fff;
  padding: 50px 5px;
  font-size: 22px;
}

.nw_sports h3 {
  font-size: 39px;
}

.nw_next_purc_card {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0px;
  margin: 50px auto;
}

.nw_next_purc_card .input-group {
  max-width: 450px;
  margin: 0 auto;
}

.nw_next_purc_card #basic-addon2 {
  border: 0;
  border-radius: 0;
  background: #264350;
  color: #fff;
}

.nw_next_purc_card .form-control {
  border: 1px solid #ccc;
  border-radius: 0;
}

.nw_footer ul {
  padding: 0;
}

.nw_footer {
  text-align: center;
}

.nw_footer ul li {
  display: block;
  padding: 8px 12px;
}

.nw_footer button {
  color: #585757;
  background: transparent;
  border: 0;
  font-size: 22px;
  font-weight: 600;
}

.nw_footer ul li a {
  color: #585858;
  font-size: 16px;
}

.nw_social li {
  display: inline-block !important;
  border: 1px solid #ccc;
  align-items: center;
  text-align: center;
  padding: 15px 7px !important;
  vertical-align: middle;
  border-radius: 50px;
  width: 55px;
  margin: 15px 20px;
}

.nw_social li a i {
  color: #000;
}

.nw_slider p {
  position: absolute;
  top: 46%;
  width: 100%;
  text-align: center;
  font-size: 35px;
  color: #fff;
}

.nw_slider img {
  position: relative;
}

.nw_slider {
  padding: 60px 15px;
}

p.nw_top {
  margin: 0;
  text-align: center;
  padding: 5px 10px;
  background: #264350;
  color: #fff;
}

.nw_login {
  margin: 0;
  text-align: right;
  padding: 5px 15px;
  height: 34px;
}

.nw_megamenu {
  width: 100% !important;
}

.nw_form input {
  border-radius: 25px !important;
  width: 260px !important;
  height: 35px;
}

.nw_form a {
  color: #264350;
  font-size: 22px;
  padding: 5px 11px;
}

.nw_menu a {
  color: #2b2a2a;
  font-size: 20px;
}

.nw_menu span {
  color: #2b2a2a;
  font-size: 20px;
}

.nw_menu ul.navbar-nav {
  margin-left: 50px;
}

.sec_mnu {
  margin: 0 auto !important;
}

.nw_menu {
  padding-bottom: 30px;
}

.nw_menu .navbar-brand {
  font-size: 40px;
  font-weight: 400;
  line-height: 0;
}

.nw_login a {
  color: #656565;
  font-size: 14px;
}

.nw_hme {
  display: none;
}

.nw_mbl_form {
  display: none;
}

.nw_footer button:nth-child(1) {
  border-bottom: 1px solid transparent;
}

.nw_container {
  max-width: 991px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {

  #nw_mbl_icon,
  .nw_login_mbl {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  #nw_mbl_icon {
    display: inline;
    float: right;
  }

  .nw_menu .navbar-brand {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    right: -30%;
    top: 8px;
  }

  .navbar-toggler-icon {
    /* background: url(../images/nw_menu.png); */
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 33;
  }

  .nw_toggler {
    position: absolute;
    left: 0;
    top: 0;
  }

  .nw_login {
    height: 0px;
  }
}

@media only screen and (max-width: 768px) {

  .nw_social li {
    margin: 15px 8px;
  }

  .navbar-nav .dropdown-menu {
    border: 0;
  }

  .owl-carousel .owl-stage {
    left: -50px;
  }

  .nw_social {
    display: block !important;
    margin-top: 30px;
  }

  .nw_wh_start ul li {
    display: contents;
    padding: 12px 12px;
    margin: 20px 27px;
  }

  .nw_wh_start ul li img {
    max-width: 90px;
    margin: 0 10px;
  }

  .nw_wh_start ul {
    margin: 50px 0;
  }

  .nw_mbl_form {
    display: flex !important;
  }

  .nw_form {
    display: none;
  }

  .nw_megamenu {
    width: 75% !important;
  }

  .nw_login {
    display: none;
  }

  .nw_login_mbl {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 100;
  }

  .nw_login_mbl a {
    font-size: 14px;
  }

  /* .exchange,
  .copo {
    height: 400px;
    background-size: cover !important;
  } */
}

@media only screen and (max-width: 575px) {

  .nw_banner h1 {
    padding: 25px 50px;
    text-align: center;
    color: #fff;
    font-size: 23px;
  }

  .nw_banner {
    /* background: url(../images/banner_mobile.png); */
    width: 100%;
    height: fit-content;
    padding: 0;
    height: 450px;
    background-size: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
  }

  .nw_own img {
    max-width: 90px;
  }

  .nw_own h2 {
    font-size: 22px;
  }

  .nw_own p {
    font-size: 14px;
  }

  .nw_own h3 {
    font-size: 18px;
  }

  .nw_featured .card-footer h3 {
    font-size: 22px;
  }

  .nw_featured .card-footer {
    left: 0px;
  }

  .nw_next_purc_card {
    padding: 0;
  }

  .nw_next_purc h3 span {
    font-weight: 600;
  }

  .nw_footer button {
    font-size: 22px;
    font-weight: 600;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
  }

  .nw_banner_cnt {
    background: #00000063;
  }

  .nw_navbar_collpse {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    min-height: 991px;
    height: 100%;
    z-index: 100;
    overflow: auto;
    bottom: 1px;
    width: 80%;
  }

  .nw_mbl_form span {
    position: relative;
    left: 18px;
    margin: 0 8px;
  }

  .nw_menu ul.navbar-nav {
    margin-left: 35px !important;
  }

  .nw_form input {
    width: 180px !important;
    margin-left: 12px;
  }

  .nw_hme {
    display: block;
  }

  .nw_menu {
    padding-bottom: 26px;
  }

  .nw_megamenu ul li a {
    font-size: 16px;
  }

  .nw_megamenu ul li {
    display: block;
    padding: 6px 0;
  }

  .nw_megamenu ul {
    padding: 0;
  }

  #nw_mbl_icon {
    display: block;
    position: absolute;
    right: 12px;
    top: 5px;
  }

}

.exchange {
  /* background: url(../images/exchange.png); */
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 175px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .copo {
  background: url(../images/copo.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 175px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .exchange h1,
.copo h1 {
  padding: 35px 50px;
  text-align: center;
  color: #fff;
  font-size: 52px;
} */

/* .exchange p,
.copo p {
  color: #fff;
  /* font-size: 22px; */
/* padding: 15px 0; */
/* letter-spacing: 0px; */


*/ .ex_content p {
  /* font-size: 18px; */
  /* padding: 15px 0; */
  letter-spacing: 0px;
}

.ex_content h1 {
  padding: 35px 50px;
  text-align: center;
  font-size: 52px;
}

.story video {
  width: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  right: 40px;
}

.vh_20 {
  height: 20vh;
}