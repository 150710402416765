.pi_elements_container .element {
    border-bottom: 1px solid #e3e3e3;
    height: 100px;
    padding: 23px 10px;
    cursor: pointer;
}

.pi_elements_container .element div img {
    width: 80%;
}

.pi_elements_container .element div.text-uppercase {
    font-size: 20px;
}

.hide {
    display: none;
}

/* .active {
  display: flex;
} */

.pi_elements_container .element .pi_dropdown {
    position: absolute;
    width: 160px;
    min-height: 100px;
    padding: 5px 16px;
    background: #fff;
    border: 1px solid #e3e3e3;
    text-align: center;
    right: -90px;
    z-index: 99999999;
    margin-top: -80px;
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}

.pi_elements_container .element .element_container_parent {
    width: 100%;
    min-height: 70px;
}

.pi_elements_container .element .pi_dropdown .head {
    border-bottom: 1px solid #e3e3e3;
    text-align: left;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item {
    padding: 5px;
    border-bottom: 1px solid #e3e3e3;
    margin-top: 10px;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item:last-child {
    border: none;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item:hover {
    background: #f1f1f1;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_dismiss {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999999;
    border: 1px solid #e3e3e3;
    padding: 6.5px 6px;
    border-radius: 0px !important;
}

.pi_sidebar_toggle_dismiss {
    display: none;
}

.cloth_container {
    overflow: auto;
    padding: 15px 5px;
}

.cloth_container .cloth_elements {
    list-style: none;
    padding-left: 0;
}

.cloth_container .cloth_elements .cloth_el {
    display: inline-block;
    width: 110px;
    margin: 0 5px;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #f0f0f0;
}

.cloth_container .cloth_elements .cloth_el:hover {
    border: #444;
    box-shadow: 0px 0px 4px 0px #969696;
}

.cloth_container .cloth_elements .cloth_el img {
    width: 100%;
}

.pi_fabric_previewer img {
    margin-top: 35%;
}

.pi_fabric_previewer button {
    text-align: center;
    width: 49%;
}

li.pi_selected_option:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background-size: cover;
    margin-left: 90px;
    margin-top: -10px;
}

li.pi_selected_option.fit_el:before {
    margin-left: 20px !important;
    margin-top: 15px !important;
}

.pi_selection_overview li {
    padding: 10px 5px;
    border-bottom: 1px solid #e3e3e3;
}

.pi-card-body {
    min-height: 650px;
}

.pi_sidebar_toggle_dismiss {
    position: absolute;
    top: 4px;
    right: -40px;
}

/* .modal-body.zoom-canvas {
  height: 95vh;
  overflow: auto;
} */

.zoom-canvas .pi-shirt-containers {
    height: 150vh;
    overflow: auto;
}

.blazer-canvas .pi_container .custom_shirt {
    top: 100px;
}

.zoom_full_width .custom_shirt {
    top: 10px;
}

@media only screen and (max-width: 900px) {
    .pi_sidebar_floating {
        display: none;
        position: absolute;
        top: -50px;
        left: -15px;
        z-index: 99999999;
        background: #ffffff;
        max-width: 300px;
        height: 100vh;
    }

    .pi_toggler {
        display: block;
    }

    .pi_sidebar_toggle_dismiss {
        display: block;
    }

    .pi_elements_container .element {
        height: 80px;
        padding: 15px 10px;
    }

    .pi_elements_container .element .pi_dropdown {
        top: 125px;
    }

}

@media (max-width: 1240px) and (min-width: 992px) {

    .shirt_zoom .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .pop_up_design .card {
        max-width: 390px;
        top: 65px;
        left: 50px;
    }
}

@media (max-width: 1023px) and (min-width: 426px) {
    .vh_80 {
        height: 150vh;
    }

    .fabric .verify-casual {
        height: 70vh;
    }

    .fabric #f-one {
        height: 80vh;
        display: block !important;
    }

    #fab_fxd_n {
        width: 100% !important;
        position: fixed !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }
}


/*----------------------------------------------- Shirt css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    position: absolute;
    left: 28%;
    width: 47%;
    bottom: 0;
}

.carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
.carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    position: absolute;
    left: 28%;
    width: 48%;
    bottom: 0;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    left: 30% !important;
    width: 50% !important;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    left: 25%;
    width: 55%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 33%;
        width: 34%;
    }
}

@media screen and (min-width: 1441px) and (min-height: 900px) and (max-height: 1500px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 23% !important;
        width: 55% !important;
        bottom: 13% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 19%;
        width: 62%;
    }

}

@media screen and (min-width: 1441px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 26% !important;
        width: 49% !important;
        bottom: 6% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 26%;
        width: 48%;
        bottom: 0;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 23% !important;
        width: 53% !important;
        bottom: 10% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 23%;
        width: 54%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 18% !important;
        width: 65% !important;
        bottom: 10% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 17%;
        width: 65%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 27% !important;
        width: 46% !important;
        bottom: 5% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 27%;
        width: 45%;
        bottom: 0;
    }
}


/* @media (min-width: 1440px) {

  .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
  .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    position: absolute;
    left: 26%;
    width: 48%;
    top: 0;
  }
}


@media screen and (max-width: 1441px) and (min-width: 1025px) {

  .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    position: absolute;
    left: 28%;
    width: 47%;
    bottom: 0;
  }

  .pop_up_design {
    width: 100%;
    margin-left: 0;
    float: right;
    display: inline;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 10000;
  }
} */



@media screen and (max-width: 1023px) and (min-width: 769px) {
    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-height: 700px) {
    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        overflow: auto;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 25vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 64% !important;
        bottom: 5% !important;
        left: 18% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 40% !important;
        left: 28% !important;
        bottom: 6% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 29%;
        width: 41%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 20%;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) {

    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 25vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 60% !important;
        bottom: 7% !important;
        left: 19% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 60%;
        left: 20%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 850px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 55% !important;
        bottom: 8% !important;
        left: 21% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 53%;
        left: 23%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 750px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 52% !important;
        bottom: 3% !important;
        left: 23% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 45%;
        left: 27%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 64% !important;
        bottom: 2% !important;
        left: 17% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 64%;
        left: 18%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}


@media screen and (max-width: 460px) and (min-width: 401px) and (max-height: 700px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 45% !important;
        bottom: 4% !important;
        left: 26% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 43%;
        left: 27%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-height: 700px) and (min-height: 800px) {

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 64%;
        left: 17%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (max-height:700px) {

    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        overflow: auto;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 20vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 51% !important;
        bottom: 4% !important;
        left: 24% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 53%;
        left: 24%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}










/*------------------------------------------ Shirt css size End===============================================------------------ */


/*------------------------------------------- Pant css size Start --------------------------------------------------------------*/

.carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
    position: absolute;
    left: 0%;
    width: 100%;
    top: 15%;
}

.carousel-inner .carousel-item .pi_pant_front .custom_shirt,
.carousel-inner .carousel-item .pi_pant_back .custom_shirt {
    position: absolute;
    width: 41%;
    left: 29%;
    top: 0;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
    left: 5%;
    top: 0;
    width: 85%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
    left: 30%;
    top: 0;
    width: 40%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        position: absolute;
        width: 28%;
        left: 36%;
        top: 0;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 45% !important;
        left: 26% !important;
    }

}


@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 53% !important;
        left: 23% !important;
    }

}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 42% !important;
        left: 30% !important;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 1000px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 51% !important;
        left: 23% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 55% !important;
        left: 22% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 40% !important;
        left: 29% !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 27% !important;
        width: 43% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 29%;
        width: 43%;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 95%;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 30% !important;
        width: 39% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 31%;
        width: 39%;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 95%;
    }

}


@media screen and (max-width: 461px) and (min-width: 426px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 25% !important;
        width: 50% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 27%;
        width: 50%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}


@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 800px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 28% !important;
        width: 43% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 29%;
        width: 43%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 30%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        width: 63% !important;
        top: 0%;
        left: 18% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 63%;
        top: 0%;
        left: 18%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}



@media screen and (max-width: 426px) and (min-width: 400px) and (max-height: 800px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 30%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        width: 47% !important;
        top: 0%;
        left: 26% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 47%;
        top: 0%;
        left: 27%
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 20% !important;
        width: 59% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 76%;
        top: 33%;
        left: 10%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 24% !important;
        width: 50% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 25%;
        width: 50%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 700px) and (max-height: 799px) {
    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 22% !important;
        width: 56% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 27%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 21%;
        width: 61%;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 19% !important;
        width: 61% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 27%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 21%;
        width: 61%;
        top: 0;
    }
}

@media screen and (max-width: 299px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        position: absolute;
        width: 63%;
        left: 18%;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        position: absolute;
        left: 12%;
        width: 72%;
        top: 38%;
    }
}







@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*---------------------------------------------- Pant css size End -------------------------------------------------------------*/

/*----------------------------------------------- Suit css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
    position: absolute;
    left: 23%;
    width: 52%;
    bottom: 0%;
}

.suit_p .custom_shirt {
    top: 66% !important;
}

.carousel-inner .carousel-item .pi_suit_front .custom_shirt,
.carousel-inner .carousel-item .pi_suit_back .custom_shirt {
    position: absolute;
    left: 27%;
    width: 46%;
    bottom: 0%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
    left: 30%;
    width: 40%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
    left: calc(50% - 15%);
    top: 20%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
    left: 30%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 34%;
        width: 32%;
    }
}



@media screen and (min-width: 1700px) and (max-width: 1699px) and (min-height: 900px) and (min-height: 1500px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 26%;
        width: 45%;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 900px) and (max-height: 1100px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 16%;
        width: 68%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 19%;
        width: 62%;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 26%;
        width: 47%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 26%;
        width: 45%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 20%;
        width: 60%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 22%;
        width: 54%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 20%;
        width: 57%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 20%;
        width: 57%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 27%;
        width: 45%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 29%;
        width: 42%;
    }

}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 28%;
        width: 42%;
    }
}






@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 29%;
        width: 41%;
    }



    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        position: absolute;
        left: 31%;
        width: 36%;
    }

}

@media screen and (max-width: 461px) and (min-width: 426px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 48%;
        /* bottom: 0%; */
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 21%;
        width: 58%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width: 400px) and (max-width:426px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 58%;
        /* bottom: 0%; */
        left: 19%;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 19%;
        width: 60%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 21%;
        width: 56%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 47%;
        /* bottom: 0%; */
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 47%;
        left: 26%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 670px) and (max-height: 760px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 55%;
        /* bottom: 0%; */
        left: 21%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 56%;
        left: 20%;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 65%;
        /* bottom: 0%; */
        left: 15%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 58%;
        left: 20%;
    }
}


@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        position: absolute;
        left: 20%;
        width: 58%;
        bottom: 0%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        position: absolute;
        left: 19%;
        width: 59%;
        bottom: 0%;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*------------------------------------------ Suit css size End===============================================------------------ */
/*----------------------------------------------- Vest css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
    position: absolute;
    width: 45%;
    left: 25%;
    bottom: 0%;
}

.carousel-inner .carousel-item .pi_vest_front .custom_shirt,
.carousel-inner .carousel-item .pi_vest_back .custom_shirt {
    position: absolute;
    width: 45%;
    left: 27%;
    bottom: 0%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
    left: 30%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
    left: 30%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 32%;
        left: 34%;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1500px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 45%;
        left: 27%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 25%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 53%;
        left: 23%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 55%;
        left: 23%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 42%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 44%;
        left: 29%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 50%;
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 53%;
        left: 24%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 54%;
        left: 22%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 56%;
        left: 22%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        position: absolute;
        width: 40%;
        left: 30%;
        bottom: 0%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 28%;
    }
}

@media screen and (max-width: 1023px) and (min-width: 769px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 41%;
        left: 30%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 25%;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        position: absolute;
        width: 38%;
        left: 31%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        position: absolute;
        width: 36%;
        left: 32%;
    }

}

@media screen and (max-width: 461px) and (min-width: 426px) {


    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 51%;
        bottom: 2%;
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 50%;
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 50%;
        left: 26%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* 
  .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 50%;
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 800px) {

    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* 
  .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 58%;
        bottom: 3%;
        left: 21%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width:430px) and (max-width:460px) and (min-height: 650px) and (max-height:760px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 41%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 39%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 39%;
        left: 31%;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 60%;
        bottom: 2%;
        left: 19%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 59%;
        left: 21%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 47%;
        left: 27%;
        bottom: 2%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 49%;
        left: 26%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 49%;
        left: 26%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        top: 0;
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    /* .exchange {
    padding: 45px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 10px 10px 25px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    .pp_0 .c-measurements {
        padding: 0;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 49%;
        left: 26%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 22%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 57%;
        left: 21%;
        bottom: 2%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 650px) and (max-height: 799px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 50%;
        left: 23%;
        bottom: 2%;
    }
}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 52%;
        left: 22%;
        bottom: 10%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 57%;
        left: 21%;
        bottom: 0%;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*------------------------------------------ Vest css size End===============================================------------------ */

/*----------------------------------------------- Blazer css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .blazer_cuff .custom_shirt {
    position: absolute;
    left: 21%;
    width: 58%;
    top: 8%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
    left: 35%;
    top: 20%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 31%;
        width: 39%;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1500px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 18%;
        width: 60%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 900px) and (max-height: 1100px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 12%;
        width: 71%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 19%;
        width: 58%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 15%;
        width: 69%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 11%;
        width: 74%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 21%;
        width: 56%;
    }
}

@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 51%;
        left: 24%;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 72%;
        top: 15%;
        left: 12%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 20%;
        width: 60%;
        top: 22%;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 680px) and (max-height: 780px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 63%;
        top: 11%;
        left: 16%;
    }

}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        position: absolute;
        left: 11%;
        width: 75%;
        top: 11%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 63%;
        top: 10%;
        left: 17%;
    }

}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 700px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 68%;
        top: 14%;
        left: 14%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 73%;
        top: 17%;
        left: 11%;
    }
}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 12%;
        width: 69%;
        top: 18%;
    }
}




/*------------------------------------------ Blazer css size End===============================================------------------ */


/*------------------------------------------ Monogram css Start------------------------------------ */


.folded_svg {
    position: absolute;
    bottom: 23%;
    right: 33%;
    z-index: 1;
    width: 150px;
    height: 50px;
}

.pi_shirt_front .front_svg {
    position: absolute;
    z-index: 1;
    top: 35%;
    right: 37%;
    width: 150px;
    height: 50px;
}

.pi_shirt_front .front_svg text {
    font-size: 60px;
}

.folded_svg text {
    font-size: 140px;
}

.cuff_svg text {
    font-size: 80px;
}

.cuff_svg {
    z-index: 1;
    position: absolute;
    width: 110px;
    height: 50px;
}

.suit_front_svg {
    top: 9%;
    z-index: 1;
    margin-top: 31%;
    left: 25%;
    transform: rotate(-20deg);
    position: absolute;
    width: 150px;
    overflow: visible;
    height: 50px;
}

.suit_front_svg text {
    font-size: 185px;
}

.fabric .suit-monogram text {
    font-size: 170px;
}

@media screen and (min-width: 1441px) and (min-height: 900px) and (max-height: 1100px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 37%;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 240px;
    }

    .cuff_svg {
        bottom: 18%;
        right: 28%;
    }

    .cuff_svg text {
        font-size: 120px;
    }
}

@media screen and (min-width: 1441px) and (min-height: 700px) and (max-height: 900px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 38%;
    }

    .cuff_svg {
        bottom: 18%;
        right: 32%;
    }

    .cuff_svg text {
        font-size: 90px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .pi_shirt_front .front_svg {
        top: 45%;
        right: 37%;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 170px;
    }

    .cuff_svg {
        bottom: 16%;
        right: 30%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .folded_svg {
        bottom: 23%;
        right: 28%;
    }

    .pi_shirt_front .front_svg {
        top: 39%;
        right: 36%;
    }

    .suit_front_svg {
        top: 26%;
        left: 39%;
    }

    .cuff_svg {
        bottom: 18%;
        right: 27%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .folded_svg {
        bottom: 23%;
        right: 33%;
    }

    .cuff_svg {
        bottom: 16%;
        right: 33%;
    }

}


@media screen and (max-width: 1440px) and (min-width: 1025px) {

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 120px;
    }

}


@media screen and (max-width: 1024px) and (min-width: 769px) {
    .folded_svg {
        bottom: 12%;
        right: 30%;
    }

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 110px;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 250px;
    }

    .cuff_svg {
        bottom: 18%;
        right: 30%;
    }

}

@media screen and (max-width: 768px) and (min-width: 462px) {
    .folded_svg {
        bottom: 22%;
        right: 36%;
    }

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 90px;
    }

    .pi_shirt_front .front_svg {
        top: 41%;
        right: 39%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .cuff_svg {
        bottom: 16%;
        right: 34%;
    }
}


@media (max-width: 575.98px) {

    .fabric .suit-monogram {
        top: 10%;
        left: 0;
        width: 100%;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 170px;
    }
}

@media screen and (max-width: 461px) and (min-width: 427px) {
    .folded_svg {
        bottom: 20%;
        right: 33%;
    }

    .folded_svg text {
        font-size: 100px;
    }


    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 15%;
        right: 30%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .suit_front_svg text {
        font-size: 90px;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

/* @media screen and (max-width: 426px) and (min-width: 376px) {
  .folded_svg {
    left: 6%;
    margin-top: 65%;
  }

  .pi_shirt_front .front_svg {
    left: 0%;
    margin-top: 29%;
  }

  .pi_shirt_front .front_svg text {
    font-size: 40px;
  }

  .fabric .suit-monogram text,
  .folded_svg text {
    font-size: 90px;
  }

  .suit_front_svg {
    margin-top: 26% !important;
    left: 25% !important;
  }

  .fabric .suit-monogram text {
    font-size: 120px !important;
  }
} */

@media screen and (max-width: 461px) and (min-width: 427px) and (min-height: 700px) and (max-height: 800px) {
    .cuff_svg {
        bottom: 14%;
        right: 28%;
    }
}

@media screen and (max-width: 461px) and (min-width: 427px) and (min-height: 801px) {
    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }
}


@media screen and (max-width: 426px) and (min-width: 401px) {

    .folded_svg text {
        font-size: 100px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 33%;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 670px) and (max-height: 760px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 56% !important;
        bottom: 2% !important;
        left: 24% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 52%;
        left: 25%;
    }
}

@media screen and (max-width: 470px) and (min-width: 401px) and (min-height: 860px) {

    .cuff_svg {
        bottom: 15%;
        right: 23%;
    }

    .pi_shirt_front .front_svg {
        top: 43%;
        right: 32%;
    }

}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {

    .folded_svg {
        bottom: 20%;
        right: 28%;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 65%;
        left: 17%;
    }

}

@media screen and (max-width: 400px) and (min-width: 372px) {
    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 30%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 19%;
        left: 32%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 680px) and (max-height: 780px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 29%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }
}

@media screen and (max-width: 400px) and (min-width: 372px) and (min-height: 820px) {

    .pi_shirt_front .front_svg {
        top: 46%;
        right: 30%;
    }

    .suit_front_svg {
        top: 23%;
        left: 32%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 22%;
    }

}

@media screen and (max-width: 371px) and (min-width: 351px) {
    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 30%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 371px) and (min-width: 351px) and (min-height: 800px) {


    .pi_shirt_front .front_svg {
        top: 52%;
        right: 30%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 22%;
    }

}

@media screen and (max-width: 350px) and (min-width: 322px) {

    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 13%;
        right: 21%;
    }

    .cuff_svg text {
        font-size: 40px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 321px) and (min-width: 300px) {
    .folded_svg {
        bottom: 19%;
        right: 29%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 34px;
    }

    .cuff_svg {
        bottom: 12%;
        right: 20%;
    }

    .cuff_svg text {
        font-size: 40px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 460px) and (min-width: 300px) and (min-height: 700px) and (max-height: 900px) {

    .folded_svg {
        bottom: 19%;
        right: 26%;
    }

}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        position: absolute;
        left: 22%;
        width: 55%;
        bottom: 13%;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 18%;
        width: 67%;
        bottom: 0;
    }

    .pi_shirt_front .front_svg {
        top: 39%;
        right: 24%;
    }

    .suit_front_svg {
        top: 25%;
        left: 18%;
    }

    .suit_front_svg text {
        font-size: 150px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 16%;
    }

    .cuff_svg text {
        font-size: 50px;
    }
}



/*------------------------------------------ Monogram css End------------------------------------ */

@media screen and (max-width: 461px) and (min-width: 426px) {
    .second-header {
        height: 10vh;
    }
}