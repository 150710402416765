.loader {
  position: fixed;
  z-index: 999999;
  background: rgba(235, 235, 235, 0.58);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
}

.loaderAlign {
  z-index: 9999;
}

.btn:focus,
.btn:active {
  border: 0 !important;
}

.plp_sort_btn:focus {
  border: 1px solid var(--box-border) !important;
}

.canvas-loaderAlign {
  position: absolute;
  top: calc(100% - 50%);
  left: calc(100% - 75.5%);
  text-align: center;
  margin-left: -35px;
  margin-top: -35px;
  z-index: 9999;
  vertical-align: sub;
  align-items: center;
}

.nav-buttonlink {
  color: white;
  text-decoration: none;
}

.login-text {
  cursor: pointer;
  color: #656565;
  font-size: 14px;
}

.avatar-icon {
  color: #2b2a2a;
  font-size: 19px;
  cursor: pointer;
  position: relative;
  padding: 5px 11px;
}

.forgot-password {
  cursor: pointer;
  color: #979393;
}

.second-header {
  box-shadow: 4px 3px 4px rgb(208 208 208 / 50%);
  top: 0;
  background: #fff;
  z-index: 10;
  height: 20vh;
}

.fabric {
  height: 88vh;
}

.cursor-pointer {
  cursor: pointer;
}

.url-active {
  font-weight: 600;
}

.tape-btn-color {
  color: white;
}

.fabric-details-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 15px !important;
}

.show-error {
  color: red;
}

.selected-address {
  border: 1px solid blue;
}

.img {
  width: 90px;
  height: 90px;
}

.align {
  text-align: center;
}

table {
  border: bisque;
  border-collapse: collapse;
}

.padding {
  margin-top: -250px;
  padding-left: 500px;
}

.o_list li span {
  text-decoration: underline;
  color: #333;
  cursor: pointer;
}

.measurement-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  margin: 0px 5px;
  position: relative;
  top: 4px;
}

.nos .btn.btn-primary {
  background: #BDE1F6;
  border: 0;
  border-radius: 0px;
  color: black;
  font-family: 'Helvetica-CondensedBold';
  font-size: 14px;
}

.disable-content-link {
  pointer-events: none;
  opacity: 0.8;
}

.disable-content-button {
  pointer-events: none;
  opacity: 0.8;
  background: #ccc;
}

.text-blue {
  color: #007bff;
  background-color: transparent;
  text-underline-position: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.menu2 ul span a {
  font-size: 18px;
  color: #283941;
  font-weight: 500;
}

.megamenu ul span a {
  font-weight: 400;
}

.color-white {
  color: white;
}

.v-hide {
  visibility: hidden;
}

.font2 {
  font-family: "Yu Gothic";
}

.font3 {
  font-family: "Brush Script MT", cursive;
}

.font1 {
  font-family: "Calibri";
}

.font4 {
  font-family: "Baskerville";
}

.casual-icon {
  position: absolute;
  bottom: 12%;
  right: 5%;
  z-index: 999;
}

.font1,
.font2,
.font3,
.font4 {
  font-size: 17px;
}

@media only screen and (min-width: 1250px) {
  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_cuff img {
    transform: scale(1.35);
    margin-top: 10% !important;
    margin-left: -25% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_front img {
    transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_back img {
    transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_side img {
    transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }
}

.reset-icon {
  z-index: 4;
}

.account_edit {
  color: #717171;
  font-size: 14px;
  cursor: pointer;
}

.canvas-icons img {
  max-width: 130px;
  position: absolute;
  right: 0;
}

.print-order {
  color: #2a627b;
  text-decoration-line: underline;
}

#measureDelete .modal-content {
  border: 3px solid #264350;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.shirt_page .s-title {
  color: #131414;
  margin-bottom: 20px;
  padding-top: 0;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 28px;
  font-family: "Gordita Regular", sans-serif;
}

.shirt_page .dropdown .filter-text {
  font-size: 14px;
  cursor: pointer;
  outline: none;
  color: inherit;
  font-family: inherit;
  text-decoration: none;
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
}

.shirt_page ul .dropdown .dropdown-menu {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 4;
  min-width: 150px;
  flex-wrap: nowrap;
  background: rgb(255, 255, 255);
  padding: 15px 10px 5px;
  border: 1px solid rgb(240, 241, 241);
}

.ll {
  position: absolute;
  left: -70px;
  top: 40px;
  z-index: 4;
}

.shirt_page .dropdown .dropdown-menu .label-text {
  position: relative;
  display: block;
  padding-left: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
  cursor: pointer;
}

.shirt_page .dropdown .dropdown-menu .label-text .check-box {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.shirt_page .dropdown .dropdown-menu .label-text input {
  outline: none;
  border: none;
  background: none;
  border-radius: 0px;
}

.shirt_page .dropdown .dropdown-menu .label-text .c-check {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #e6e6e6;
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check {
  position: absolute;
  left: 0px;
  top: 6px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 300ms opacity;
  transition: 300ms opacity;
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check:before,
.shirt_page .dropdown .dropdown-menu .label-text .cc-check:after {
  content: "";
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 1px;
  background: #212121;
}

.shirt_page .dropdown svg {
  margin-left: 20px;
  margin-top: 5px;
}

.shirt_page .dropdown .dropdown-toggle::after {
  display: none;
}

.shirt_page .dropdown-item.active,
.shirt_page .dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: #d5d5d5;
}

.shirt_page ul .nav-item {
  margin-right: 40px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
}

.card .img-top {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.btn-design {
  background-color: #264350;
  color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 7px;
}

.card:hover .img-top {
  display: inline;
}

.mobile-btn {
  border: 1px solid #3a3a3a !important;
  padding: 3px 10px !important;
  border-radius: 10px !important;
}

.d-sm-none .label-text {
  padding-left: 0px !important;
}

.shirt-grup .card p {
  padding-left: 15px;
  padding-right: 15px;
}

.grid-item__colors .color-btn-1 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
}

.grid-item__colors .color-btn-2 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background: rgb(219, 238, 252);
  border: 1px solid rgb(219, 238, 252);
}

.grid-item__colors .color-btn-3 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  cursor: pointer;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  background: linear-gradient(135deg,
      rgb(219, 238, 252) 50%,
      rgb(255, 255, 255) 50%);
  border: 1px solid rgb(221, 221, 221);
}

@media (max-width: 576px) {

  .shirt-grup .col-lg-12,
  .shirt-grup .col-lg-4 {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}

.pdp_slider {
  padding: 30px 350px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.gg .carousel-control-prev-icon {
  /* background-image: url("../images/left-arrow.png"); */
}

.gg .carousel-control-next-icon {
  /* background-image: url("../images/right-arrow1.png"); */
}

@media only screen and (min-width: 1024px) {

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 50px !important;
    height: 50px !important;
  }
}

.pdp-title,
.d-title {
  color: #585757;
  background: transparent;
  border: 0;
  font-size: 22px;
  font-weight: 600;
}

.pdp_list_icon {
  width: 30px;
  margin-right: 30px;
}

.pdp-spec tr {
  padding: 15px 0px;
}

.slider-p {
  padding-left: 350px;
  padding-right: 350px;
}

.description .d-d {
  color: #6f6f6f;
  font-size: 12px;
}

.gg {
  padding: 0px 250px !important;
}

.gg img {
  padding: 0px 150px !important;
}

@media (max-width: 576px) {
  .pdp_list_icon {
    width: 25px;
    margin-right: 10px;
  }

  .gg {
    padding: 0px 20px !important;
  }

  .gg img {
    padding: 0px 20px !important;
  }

  .pdp-spec td {
    font-size: 13px;
  }

  .pdp-spec .col {
    padding-right: 1px;
    padding-left: 1px;
  }

  .cus-slider1 .col-lg-12,
  .nec_k .col-lg-6,
  .nec_k .col-lg-3 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pop_up_design .card {
    top: 60px !important;
    left: 25px !important;
  }

  .pop_up_design .card {
    height: 220px !important;
    overflow: auto;
  }

  .bg_imgg {
    top: 20px !important;
  }

  .pop_up_design .card {
    max-width: 78vw !important;
  }

  .bg_imgg img {
    height: 300px !important;
  }

  .clse_btn {
    top: 20px !important;
  }

  .quick_measure button,
  .quick_measure p {
    width: 120px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .quick_measure .active {
    padding-left: 15px !important;
  }

  .owl-nav {
    display: none;
  }

  .prdut_list .col-lg-4 {
    height: 520px;
  }

  #cuff {
    top: 330px;
    height: 75% !important;
  }

  #cuff li {
    width: 100% !important;
    margin: 0px !important;
    padding: 15px;
  }

  #cuff li .titles {
    padding: 2px;
  }

  .quick_size .container,
  .quick_size .container .col-12,
  .zoom_full_width .modal-body,
  .zoom_full_width .modal-body .col-12 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .zoom_full_width .modal-body .col-12.measuremnt-l {
    height: 90% !important;
    overflow: scroll !important;
  }

  .zoom_full_width .modal-body {
    height: 100%;
  }

  .zoom_full_width .modal-body .col-12.measuremnt-l .container .table-responsive img {
    width: 58px !important;
  }

  .d-inline ul.nw_zoom {
    display: flex;
  }

  .d-inline .cursor-pointer {
    padding: 0px 15px;
  }

}

.modal.show .modal-dialog {
  width: 100vw;
  height: 100%;
}

.table td,
.table th {
  border: 1px solid #dee2e6;
}

.fabric .suit-monogram::after {
  width: 25px;
  height: 25px;
  background-color: #264350;
  border-radius: 70px;
  position: absolute;
  top: 0px;
  font-size: 16px;
  right: 0px;
  text-align: center;
  color: white;
  content: "X";
}

.card {
  height: auto;
}

.modal-lg {
  width: 100% !important;
  max-width: 100% !important;
}

.modal-dialog {
  margin: 0px !important;
}

.ed-tt-box input[type=text] {
  width: 90px;
  padding: 7px 20px;
  box-sizing: border-box;
}

.ed-tt-box input[type=text]:focus-visible {
  border: none;
}

.custom_measurment .dropdown .btn {
  border: 1px solid rgb(214, 214, 214);
}


.modal-lg,
.modal-xl {
  max-width: 90%;
}

.modal-lg,
.modal-xl {
  max-width: 95%;
}

.zoom_full_width .modal-body .col-12.measuremnt-l .container .table-responsive img {
  width: 58px !important;
}

.zoom_full_width .modal-body .col-12.measuremnt-l {
  height: 80vh;
  overflow: scroll;
}

.nw_featured .card-footer {
  left: 0px !important;
}

.suit-monogram {
  z-index: 10;
}

.breadcum {
  width: 16%;
}


.breadcum_a a {
  display: flex;
}

.fabric #nek1 {
  margin-top: 15px;
}

.bg_imgg img {
  height: 65vh;
}


.nw_featured .card-footer {
  position: relative !important;
  bottom: 126px !important;
}

.prdut_list .card img {
  object-fit: scale-down;
  height: 50%;

}

.prdut_list .col-lg-4 {
  height: 600px;
}

.quick_size .breadcrumb li a {
  color: #283941;
}

.quick_size .breadcrumb li .active {
  color: #000000;
}

.quick_size .breadcrumb {
  background-color: #ffffff !important;
  margin-bottom: 1px;
}

.quick_size .s_heading h2 {
  padding-top: 1px;
}

.quick_measure .d-flex {
  margin-top: 8px;
  margin-bottom: 8px;
}

.quick_measure .active {
  background-color: #264350 !important;
  color: white !important;
  padding-left: 50px;
}

.quick_measure .btn-outline-dark {
  border-color: #e1e1e1;
}

.quick_measure button,
.quick_measure p {
  width: 120px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.quick_measure button,
.quick_measure p {
  width: 120px;
}

.btn-next {
  background-color: #264350;
  color: white;
}

.btn-100 {
  width: 200px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.quick_measure .btn-outline-dark .h-none:not(:disabled):not(.disabled).active,
.quick_measure .btn-outline-dark .h-none:not(:disabled):not(.disabled):active,
.quick_measure .h-none .show>.btn-outline-dark.dropdown-toggle {
  color: #343a40;
  background-color: #ffffff;
  border-color: #ffffff;
}

.measuremnt-l .hd p {
  font-size: 25px;
}

.measuremnt-l .size-btn p {
  border: 2px solid #cfcfcf;
  padding: 10px 38px;
}

.measuremnt-l .size-btn p:hover,
.measuremnt-l .size-btn p:active {
  border: 2px solid #111111;
  padding: 10px 38px;
  border-radius: 0px;
}

.measuremnt-l .light-color {
  color: rgb(116, 116, 116);
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

/* toggle */

.switch_m {
  position: relative;
  display: inline-block;
  top: 10px;
  width: 63px;
  height: 7px;
}

.switch_m input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_m .slider_m {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #264350;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_m .slider_m:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  top: -9px;
  border: 1px solid #c9c9c9;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_m input:checked+.slider_m {
  background-color: #264350;
}

.switch_m input:focus+.slider_m {
  box-shadow: 0 0 1px #2196F3;
}

.switch_m input:checked+.slider_m:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(556px);
  transform: translateX(40px);
}

.switch_m .slider_m.round_m {
  border-radius: 34px;
}

.switch_m .slider_m.round_m:before {
  border-radius: 50%;
}

.menu-wrapper {
  display: flex;
  align-items: center;
}

.menu-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  width: 100%;
}

.prev-btn,
.next-btn {
  background-color: #ccc;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-btn[disabled],
.next-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}


/* toggle */

/* .canva_zoom_hgt {
  height: 650px;
} */

.measurement-link-delete {
  color: red;
  text-decoration: none;
  background-color: transparent;
  margin: 0px 5px;
  position: relative;
  top: 4px;
}

.verify-casual .modal {
  width: 100%;
  height: 100%;
}

.fabric .suit-monogram {
  position: absolute;
  width: 50%;
  top: 20px;
  left: 600px;
  background-color: white;
  padding: 10px;
  border: 2px solid #eaeaea;
  z-index: 10000000;
}

.fabric .suit-monogram img {
  width: 100%;
}

.nw_featured {
  padding: 0px !important;
}

#loginpop .modal-dialog {
  margin: 0 auto !important;
}

@media screen and (max-width: 400px) {

  #measure .modal-body p {
    font-size: 20px;
    font-weight: 500;
  }

  #fab-font-ht .w-90 {
    width: 90% !important;
  }

  #measure .modal-content {
    padding: 20px;
  }


  #measure .modal-body .m_content p {
    font-size: 14px !important;
  }

  .card-body {
    padding: 0 !important;
  }

  .accordion .card-body {
    padding: 0 !important;
  }

  #accordion .card {
    height: auto;
  }

  .clse_btn {
    padding: 5px 12px !important;
    font-size: 25px !important;
  }

  .nw_featured .card {
    height: 410px;
  }

  .verify-casual .modal {
    left: 0vw !important;
    width: 100% !important;
    height: 100% !important;
  }

  .verify-casual .modal-backdrop.show {
    opacity: 0 !important;
  }

  .fabric .suit-monogram {
    width: 93% !important;
    top: 160px !important;
    position: fixed !important;
    left: 8px !important;
    z-index: 10000000000;
  }
}

.vh_80 {
  height: 80vh;
}

#cart_deatils .modal-content {
  border-radius: 15px;
  max-width: 900px;
  margin-top: 5vh !important;
  margin: 0 auto;
  height: 90vh;
  overflow: auto;
  border: none;
}

.fb_popup_img img {
  width: 100%;
}

.cart_pop_up p {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.nw_snd_btn button .nw_drp {
  background-color: white;
  color: #000;
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .vh_80 {
    height: 79vh;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .vh_80 {
    height: 79vh;
  }
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.custom_shirt.shirt_head {
  margin-top: -12%;
}

.suit_back_head {
  margin-top: -9%;
}

.carousel-control-prev,
.carousel-control-next {
  top: 40% !important;
  height: 15%;
}

.carousel-control-prev {
  left: 20px !important;
}

.carousel-control-next {
  right: 20px !important;
}

.modal-body .nos {
  border-radius: 10px;
  border: 1px solid #cccccc6e;
}

.modal-body .nos p {
  font-size: 20px;
  font-weight: 500;
}

.nos_pop .modal-body button {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

#measure {
  padding-right: 0px !important;
}

.nos_pop .modal-body {
  height: 480px !important;
}

.story iframe {
  width: 100%;
  height: 550px;
}

.fliter_button button {
  width: 100%;
  border: 0;
  padding: 6px 12px;
  font-size: 18px;
  background: #333333;
  color: #fff;
  letter-spacing: 2px;
  border-radius: 8px;
}

.vh_80 .nec_k {
  overflow: auto;
}

.fabric .verify-casual {
  height: 100%;
}

.step2.active {
  margin-top: -16px;
}

#progressbar li.step2:after,
#progressbar li.step3:after {
  content: "";
  background: #e4e4e4;
  top: 8px;
}

#progressbar li.active.step2:after,
#progressbar li.active.step3:after {
  content: "";
  background: #264350;
  top: 8px;
}

#progressbar li.active.step3:after+#progressbar li.step2:after {
  content: "";
  background: #264350;
  top: 8px;
}

#filters #accordion {
  overflow-y: scroll;
  height: 55vh;
}

.price_acc::after {
  display: none;
}

/* --------------PLP Design CSS Start------------------ */

.btn-design-now {
  background-color: #264350;
  border: #264350;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.plp-title {
  font-weight: 600;
}

.plp-color-one {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(29, 49, 119);
  border: 1px solid rgb(29, 49, 119);
}

.plp-color-one span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-color-two {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(29, 49, 119);
  border: 1px solid rgb(29, 49, 119);
}

.plp-color-two span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
}

.plp-color-three {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}

.plp-color-three span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-color-four {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(66, 98, 132);
  border: 1px solid rgb(66, 98, 132);
}

.plp-color-four span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-plus-one {
  position: relative;
  top: -5px;
  color: rgb(148, 154, 161);
  font-size: 12px;
}

/* --------------PLP Design CSS End------------------ */

/* --------------PDP Design CSS Start------------------ */

.pdp .carousel .carousel-control-prev,
.pdp .carousel .carousel-control-next {
  display: block;
}

.pdp .pdp_slider_counter {
  background-color: #264350;
  padding: 5px;
  border-radius: 20px;
  float: right;
  width: 70px;
  color: white;
  font-size: 12px;
  position: relative;
  right: 0px;
  top: 10px;
  text-align: center;
}

.pdp_spc_titile {
  color: #969393;
}

.pdp .btn-design-now {
  padding: 10px;
  font-size: 18px;
  margin-top: 15px;
  border-radius: 10px;
}


.pdp .carousel-control-prev-icon {
  /* background-image: url("../images/pdp_arrow_l.png"); */
  width: 30px !important;
  height: 52px !important;
}

.pdp .carousel-control-next-icon {
  /* background-image: url("../images/pdp_arrow_r.png"); */
  width: 30px !important;
  height: 52px !important;
}

.pdp .pdp-title {
  font-weight: 600;
}

.ScrollStyle {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.loginBody {
  margin-top: -10px;
}

.viewIcon {
  z-index: 999;
}

.footer-text {
  text-decoration: none !important;
  color: white;
}


.footer-text :hover {
  text-decoration: none !important;
  color: white;
}


/* --------------PDP Design CSS End------------------ */