@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
.loader {
  position: fixed;
  z-index: 999999;
  background: rgba(235, 235, 235, 0.58);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
}

.loaderAlign {
  z-index: 9999;
}

.btn:focus,
.btn:active {
  border: 0 !important;
}

.plp_sort_btn:focus {
  border: 1px solid var(--box-border) !important;
}

.canvas-loaderAlign {
  position: absolute;
  top: calc(100% - 50%);
  left: calc(100% - 75.5%);
  text-align: center;
  margin-left: -35px;
  margin-top: -35px;
  z-index: 9999;
  vertical-align: sub;
  align-items: center;
}

.nav-buttonlink {
  color: white;
  text-decoration: none;
}

.login-text {
  cursor: pointer;
  color: #656565;
  font-size: 14px;
}

.avatar-icon {
  color: #2b2a2a;
  font-size: 19px;
  cursor: pointer;
  position: relative;
  padding: 5px 11px;
}

.forgot-password {
  cursor: pointer;
  color: #979393;
}

.second-header {
  box-shadow: 4px 3px 4px rgb(208 208 208 / 50%);
  top: 0;
  background: #fff;
  z-index: 10;
  height: 20vh;
}

.fabric {
  height: 88vh;
}

.cursor-pointer {
  cursor: pointer;
}

.url-active {
  font-weight: 600;
}

.tape-btn-color {
  color: white;
}

.fabric-details-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 15px !important;
}

.show-error {
  color: red;
}

.selected-address {
  border: 1px solid blue;
}

.img {
  width: 90px;
  height: 90px;
}

.align {
  text-align: center;
}

table {
  border: bisque;
  border-collapse: collapse;
}

.padding {
  margin-top: -250px;
  padding-left: 500px;
}

.o_list li span {
  text-decoration: underline;
  color: #333;
  cursor: pointer;
}

.measurement-link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  margin: 0px 5px;
  position: relative;
  top: 4px;
}

.nos .btn.btn-primary {
  background: #BDE1F6;
  border: 0;
  border-radius: 0px;
  color: black;
  font-family: 'Helvetica-CondensedBold';
  font-size: 14px;
}

.disable-content-link {
  pointer-events: none;
  opacity: 0.8;
}

.disable-content-button {
  pointer-events: none;
  opacity: 0.8;
  background: #ccc;
}

.text-blue {
  color: #007bff;
  background-color: transparent;
  text-underline-position: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.menu2 ul span a {
  font-size: 18px;
  color: #283941;
  font-weight: 500;
}

.megamenu ul span a {
  font-weight: 400;
}

.color-white {
  color: white;
}

.v-hide {
  visibility: hidden;
}

.font2 {
  font-family: "Yu Gothic";
}

.font3 {
  font-family: "Brush Script MT", cursive;
}

.font1 {
  font-family: "Calibri";
}

.font4 {
  font-family: "Baskerville";
}

.casual-icon {
  position: absolute;
  bottom: 12%;
  right: 5%;
  z-index: 999;
}

.font1,
.font2,
.font3,
.font4 {
  font-size: 17px;
}

@media only screen and (min-width: 1250px) {
  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_cuff img {
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
    margin-top: 10% !important;
    margin-left: -25% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_front img {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_back img {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner .pi_shirt_side img {
    -webkit-transform: scale(1.8);
            transform: scale(1.8);
    margin-top: 10% !important;
    margin-left: -20% !important;
  }
}

.reset-icon {
  z-index: 4;
}

.account_edit {
  color: #717171;
  font-size: 14px;
  cursor: pointer;
}

.canvas-icons img {
  max-width: 130px;
  position: absolute;
  right: 0;
}

.print-order {
  color: #2a627b;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#measureDelete .modal-content {
  border: 3px solid #264350;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.shirt_page .s-title {
  color: #131414;
  margin-bottom: 20px;
  padding-top: 0;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 28px;
  font-family: "Gordita Regular", sans-serif;
}

.shirt_page .dropdown .filter-text {
  font-size: 14px;
  cursor: pointer;
  outline: none;
  color: inherit;
  font-family: inherit;
  text-decoration: none;
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
}

.shirt_page ul .dropdown .dropdown-menu {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 4;
  min-width: 150px;
  flex-wrap: nowrap;
  background: rgb(255, 255, 255);
  padding: 15px 10px 5px;
  border: 1px solid rgb(240, 241, 241);
}

.ll {
  position: absolute;
  left: -70px;
  top: 40px;
  z-index: 4;
}

.shirt_page .dropdown .dropdown-menu .label-text {
  position: relative;
  display: block;
  padding-left: 24px;
  text-transform: capitalize;
  margin-bottom: 10px;
  cursor: pointer;
}

.shirt_page .dropdown .dropdown-menu .label-text .check-box {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.shirt_page .dropdown .dropdown-menu .label-text input {
  outline: none;
  border: none;
  background: none;
  border-radius: 0px;
}

.shirt_page .dropdown .dropdown-menu .label-text .c-check {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #e6e6e6;
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check {
  position: absolute;
  left: 0px;
  top: 6px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 300ms opacity;
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.shirt_page .dropdown .dropdown-menu .label-text .cc-check:before,
.shirt_page .dropdown .dropdown-menu .label-text .cc-check:after {
  content: "";
  position: absolute;
  left: 10%;
  top: 10%;
  width: 80%;
  height: 1px;
  background: #212121;
}

.shirt_page .dropdown svg {
  margin-left: 20px;
  margin-top: 5px;
}

.shirt_page .dropdown .dropdown-toggle::after {
  display: none;
}

.shirt_page .dropdown-item.active,
.shirt_page .dropdown-item:active {
  color: #000;
  text-decoration: none;
  background-color: #d5d5d5;
}

.shirt_page ul .nav-item {
  margin-right: 40px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
}

.card .img-top {
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.btn-design {
  background-color: #264350;
  color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 7px;
}

.card:hover .img-top {
  display: inline;
}

.mobile-btn {
  border: 1px solid #3a3a3a !important;
  padding: 3px 10px !important;
  border-radius: 10px !important;
}

.d-sm-none .label-text {
  padding-left: 0px !important;
}

.shirt-grup .card p {
  padding-left: 15px;
  padding-right: 15px;
}

.grid-item__colors .color-btn-1 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
}

.grid-item__colors .color-btn-2 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background: rgb(219, 238, 252);
  border: 1px solid rgb(219, 238, 252);
}

.grid-item__colors .color-btn-3 {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
  cursor: pointer;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  background: linear-gradient(135deg,
      rgb(219, 238, 252) 50%,
      rgb(255, 255, 255) 50%);
  border: 1px solid rgb(221, 221, 221);
}

@media (max-width: 576px) {

  .shirt-grup .col-lg-12,
  .shirt-grup .col-lg-4 {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}

.pdp_slider {
  padding: 30px 350px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.gg .carousel-control-prev-icon {
  /* background-image: url("../images/left-arrow.png"); */
}

.gg .carousel-control-next-icon {
  /* background-image: url("../images/right-arrow1.png"); */
}

@media only screen and (min-width: 1024px) {

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 50px !important;
    height: 50px !important;
  }
}

.pdp-title,
.d-title {
  color: #585757;
  background: transparent;
  border: 0;
  font-size: 22px;
  font-weight: 600;
}

.pdp_list_icon {
  width: 30px;
  margin-right: 30px;
}

.pdp-spec tr {
  padding: 15px 0px;
}

.slider-p {
  padding-left: 350px;
  padding-right: 350px;
}

.description .d-d {
  color: #6f6f6f;
  font-size: 12px;
}

.gg {
  padding: 0px 250px !important;
}

.gg img {
  padding: 0px 150px !important;
}

@media (max-width: 576px) {
  .pdp_list_icon {
    width: 25px;
    margin-right: 10px;
  }

  .gg {
    padding: 0px 20px !important;
  }

  .gg img {
    padding: 0px 20px !important;
  }

  .pdp-spec td {
    font-size: 13px;
  }

  .pdp-spec .col {
    padding-right: 1px;
    padding-left: 1px;
  }

  .cus-slider1 .col-lg-12,
  .nec_k .col-lg-6,
  .nec_k .col-lg-3 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pop_up_design .card {
    top: 60px !important;
    left: 25px !important;
  }

  .pop_up_design .card {
    height: 220px !important;
    overflow: auto;
  }

  .bg_imgg {
    top: 20px !important;
  }

  .pop_up_design .card {
    max-width: 78vw !important;
  }

  .bg_imgg img {
    height: 300px !important;
  }

  .clse_btn {
    top: 20px !important;
  }

  .quick_measure button,
  .quick_measure p {
    width: 120px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .quick_measure .active {
    padding-left: 15px !important;
  }

  .owl-nav {
    display: none;
  }

  .prdut_list .col-lg-4 {
    height: 520px;
  }

  #cuff {
    top: 330px;
    height: 75% !important;
  }

  #cuff li {
    width: 100% !important;
    margin: 0px !important;
    padding: 15px;
  }

  #cuff li .titles {
    padding: 2px;
  }

  .quick_size .container,
  .quick_size .container .col-12,
  .zoom_full_width .modal-body,
  .zoom_full_width .modal-body .col-12 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .zoom_full_width .modal-body .col-12.measuremnt-l {
    height: 90% !important;
    overflow: scroll !important;
  }

  .zoom_full_width .modal-body {
    height: 100%;
  }

  .zoom_full_width .modal-body .col-12.measuremnt-l .container .table-responsive img {
    width: 58px !important;
  }

  .d-inline ul.nw_zoom {
    display: flex;
  }

  .d-inline .cursor-pointer {
    padding: 0px 15px;
  }

}

.modal.show .modal-dialog {
  width: 100vw;
  height: 100%;
}

.table td,
.table th {
  border: 1px solid #dee2e6;
}

.fabric .suit-monogram::after {
  width: 25px;
  height: 25px;
  background-color: #264350;
  border-radius: 70px;
  position: absolute;
  top: 0px;
  font-size: 16px;
  right: 0px;
  text-align: center;
  color: white;
  content: "X";
}

.card {
  height: auto;
}

.modal-lg {
  width: 100% !important;
  max-width: 100% !important;
}

.modal-dialog {
  margin: 0px !important;
}

.ed-tt-box input[type=text] {
  width: 90px;
  padding: 7px 20px;
  box-sizing: border-box;
}

.ed-tt-box input[type=text]:focus-visible {
  border: none;
}

.custom_measurment .dropdown .btn {
  border: 1px solid rgb(214, 214, 214);
}


.modal-lg,
.modal-xl {
  max-width: 90%;
}

.modal-lg,
.modal-xl {
  max-width: 95%;
}

.zoom_full_width .modal-body .col-12.measuremnt-l .container .table-responsive img {
  width: 58px !important;
}

.zoom_full_width .modal-body .col-12.measuremnt-l {
  height: 80vh;
  overflow: scroll;
}

.nw_featured .card-footer {
  left: 0px !important;
}

.suit-monogram {
  z-index: 10;
}

.breadcum {
  width: 16%;
}


.breadcum_a a {
  display: flex;
}

.fabric #nek1 {
  margin-top: 15px;
}

.bg_imgg img {
  height: 65vh;
}


.nw_featured .card-footer {
  position: relative !important;
  bottom: 126px !important;
}

.prdut_list .card img {
  object-fit: scale-down;
  height: 50%;

}

.prdut_list .col-lg-4 {
  height: 600px;
}

.quick_size .breadcrumb li a {
  color: #283941;
}

.quick_size .breadcrumb li .active {
  color: #000000;
}

.quick_size .breadcrumb {
  background-color: #ffffff !important;
  margin-bottom: 1px;
}

.quick_size .s_heading h2 {
  padding-top: 1px;
}

.quick_measure .d-flex {
  margin-top: 8px;
  margin-bottom: 8px;
}

.quick_measure .active {
  background-color: #264350 !important;
  color: white !important;
  padding-left: 50px;
}

.quick_measure .btn-outline-dark {
  border-color: #e1e1e1;
}

.quick_measure button,
.quick_measure p {
  width: 120px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.quick_measure button,
.quick_measure p {
  width: 120px;
}

.btn-next {
  background-color: #264350;
  color: white;
}

.btn-100 {
  width: 200px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.quick_measure .btn-outline-dark .h-none:not(:disabled):not(.disabled).active,
.quick_measure .btn-outline-dark .h-none:not(:disabled):not(.disabled):active,
.quick_measure .h-none .show>.btn-outline-dark.dropdown-toggle {
  color: #343a40;
  background-color: #ffffff;
  border-color: #ffffff;
}

.measuremnt-l .hd p {
  font-size: 25px;
}

.measuremnt-l .size-btn p {
  border: 2px solid #cfcfcf;
  padding: 10px 38px;
}

.measuremnt-l .size-btn p:hover,
.measuremnt-l .size-btn p:active {
  border: 2px solid #111111;
  padding: 10px 38px;
  border-radius: 0px;
}

.measuremnt-l .light-color {
  color: rgb(116, 116, 116);
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}

/* toggle */

.switch_m {
  position: relative;
  display: inline-block;
  top: 10px;
  width: 63px;
  height: 7px;
}

.switch_m input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_m .slider_m {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #264350;
  transition: 0.4s;
}

.switch_m .slider_m:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 0px;
  top: -9px;
  border: 1px solid #c9c9c9;
  background-color: white;
  transition: 0.4s;
}

.switch_m input:checked+.slider_m {
  background-color: #264350;
}

.switch_m input:focus+.slider_m {
  box-shadow: 0 0 1px #2196F3;
}

.switch_m input:checked+.slider_m:before {
  -webkit-transform: translateX(40px);
  transform: translateX(40px);
}

.switch_m .slider_m.round_m {
  border-radius: 34px;
}

.switch_m .slider_m.round_m:before {
  border-radius: 50%;
}

.menu-wrapper {
  display: flex;
  align-items: center;
}

.menu-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  width: 100%;
}

.prev-btn,
.next-btn {
  background-color: #ccc;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev-btn[disabled],
.next-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}


/* toggle */

/* .canva_zoom_hgt {
  height: 650px;
} */

.measurement-link-delete {
  color: red;
  text-decoration: none;
  background-color: transparent;
  margin: 0px 5px;
  position: relative;
  top: 4px;
}

.verify-casual .modal {
  width: 100%;
  height: 100%;
}

.fabric .suit-monogram {
  position: absolute;
  width: 50%;
  top: 20px;
  left: 600px;
  background-color: white;
  padding: 10px;
  border: 2px solid #eaeaea;
  z-index: 10000000;
}

.fabric .suit-monogram img {
  width: 100%;
}

.nw_featured {
  padding: 0px !important;
}

#loginpop .modal-dialog {
  margin: 0 auto !important;
}

@media screen and (max-width: 400px) {

  #measure .modal-body p {
    font-size: 20px;
    font-weight: 500;
  }

  #fab-font-ht .w-90 {
    width: 90% !important;
  }

  #measure .modal-content {
    padding: 20px;
  }


  #measure .modal-body .m_content p {
    font-size: 14px !important;
  }

  .card-body {
    padding: 0 !important;
  }

  .accordion .card-body {
    padding: 0 !important;
  }

  #accordion .card {
    height: auto;
  }

  .clse_btn {
    padding: 5px 12px !important;
    font-size: 25px !important;
  }

  .nw_featured .card {
    height: 410px;
  }

  .verify-casual .modal {
    left: 0vw !important;
    width: 100% !important;
    height: 100% !important;
  }

  .verify-casual .modal-backdrop.show {
    opacity: 0 !important;
  }

  .fabric .suit-monogram {
    width: 93% !important;
    top: 160px !important;
    position: fixed !important;
    left: 8px !important;
    z-index: 10000000000;
  }
}

.vh_80 {
  height: 80vh;
}

#cart_deatils .modal-content {
  border-radius: 15px;
  max-width: 900px;
  margin-top: 5vh !important;
  margin: 0 auto;
  height: 90vh;
  overflow: auto;
  border: none;
}

.fb_popup_img img {
  width: 100%;
}

.cart_pop_up p {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.nw_snd_btn button .nw_drp {
  background-color: white;
  color: #000;
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .vh_80 {
    height: 79vh;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .vh_80 {
    height: 79vh;
  }
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.custom_shirt.shirt_head {
  margin-top: -12%;
}

.suit_back_head {
  margin-top: -9%;
}

.carousel-control-prev,
.carousel-control-next {
  top: 40% !important;
  height: 15%;
}

.carousel-control-prev {
  left: 20px !important;
}

.carousel-control-next {
  right: 20px !important;
}

.modal-body .nos {
  border-radius: 10px;
  border: 1px solid #cccccc6e;
}

.modal-body .nos p {
  font-size: 20px;
  font-weight: 500;
}

.nos_pop .modal-body button {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

#measure {
  padding-right: 0px !important;
}

.nos_pop .modal-body {
  height: 480px !important;
}

.story iframe {
  width: 100%;
  height: 550px;
}

.fliter_button button {
  width: 100%;
  border: 0;
  padding: 6px 12px;
  font-size: 18px;
  background: #333333;
  color: #fff;
  letter-spacing: 2px;
  border-radius: 8px;
}

.vh_80 .nec_k {
  overflow: auto;
}

.fabric .verify-casual {
  height: 100%;
}

.step2.active {
  margin-top: -16px;
}

#progressbar li.step2:after,
#progressbar li.step3:after {
  content: "";
  background: #e4e4e4;
  top: 8px;
}

#progressbar li.active.step2:after,
#progressbar li.active.step3:after {
  content: "";
  background: #264350;
  top: 8px;
}

#progressbar li.active.step3:after+#progressbar li.step2:after {
  content: "";
  background: #264350;
  top: 8px;
}

#filters #accordion {
  overflow-y: scroll;
  height: 55vh;
}

.price_acc::after {
  display: none;
}

/* --------------PLP Design CSS Start------------------ */

.btn-design-now {
  background-color: #264350;
  border: #264350;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.plp-title {
  font-weight: 600;
}

.plp-color-one {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(29, 49, 119);
  border: 1px solid rgb(29, 49, 119);
}

.plp-color-one span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-color-two {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(29, 49, 119);
  border: 1px solid rgb(29, 49, 119);
}

.plp-color-two span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 1;
}

.plp-color-three {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}

.plp-color-three span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-color-four {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 100%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: rgb(66, 98, 132);
  border: 1px solid rgb(66, 98, 132);
}

.plp-color-four span {
  border: 1px solid rgb(119, 122, 124);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.2s ease 0s;
  opacity: 0;
}

.plp-plus-one {
  position: relative;
  top: -5px;
  color: rgb(148, 154, 161);
  font-size: 12px;
}

/* --------------PLP Design CSS End------------------ */

/* --------------PDP Design CSS Start------------------ */

.pdp .carousel .carousel-control-prev,
.pdp .carousel .carousel-control-next {
  display: block;
}

.pdp .pdp_slider_counter {
  background-color: #264350;
  padding: 5px;
  border-radius: 20px;
  float: right;
  width: 70px;
  color: white;
  font-size: 12px;
  position: relative;
  right: 0px;
  top: 10px;
  text-align: center;
}

.pdp_spc_titile {
  color: #969393;
}

.pdp .btn-design-now {
  padding: 10px;
  font-size: 18px;
  margin-top: 15px;
  border-radius: 10px;
}


.pdp .carousel-control-prev-icon {
  /* background-image: url("../images/pdp_arrow_l.png"); */
  width: 30px !important;
  height: 52px !important;
}

.pdp .carousel-control-next-icon {
  /* background-image: url("../images/pdp_arrow_r.png"); */
  width: 30px !important;
  height: 52px !important;
}

.pdp .pdp-title {
  font-weight: 600;
}

.ScrollStyle {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.loginBody {
  margin-top: -10px;
}

.viewIcon {
  z-index: 999;
}

.footer-text {
  text-decoration: none !important;
  color: white;
}


.footer-text :hover {
  text-decoration: none !important;
  color: white;
}


/* --------------PDP Design CSS End------------------ */
body {
  font-family: 'Roboto Condensed', sans-serif;
}

#banner_slider {
  position: relative;
  top: 0px;
  z-index: 0;
}

.sc_m ul {
  padding-left: 0;
  text-align: center;
}

.megamenu ul li {
  display: block !important;
}

.font_48 {
  font-size: 48px;
}

#menu_desk {
  box-shadow: 4px 3px 4px rgb(208 208 208 / 50%);
  padding-bottom: 1px;
  /* position: sticky; */
  top: 0;
  z-index: 10;
  background: #fff;
  padding-top: 0px;
}

#banner_slider .carousel-inner {
  background: #f9fbff;
}

.sc_m ul li {
  display: inline-block;
  padding: 5px 5px;
}

.r_lg {
  text-align: right;
}

.car_t li input {
  width: 200px;
  border-radius: 50px;
  height: 30px;
  padding: 0px 14px;
}

ul.car_t li {
  display: inline-block;
  padding: 4px 3px;
}

ul.car_t {
  padding-left: 0;
  float: right;
  margin-right: 0;
}

.menu2 ul li a {
  font-size: 18px;
  color: #283941;
  font-weight: 500;
}

.menu2 ul {
  padding-left: 0;
  z-index: 1000;
}

.menu2 ul li {
  display: inline-block;
  padding: 6px 14px;
}

.megamenu.show {
  display: block !important;
}

.second-menu .megamenu.show {
  -webkit-transform: translate3d(0px, 41px, 0px) !important;
          transform: translate3d(0px, 41px, 0px) !important;
}

.second-menu .dropdown-menu {
  /* min-width: 84.3rem; */
  border-bottom: 1px solid #ccc;
}

.img-list-menu li {
  display: inline-block;
  float: left;
  width: 30%;
}

.tal_img {
  position: absolute;
}

.min-header {
  background: #283941;
}

.min-header p,
.min-header ul {
  margin-bottom: 0;
}

.l_ft p {
  font-size: 14px;
}

.sc_m ul li a,
.r_lg p a,
.l_ft p,
.r_lg p span {
  color: #fff;
}

.fa-fw.fa-eye,
.fa-fw.fa-eye-slash {
  position: relative;
  left: -10px;
  float: right;
  top: -40px;
}

.sign-cs {
  top: -28px !important;
}


.menu1 ul.car_t {
  margin-bottom: 0;
}

.menu1 ul.car_t li {
  padding: 5px 8px;
}

.menu1 ul.car_t li a i {
  font-size: 22px;
}

.menu1 ul.car_t li a {
  color: #283941;
}

.menu1 ul.car_t li span i {
  font-size: 22px;
}

.menu1 ul.car_t li span {
  color: #283941;
}

#banner_slider .carousel-caption h5 {
  font-size: 35px;
  font-weight: 400;
  color: #484848;
}

#banner_slider .carousel-caption {
  text-align: left;
  bottom: 35%;
}

#banner_slider .carousel-caption button {
  border: 1px solid #484848;
  color: #484848;
  border-radius: 50px;
  margin-top: 20px;
}

.collections .row {
  padding: 75px 15px;
}

.collections .card {
  border: 0;
}

.collections .card h6 a {
  font-size: 20px;
  color: #000000;
}

.collections .card h6 {
  padding: 15px 15px;
  text-align: center;
}

.vir_appoin p {
  margin-top: 50px;
}

.vir_appoin p a {
  background: #283941;
  padding: 12px 25px;
  border-radius: 50px;
  color: #fff;
}

.img-collection {
  max-width: 200px;
}

.collections .card h6 a span {
  position: relative;
  right: -20px;
  color: #b18e61;
}

.tal_img {
  position: absolute;
  max-width: 320px;
  left: calc(15px - -3%);
  bottom: 0;
}

.how h2,
.test_i h2,
.s_heading h2,
.mail_letter h2,
#loginpop h2 {
  text-align: left;
  padding: 20px 0 0;
  color: #283941;
  font-weight: 300;
  font-size: 32px;
  font-family: "Roboto Condensed", sans-serif;
}

.s_heading+.table {
  font-family: "Roboto Condensed", sans-serif;
}

.how h2::after,
.test_i h2::after,
.s_heading h2::after,
.mail_letter h2:after,
#loginpop h2::after {
  content: "";
  display: none;
  height: 20px;
  /* background-image: url(../images/border-bttom.png); */
  background-repeat: no-repeat;
  background-position: center bottom;
}

.how .card h3 {
  color: #283941;
  font-size: 20px;
}

.how .card img {
  max-width: 35px;
  margin-bottom: 15px;
}

.how .card {
  border: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  background: transparent;
}

.how .card h5 {
  margin-bottom: 0;
  font-size: 18px;
  color: #7c7c7c;
  font-weight: 400;
}

.how .card p {
  font-size: 15px;
  font-weight: 400;
  padding-top: 8px;
}

.how {
  position: relative;
  background: #f9fbff;
  /* background-image: url(../images/brand-bg.png); */
  background-position: 73% 0px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0;
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
  border: 3px solid #dedede;
  background: #333333;
  list-style-type: none !important;
  cursor: pointer !important;
}

button.owl-next span,
button.owl-prev span {
  position: relative;
  top: -13px;
}

button.owl-next:focus,
button.owl-prev:focus {
  outline: 0 !important;
}

button.owl-next {
  position: absolute;
  right: 0;
  top: calc(100% - 50%);
  background: #ccc !important;
  width: 50px;
  height: 50px;
  font-size: 50px !important;
  color: #fff !important;
}

button.owl-prev {
  position: absolute;
  left: 0;
  top: calc(100% - 50%);
  background: #ccc !important;
  width: 50px;
  height: 50px;
  font-size: 50px !important;
  color: #fff !important;
}

.fabric .carousel-indicators li {
  width: 15px;
  height: 15px;
}

.carousel-indicators .active {
  opacity: 1;
  border: 3px solid #fff;
}

.cus_btn {
  background: rgb(108 125 32);
  color: #fff;
  border-radius: 50px;
}

.how .cus_btn {
  background: #17303a;
}

.btn.h_cus {
  background: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  display: block;
  margin-bottom: 10px;
  width: 200px;
  box-shadow: 3px 3px 8px rgb(107 107 107 / 50%);
  transition: 0.3s;
}

.collections .card h6 a:hover {
  color: #283941;
  text-decoration: none;
}

.cus_btn:hover {
  background: #c28136;
  color: #fff;
  transition: 0.3s;
}

.me_sr h3 {
  color: #b18e61;
}

.me_sr h3 {
  color: #b18e61;
  line-height: 1.5;
  letter-spacing: 1.5px;
  font-weight: 700;
}

.me_sr p {
  padding: 12px 0px;
  color: #828282;
}

.me_sr .card {
  border: 0;
}

.me_sr .card {
  border: 0;
  padding: 50px 5px;
}

.m_p {
  max-width: 450px;
  margin: 0 auto;
}

.me_sr .card p {
  font-size: 14px;
  color: #828282;
}

.me_sr {
  padding: 50px 0;
}

.test_i h4 {
  padding: 50px 20px 0px 20px;
}

.test_i .star {
  padding: 50px 20px;
}

.test_i h6 {
  padding: 20px 30px;
  border-bottom: 1px solid #9f9f9f;
}

.test_i .carousel-control-prev-icon,
.test_i .carousel-control-next,
#banner_slider .carousel-control-prev-icon,
#banner_slider .carousel-control-next {
  display: none;
}

.test_i .carousel-indicators .active {
  opacity: 1;
  border: 3px solid #000;
}

.test_i {
  position: relative;
  background: #f1f1f1;
  /* background-image: url(../images/brand-bg.png); */
  background-position: -42% 0;
  background-repeat: no-repeat;
  /* background-size: cover; */
  margin: 50px 0;
}

.sho_p ul.nav-tabs {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
}

.sho_p ul.nav-tabs li {
  text-align: center;
  display: block;
  align-items: center;
  padding: 5px 20px;
}

.sho_p ul.nav-tabs li a.active {
  border-bottom: 1px solid #333;
}

.sho_p ul.nav-tabs li a {
  border: 0;
}

.sho_p .tab-pane {
  padding: 50px 0;
}

.sho_p .tab-pane .card h4,
.sho_p .tab-pane .card h5 {
  margin: 2px 8px;
  font-size: 17px;
  font-weight: 400;
  color: #808080;
}

.sho_p .tab-pane .card p button {
  border: 1px solid #333;
  margin: 10px 8px;
}

.sho_p .tab-pane .card p {
  margin-bottom: 0;
}

.sho_p .tab-pane .card {
  border: 0;
}

.sho_p .tab-pane .card p button:hover {
  background: #333;
  color: #fff;
}

.vi_btn h6 {
  margin-top: 50px;
}

.vi_btn h6 a {
  background: rgb(108, 125, 32);
  color: #fff;
  padding: 8px 25px;
  border-radius: 50px;
  box-shadow: 1px 2px 6px rgb(126 126 126 / 60%);
}

.vi_btn h6 a:hover {
  background: #c47d3a;
  text-decoration: none;
  transition: 0.3s;
}

.mail_letter p {
  font-size: 16px;
  font-weight: 400;
}

.mail_letter .input-group input {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.mail_letter .input-group {
  max-width: 450px;
  margin: 0 auto;
}

.mail_letter .input-group-append .btn {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.footer {
  background: #f8f8f8;
  padding: 25px 0;
  text-align: center;
}

.footer .col-lg-3 {
  text-align: left;
  padding: 5px 51px;
}

ul.fabric_lft#fab-font-ht {
  height: 53vh;
  overflow: auto;
}

.fabric_lft.fabric_style#fabric_style {
  height: 53vh;
  overflow: auto;
}

.footer h4 {
  color: #283941;
  margin-bottom: 25px;
  font-weight: 300;
}

.footer ul li {
  padding: 6px 20px;
  list-style-type: none;
}

.footer ul {
  padding-left: 0;
}

.foot ul {
  margin-bottom: 0;
  text-align: right;
}

.foot ul li {
  display: inline-block;
}

.foot h6 {
  color: #283941;
  font-size: 14px;
  text-align: right;
}

.foot ul li a i {
  background: white;
  padding: 7px 7px;
  border-radius: 50px;
  box-shadow: 2px 1px 0px rgb(179 179 179 / 50%);
  color: #283941;
  transition: 0.3s;
}

.footer ul li a:hover {
  list-style-type: none;
  text-decoration: none;
  color: #000;
}

.foot .fa-facebook-f {
  padding: 7px 10px;
}

.footer ul li a {
  color: #333;
  font-weight: 400;
}

.foot ul li a i:hover {
  background: #283941;
  color: #fff;
  transition: 0.3s;
}

.btn.h_cus:hover {
  background: #6c7d20;
  color: #fff;
  transition: 0.3s;
}

.megamenu {
  width: 100%;
  border: 0;
  border-radius: 0;
}

.megamenu ul li a {
  font-weight: 400;
}

/* inner pages */
.side_banner {
  /* background: url(../images/banner_1.png); */
  background-size: cover;
  background-position: center;
}

.s_blazer {
  /* background: url(../images/banner_3.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.pant {
  /* background: url(../images/banner_2.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.suit_ban {
  /* background: url(../images/banner_4.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.side_banner .i_banner {
  padding: 195px 120px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.s_blazer h1,
.s_blazer p {
  color: #333 !important;
}

.s_blazer button {
  background: #929292;
  border: 0;
  padding: 5px 13px;
  border-radius: 24px;
  color: #333;
}

.side_banner button {
  background: #fff;
  border: 0;
  padding: 5px 13px;
  border-radius: 24px;
}

.i_banner p {
  font-size: 20px;
  color: #dbdbdb;
}

.h_d_y_s .card img {
  max-width: 75px;
}

.h_d_y_s .card img {
  max-width: 88px;
  width: 101px;
  height: 100px;
  margin: 0 auto;
}

.h_d_y_s .card {
  text-align: center;
  /* min-height: 240px; */
  border: 0;
}

.dgn_btn button:nth-child(2) {
  border: 0;
  background: transparent;
  margin-left: 20px;
}

.dgn_btn button:first-child {
  border: 1px solid #333;
  border-radius: 50px;
  padding: 5px 25px;
}

.h_d_y_s {
  margin: 20px 0;
}

.prdut_list .card p {
  margin-bottom: 0;
  font-size: 14px;
}

.prdut_list .card p span {
  color: #867e7e;
  font-weight: 400;
}

.prdut_list .card h5 {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 1px;
}

.prdut_list .card ul li button:hover,
.suit_banner .card ul li button:hover {
  background: white;
  transition: 0.3s;
  border: 1px solid #333;
  color: #333;
}

.prdut_list .card ul li button,
.suit_banner .card ul li button {
  border: 0;
  background: #6c7d20;
  color: #fff;
  border: 1px solid transparent;
  transition: 0.3s;
}

.prdut_list .card ul,
.suit_banner .card ul {
  padding-left: 0;
}

.prdut_list .card ul li:nth-child(3) button,
.suit_banner .card ul li:nth-child(3) button {
  background: #fff !important;
  color: #2b2b2b;
  border: 1px solid #8e8e8e;
}

.prdut_list .card {
  border: 0;
  margin-bottom: 50px;
  height: 140%;
}

.prdut_list .card ul li,
.suit_banner .card ul li {
  display: inline-block;
}

.suit_banner .card p {
  padding: 25px 0px;
}

.suit_banner .card h1 {
  font-size: 35px;
  color: #b18e61;
  font-weight: 700;
}

.suit_banner .card h3 {
  color: #b18e61;
}

.suit_banner .card {
  padding: 18px 25px;
  border: 0;
}

button.close {
  position: absolute;
  top: 25px;
  right: 35px;
  z-index: 1000;
  padding: 0 !important;
}

#loginpop label {
  width: 100%;
  text-align: left;
}

/* #loginpop input {
  margin: 15px auto;
  width: 100%;
} */

#loginpop .custom-control.custom-switch {
  text-align: center;
  margin: 25px auto;
  width: 145px;
}

#loginpop {
  text-align: center;
}

.btn-blue {
  width: 100%;
}

.btn-blue {
  background: #264350;
  width: 95%;
  margin: 15px 0px;
}

.btn-red {
  background: #ff465f;
  width: 88%;
  margin: 15px 0px;
}

.btn-sky {
  background: #61b0ce;
  width: 88%;
}

.btn-sky,
.btn-red,
.btn-blue {
  margin: 15px 0px;
  color: #fff;
  padding: 5px 0;
}

.btn-sky:hover,
.btn-red:hover,
.btn-blue:hover {
  color: #fff !important;
}

.form {
  max-width: 900px;
  margin: 30px auto;
  background: #f8f8f8;
  padding: 40px;
  border-radius: 8px;
  font-family: "Roboto Condensed", sans-serif;
}

#m_sign_up ul {
  padding: 0;
}

#m_sign_up h3 {
  font-size: 32px;
}

#m_sign_up h5,
#m_sign_up ul li {
  letter-spacing: 1px;
  list-style-type: none;
}

ul.my_detas li {
  display: inline-block;
}

ul.my_detas li i {
  color: #5d5d5d;
  font-size: 12px;
  padding: 0px 8px;
}

ul.my_detas {
  padding: 0;
  text-align: left;
}

ul.my_detas li a {
  color: #717171;
  font-size: 14px;
}

.btn.btn-gren {
  background: #264350;
  color: #fff;
}

.c-measurements ul li.active {
  border-top: 48px solid #264350 !important;
}

.nos {
  margin: 20px 30px 0px 30px;
  padding: 20px 0px 5px 0px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #264350;
  background-color: #264350;
}

.scnd .form-group {
  margin-left: 15px;
}

.scnd .btn-gren {
  font-size: 14px;
  padding: 6px 30px;
  border-radius: 0;
}

#loginpop p a {
  color: #264350;
  font-weight: 500;
  font-size: 16px;
}

#loginpop .modal-content {
  border-radius: 0.8rem;
}

.o_list li a {
  text-decoration: underline;
  color: #333;
}

.o_list li {
  list-style-type: none;
}

.fabric_lft li span {
  font-size: 13px;
}

.fabric_lft li h6 {
  font-size: 13px;
  margin: 0;
  width: 75%;
  /* min-height: 30px; */
}

.fabric_lft li p {
  margin: 0;
  line-height: 1.3;
}

.fabric_lft li a img,
.fabric_lft li img {
  max-width: 60px;
}

.fabric_lft li {
  display: inline-block;
  text-align: left;
  width: 31%;
  padding: 10px 4px;
  margin: 3px 0px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .mess-modal .modal-body {
    height: 560px;
    overflow: auto;
  }
}

.fab-fnt li {
  width: 48%;
  padding: 4px 0;
  margin: 0;
}

.fa-shopping-bag {
  position: relative;
}

b.notifi {
  position: absolute;
  background: #fff;
  font-size: 10px;
  right: -13px;
  z-index: 0;
  border: 2px solid #707070;
  padding: 2px 7px;
  top: -15px;
  border-radius: 50px;
  color: #ee1c1c;
}

ul.fabric_lft {
  background: #ffffff;
  padding: 0px 5px;
}

ul.fabric_lft,
.fabric_list {
  padding: 0;
  text-align: center;
}

#nek1 {
  min-height: calc(100vh - 25vh);
}

/* .vest-cart .folded-pant img {
  top: calc(100% - 100%) !important;
} */

ul.fabric_lft.fabric_border {
  overflow-y: scroll;
  margin: 0;
  height: 50vh;
  padding: 0;
  overflow-x: hidden;
}

ul.fabric_lft.me_chest {
  overflow-y: scroll;
  margin: 0;
  height: 80%;
  padding: 0;
  overflow-x: hidden;
}

ul.fabric-splits .bg-success {
  background: #66771d !important;
  border: 0;
  border-radius: 0;
  width: 70px;
  font-size: 14px;
}

.sticky_arrow {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  background-color: white;
  height: 30px;
}

.w-90 {
  width: 83%;
  display: inline-block;
}

#fab-font-ht .w-90 {
  width: 90%;
}

#fab-font-ht label {
  display: block;
}

b.clse {
  cursor: pointer;
  margin: 0 8px;
}

ul.fabric-splits .input-group {
  width: 100%;
  padding: 7px 5px;
}

.fabr-header h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding: 8px 15px;
  background: #fff;
  margin: 0;
}

.fabr-header {
  background: #fff;
  padding-bottom: 1px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: none;
  border-bottom: 1px solid #d4d4d4;
  z-index: 5;
}

.meas_001 label {
  width: 100%;
  display: block;
  text-align: left;
  margin: 20px auto;
  font-size: 21px;
}

.meas_001 h2 {
  font-size: 48px;
  font-weight: 300;
}

form.meas_001 {
  background: #f1f1f1;
  padding: 50px;
}

#m_001 {
  margin-top: 47px;
  background: #264350;
  color: #fff;
}

.grub-btn .form-control:focus {
  outline: none;
  box-shadow: none;
}

.fabric_list li {
  display: inline-block;
  padding: 2px 15px;
}

.fabric_list+#carouselExampleIndicators ol.carousel-indicators {
  bottom: -10px;
}

.fabric_list+#carouselExampleIndicators .carousel-indicators li {
  border: 5px solid #fff;
}

.fabric_list+#carouselExampleIndicators .carousel-indicators .active {
  border: 5px solid #fff;
  box-shadow: 2px 2px 3px rgb(130 130 130 / 50%);
}

.fabric_list+#carouselExampleIndicators .carousel-inner img {
  /* max-width: 285px; */
  /* width: calc(100% - 55%); */
  margin: 0 auto;
}

.fabric_rght h3 {
  color: #b18e61;
  font-size: 24px;
  margin-bottom: 20px;
}

.fabric_rght p {
  margin: 0;
}

.fabric_rght h6 button {
  border-radius: 50px;
  width: 180px;
  margin: 15px 0;
}

.fabric_lft li a {
  color: #333;
  position: relative;
  width: 100%;
  display: block;
}

.fabric_lft li a b {
  position: absolute;
  right: 0;
  top: calc(100% - 50%);
  font-size: 13px;
  font-weight: 500;
}

.fabric_list li a {
  color: #494949;
}

.fabric_styl2 li.active,
.fabric_style li.active {
  position: relative;
}

.fabric_style li.active:after {
  content: "";
  position: absolute;
  top: 10px;
  border: 5px solid #264350;
  border-radius: 50px;
  right: 10px;
}

.fabric_styl2 li.active:after {
  content: "";
  position: absolute;
  top: 10px;
  border: 5px solid #d6d4d8;
  border-radius: 50px;
  right: 10px;
}

.fabric_list li.active a {
  font-weight: 700;
}

.fabric_list+#carouselExampleIndicators .carousel-inner {
  min-height: 450px;
}

.fabric_style li .media-body {
  text-align: right;
  align-items: revert;
  font-size: 11px;
}

.fabric_style li {
  display: inline-block;
  width: 48%;
  padding: 10px 30px;
  border-bottom: 1px solid #ccc;
  z-index: 0;
  margin: 0 2px;
}

.fabric_style li .media {
  align-items: center;
}

.fabric_styl2 {
  list-style-type: none;
  padding: 10px 7px;
  background: #d6d4d8;
  width: 30%;
  text-align: center;
  min-height: calc(100vh - 30vh);
  overflow-y: scroll;
  max-height: 450px;
  margin: 0;
}

.fabric_styl2 li.active p {
  width: 100%;
  display: block;
  float: left;
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 0;
  margin: 0px 0px;
}

.fabric_styl2 li p {
  font-size: 12px;
}

.fabric_styl2::-webkit-scrollbar {
  width: 5px;
  background: #eeeeee;
}

.fabric_styl2::-webkit-scrollbar-track {
  background: transparent;
}

.fabric_styl2::-webkit-scrollbar-thumb {
  background: transparent;
}

.fabric_lft::-webkit-scrollbar {
  width: 2px;
  background: #eeeeee;
}

.grub-bt1 .form-control::-webkit-input-placeholder {
  font-size: 12px;
}

.grub-bt1 .form-control::placeholder {
  font-size: 12px;
}

/* Track */
.fabric_lft::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
.fabric_lft::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 25px;
}

#nek3 li.active {
  border: 1px solid #264350;
}

#nek3 li.inactive {
  border: 1px solid red;
}

/* Handle on hover */
.fabric_lft::-webkit-scrollbar-thumb:hover,
.fabric_styl2::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

.fabric_style {
  width: 100%;
}

ul.fabric-splits {
  padding: 0;
}

.fab_split {
  display: flex;
}

.fabric_styl2 li {
  margin: 12px 6px;
  background: #fff;
  padding: 15px 15px;
  font-size: 13px;
}

.mono li {
  width: 24%;
}

.mono li img {
  max-width: 65px;
}

.btn_desg li {
  width: 35%;
  text-align: center;
}

.cuff_desg li p {
  font-size: 12px;
  padding: 0 15px;
}

.btn-black {
  background: #333;
  color: #fff;
  border-radius: 50px;
  padding: 6px 16px;
  margin: 0px 5px;
  font-size: 14px;
}

.share {
  background: transparent;
  border: 0;
  font-size: 22px;
}

/* shopping cart */
.shopping_crt ul li a {
  font-size: 14px;
  padding: 5px 15px;
  color: #333;
  position: relative;
}

.shopping_crt ul li {
  display: inline-block;
}

.shopping_crt ul {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;
}

.border-btm-cus {
  border-bottom: 1px solid #333;
}

.s_addr_001 h2 {
  border-bottom: 1px solid #ccc;
  padding: 20px 0px;
  margin-bottom: 30px;
}

.cart_001 {
  font-size: 27px;
}

.shopping_crt form {
  max-width: 900px;
  margin: 0 auto;
}

.shopping_crt ul li.active a {
  font-weight: 600;
}

.shopping_crt ul li a:after {
  content: "<";
  position: absolute;
  right: 0px;
}

.shopping_crt ul li:last-child a:after {
  content: none;
}

.shopping_crt .media img {
  max-width: 50%;
  padding: 0px 4px;
}

.shopping_crt .media-body h3 {
  color: #264350;
  font-weight: 700;
}

.shopping_crt .media-body h3,
.shopping_crt .media-body h5,
.shopping_crt .media-body p {
  margin: 8px 0px;
}

.shopping_crt .media-body p {
  font-size: 14px;
}

.btn-s-cus {
  border: 1px solid #6b6b6b;
  border-radius: 50px;
  font-size: 12px;
  margin: 4px 8px 0px 0px;
}

.text-pink {
  color: #ff465f;
}

.shipp-crt {
  text-align: end;
  width: 100%;
}

.shipp-crt tr td {
  padding: 10px 20px;
  text-align: left;
}

.c-measurements h3 {
  color: #264350;
  text-align: center;
  font-size: 18px;
  margin-bottom: 25px;
}

.c-measurements ul {
  margin: 0 auto;
  padding-top: 100px;
}

.c-measurements ul li {
  display: inline-block;
  width: 32%;
  border: 1px solid #bebebe;
  padding: 25px 25px;
  vertical-align: middle;
  min-height: 320px;
  margin: -3px -0.45px;
}

.c-measurements ul li h5 b {
  color: #264350;
}

.c-measurements ul li:nth-child(2) {
  min-height: 430px;
  border: 2px solid #7e7e7e;
}

.c-measurements ul li button.btn.btn-gren {
  border-radius: 50px;
  width: 120px;
  font-size: 14px;
  margin-top: 20px;
}

.c-measurements p {
  margin: 0;
  padding-top: 17px;
}

.c-measurements ul li h6 {
  position: absolute;
  top: 0;
  color: red;
}

.c-measurements ul li {
  position: relative;
  width: 33%;
}

.c-measurements ul li h6 {
  position: absolute;
  top: -35px;
  text-align: center;
  color: #fff;
  left: 50%;
  margin-left: -35px;
}

.c-measurements ul li h4 {
  color: #264350;
  font-size: 18px;
  font-weight: 600;
}

.c-measurements ul li p {
  font-size: 15px;
  text-align: center;
}

.c-measure-quick p,
.c-measure-quick h4 {
  text-align: center;
}

.c-measure-quick form .row {
  margin: 20px 0px;
}

.c-measure-quick form {
  max-width: 768px;
  margin: 30px auto;
}

.c-measure-quickp li p {
  margin: 8px 0px;
  text-align: left;
  min-height: 25px;
  position: relative;
}

span.plus {
  left: -23px;
}

span.minus {
  margin-left: -23px;
  left: 23px;
}

.c-measure-quickp li {
  border: 0 !important;
}

.c-measure-quickp li h3 {
  color: #909090;
  padding: 12px 0px;
}

.firt {
  border-right: 1px solid #aaa;
}

.payment form table {
  width: 100%;
}

.payment form h4,
.scnd h4 {
  font-size: 18px;
}

.payment form table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.payment form table tr td {
  padding: 10px 0;
}

.scnd table {
  width: 100%;
  border: 1px double #6c7d20;
  margin: 25px 40px;
}

.scnd table tr td {
  padding: 15px;
}

.order h2 {
  color: #264350;
  font-size: 22px;
  font-weight: 600;
}

.order table tr th img,
.accordion table tr th img {
  max-width: 100px;
  padding: 15px;
  background: #e3e3e3;
}

.order table tr td,
.accordion table tr td {
  vertical-align: middle;
}

.order table,
.accordion table {
  display: inline-table;
  overflow: auto;
}

/* address */
.y-addrs .card-footer p {
  margin: 0;
  font-size: 14px;
}

.y-addrs .card-footer {
  border: 0;
  background: transparent;
}

.y-addrs .card {
  margin-bottom: 25px;
  box-shadow: 1px 2px 6px rgb(187 187 187 / 50%);
  min-height: 310px;
}

.y-addrs .card-body h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.y-addrs .card p.active {
  margin: 0px;
  padding: 5px 15px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 15px;
  color: #ababab;
  font-weight: 600;
}

.card.tdt .card-body {
  vertical-align: middle;
  margin: auto;
  display: inline-table;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.default {
  background: whitesmoke;
  border: 1px solid #264350;
}

.tdt .card-body h3 b {
  font-size: 50px;
  color: #ccc;
  font-weight: 100;
}

.tdt .card-body h3 {
  text-align: center;
}

/* designs */
.bars {
  display: block;
}

.bars .opt {
  margin: 10px 0;
}

.bars .title {
  text-transform: uppercase;
  font-size: 11px;
  display: inherit;
}

.bars .bar {
  position: absolute;
  right: 10px;
  margin-top: -6px;
  color: #000;
}

.bars .bar .subar.active {
  opacity: 1;
}

.bars .bar .subar {
  background-color: #c19d56;
  height: 5px;
  width: 55px;
  float: left;
  margin-right: 3px;
  opacity: 0.3;
}

.pop_up_design {
  width: 100%;
  margin-left: 0;
  float: right;
  display: inline;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10000;
}

.pop_up_design .card {
  z-index: 7;
  max-width: 500px;
  height: 300px;
  position: relative;
  top: 70px;
  left: 90px;
  overflow: auto;
  padding: 15px;
}

.bars .bar .subar span {
  top: 8px;
  position: absolute;
  font-size: 12px;
}

.bg_imgg {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
}

.bg_imgg img {
  height: 484px;
  width: 100%;
}

.clse_btn {
  color: #ffffff;
  z-index: 26;
  position: absolute;
  right: 0;
  padding: 6px 12px;
  font-size: 25px;
  background: #264350;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 33px;
}

.pop_up_design {
  display: none;
}

/* plus , minus */

.minus,
.plus {
  width: 25px;
  height: 25px;
  background: #264350;
  padding: 0px 0px 0px 0px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
  top: 0px;
  color: #fff;
  border-radius: 50px;
}

.number input {
  width: 70px;
  height: 24px;
  border-radius: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.accordion ul li img {
  max-width: 50px;
}

.accordion ul {
  padding: 0;
  margin: 0;
}

.accordion ul li {
  display: inline-block;
  padding: 0px 25px;
  vertical-align: bottom;
}

.accordion h3 {
  font-size: 18px;
}

.accordion .panel p,
.accordion .panel address {
  margin: 10px 5px;
  font-size: 16px;
}

.accordion .panel p b {
  font-size: 16px;
}

.order-r-stats table th {
  font-size: 15px;
  font-weight: 700;
  padding: 8px 0px;
}

.order-r-stats table tr td {
  width: 70%;
  padding: 8px 0px;
}

.accordion .card-header {
  padding: 0;
  background: transparent;
}

.accordion .card-header button {
  color: #4f4c4c;
  font-weight: 500;
}

.accordion .card-body {
  padding: 35px 35px;
}

.accordion {
  border: none !important;
}

.order-h3 {
  font-size: 23px;
  color: #2a627b;
  font-weight: 700;
  margin-top: 20px;
}

.order-h3 a {
  font-size: 14px;
  padding: 12px;
  color: #333;
  font-weight: 400;
}

/* progress status */
#progressbar {
  overflow: hidden;
  counter-reset: step;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
}


#progressbar li {
  list-style-type: none;
  color: #4E4E4E;
  text-transform: uppercase;
  font-size: 12px;
  width: 160px;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar .process_state {
  counter-increment: step;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 26px;
  display: block;
  list-style-type: none;
  color: #4E4E4E;
  background: #4E4E4E;
  border: 2px solid #4E4E4E;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar .process_state.notfilled {
  counter-increment: step;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 26px;
  display: block;
  list-style-type: none;
  color: #4E4E4E;
  background: #fff;
  border: 1px solid #4E4E4E;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar .process_state:after {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  content: "";
  width: 160px !important;
  height: 2px;
  background-color: #4E4E4E;
}

#progressbar .process_not::after {
  width: 0px !important;
  height: 0px;
}

/*progressbar connectors*/
/* #progressbar li:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #e7e7e7;
  position: absolute;
  left: -50%;
  top: 8px;
  z-index: -1;
  put it behind the numbers
} */

.order-status-confrm {
  font-family: "Roboto Condensed", sans-serif;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

li.active.step2:after {
  /* content: url(../images/order-icon.png); */
  position: absolute;
  top: 0;
}

#progressbar li.active span {
  position: absolute;
  top: 18px;
  left: calc(100% - 75%);
}

#progressbar li.active .icons-active {
  display: block;
  position: absolute;
  top: -50px;
  left: calc(20% - -15px);
  margin-left: 35px;
}

/* #progressbar li.active:before {
  position: relative;
  top: -8px;
} */

#progressbar li.step2:after {
  top: 8px;
}

#progressbar li.active.step3 {
  margin-bottom: 18px;
}

.icons-active {
  display: none;
}

.cus-slider2,
.cus-slider3,
.cus-slider4,
.cus-slider5,
.cus-slider6,
.cus-slider7,
.cus-slider8,
.cus-slider9,
.cus-slider10,
.cus-slider11,
.cus-slider12,
.cus-slider13,
.cus-slider14 {
  display: none;
}

.nec_k {
  background: #fff;
  height: calc(100vh - 13vh);
}

.nec_k ul.fabric_lft li {
  width: 45% !important;
  text-align: center;
  border: 1px solid #ccc;
  margin: 5px 5px;
  padding: 5px;
}

.nec_k_list li a {
  font-size: 14px;
}

.fa-check-circle {
  display: none;
}

.tick {
  position: relative;
}

.tick .fa-check-circle {
  display: inline;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #264350;
}

.nec_k_list li {
  padding: 5px 5px;
}

.cus-slider1-prve,
.cus-slider2-prve,
.cus-slider3-prve,
.cus-slider4-prve,
.cus-slider5-prve,
.cus-slider6-prve,
.cus-slider7-prve,
.cus-slider8-prve,
.cus-slider9-prve,
.cus-slider10-prve,
.cus-slider11-prve,
.cus-slider12-prve,
.cus-slider13-prve,
.cus-slider14-prve {
  background: #a5a5a5;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: #fff;
}

.cus-slider1-nxt,
.cus-slider2-nxt,
.cus-slider3-nxt,
.cus-slider4-nxt,
.cus-slider5-nxt,
.cus-slider6-nxt,
.cus-slider7-nxt,
.cus-slider8-nxt,
.cus-slider9-nxt,
.cus-slider10-nxt,
.cus-slider11-nxt,
.cus-slider12-nxt,
.cus-slider13-nxt,
.cus-slider14-nxt {
  background: #264350;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: white;
}

.cus-slider1-nxt-error,
.cus-slider2-nxt-error,
.cus-slider3-nxt-error,
.cus-slider4-nxt-error,
.cus-slider5-nxt-error,
.cus-slider6-nxt-error,
.cus-slider7-nxt-error,
.cus-slider8-nxt-error,
.cus-slider9-nxt-error,
.cus-slider10-nxt-error,
.cus-slider11-nxt-error,
.cus-slider12-nxt-error,
.cus-slider13-nxt-error,
.cus-slider14-nxt-error {
  background: #ccc;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: white;
}

#nek1 h4 {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin: 0;
}

.desk_neck {
  margin-bottom: 5px;
}

#nek1 p {
  font-size: 14px;
  margin-bottom: 10px;
}

input.value {
  height: 48px;
  text-align: center;
}

#btns li {
  list-style-type: none;
}

#btns li .btn {
  background: #ccc;
  margin: 10px 0px;
  width: 120px;
  border-radius: 50px;
}

#btns li .btn-gren {
  background: #264350 !important;
}

#f-two,
#f-three {
  display: none;
}

a.f-one.active,
a.f-two.active,
a.f-three.active {
  font-weight: 600;
}

.close-icon {
  display: none;
}

.collections .card .col-12 {
  text-align: center;
  padding: 21px 18px;
  min-height: 200px;
}

.collections .card .col-12:hover {
  box-shadow: 2px 2px 6px rgb(208 208 208 / 50%);
}

.grub-btn {
  padding: 7px 0;
}

.grub-btn .input-group-append .btn {
  padding: 4px 0px;
  font-size: 13px;
}

.grub-btn .form-control {
  height: 30px;
  border: 0;
  padding: 0 0 0 34px;
}

.btn-s-cus i {
  display: none;
}

.neck-add {
  display: none;
}

.neck-add i {
  font-size: 30px;
  color: #6c7d20;
  padding: 20px 0;
}

.mbl_neck {
  display: none;
}

#nek1 img {
  margin: 0 auto;
  height: calc(100vh - 58vh);
}

#measure {
  padding: 0;
}

.options_render li a img {
  max-width: 40px;
}

.options_render li a {
  color: #333;
  font-size: 14px;
}

.options_render li {
  display: block;
  padding: 6px 10px;
}

#cuff {
  display: none;
  position: absolute;
  left: 0;
  height: calc(100vh - 26vh);
  background: #fff;
  z-index: 5;
  overflow: auto;
}

#cuff::-webkit-scrollbar {
  width: 5px;
  background: #eee;
}

#cuff::-webkit-scrollbar-thumb {
  background: #aaa;
}

#cuff::-webkit-scrollbar-track {
  background: #eee;
}

.cuff_desg li img {
  cursor: pointer;
}

#cuff li img {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  height: 105px;
  width: 100%;
}

#cuff li {
  display: inline-grid;
  width: 30%;
  margin: 10px;
}

#cuff p {
  padding: 6px 12px;
}

#cuff h4 a i {
  font-size: 25px;
  color: #333;
}

#cuff h4 a {
  text-align: revert;
  float: right;
  margin-right: 0;
}

#cuff h4 {
  font-size: 18px;
  font-weight: 400;
  width: 100%;
}

p.sub_titles {
  margin: 0;
  font-size: 13px;
  line-height: 1;
}

p.titles {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.btn_desg_vbl li img {
  max-width: 90px;
}

.btn_desg li img {
  cursor: pointer;
}

.btn_desg_vbl {
  display: none;
}

.btn_desg_vbl li {
  width: 48%;
}

/* j6-21 */

.fabric {
  padding: 5px 5px 0 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mbl-shar-ico {
  display: none;
}

.second-header .nw_menu {
  padding-bottom: 10px !important;
}

.second-header .nw_login {
  padding: 5px 25px 0 15px !important;
}

#nw_noti {
  position: absolute;
  top: 2px;
  font-size: 11px;
  width: 16px;
  height: 16px;
  background: #fff;
  text-align: center;
  color: #000;
  border-radius: 15px;
  border: 1px solid #333;
  right: 5px;
}

#fab_fxd_n p {
  font-size: 14px;
  margin: 0;
  padding: 8px 22px;
}

.sec_mnu .nav-link.active:before {
  left: 0;
  content: "";
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid #6d6767;
  width: 80px;
  left: 0px;
  bottom: -10px;
}

.sec_mnu .nav-link.active {
  /* border-bottom: 2px solid #333; */
  position: relative;
}

#fab_fxd_n p:nth-child(2) {
  text-align: right;
}

.nw_rt {
  text-align: right;
}

.nw_snd_btn button {
  background: #fff !important;
  border: 1px solid #ccc !important;
}

.nw_snd_btn1 {
  background: #fff !important;
  border: 1px solid transparent !important;
}

.nw_drp {
  width: 100%;
  border: 0;
}

#customSwitch1 {
  margin: 0 !important;
  height: 28px;
  width: 40px !important;
  left: 0px;
  top: 2px;
  z-index: 333;
}

#loginpop button p i,
.form .btn-blue p i {
  font-size: 21px;
}

#loginpop button p,
.form .btn-blue p {
  margin: 0;
  margin-right: 10px;
}

.payment input.form-control:disabled {
  background: transparent;
  border: 0;
  border-bottom: 3px solid #377f9f;
  border-radius: 0;
  margin-bottom: 35px;
  padding: 0px 0px;
}

.payment,
.order {
  font-family: "Roboto Condensed", sans-serif;
}

.order p {
  font-size: 19px;
}

.order_001 a {
  font-size: 24px;
  -webkit-text-decoration: revert;
          text-decoration: revert;
}

.c-measure-quickp ul {
  max-width: 485px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.mm-man ul {
  max-width: 485px !important;
}

.mm-man ul li {
  width: 50% !important;
}

.scnd h4 {
  padding: 0 15px;
}

.s_001_img {
  max-width: 65px;
  margin: 0 12px;
}

.nw_tbl_mbl #profile_tbl {
  text-align: center;
  width: 80%;
}

.nw_tbl_mbl #profile_tbl thead tr th {
  width: 150px;
}

.c-measure-quickp {
  height: 60vh;
  overflow: auto;
}

#measure .modal-body p {
  font-size: 20px;
  font-weight: 500;
}

#measure .modal-content {
  border-radius: 15px;
  max-width: 500px;
  padding: 17px;
  margin-top: 5vh !important;
  margin: 0 auto;
  border: none;
}

#measure .modal-body .modal-title {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}

#measure .modal-body .m_content p {
  font-size: 18px;
}

#measure .modal-body .m_content p b {
  color: #264350;
}

#measure .modal-body button {
  width: 100%;
}

#measure .modal-body .fa-long-arrow-alt-right {
  float: right;
  margin-top: 4px;
}

.nos .media-body .row .col-lg-2 {
  margin: 20px 0px 0px -20px;
}

#see_all {
  width: 80%;
  text-align: center;
}

#see_all a {
  color: #333;
}


ul.nw_zoom {
  z-index: 100;
  position: absolute;
  top: 5%;
  right: 0%;
  padding: 0;
}

ul.nw_zoom li img {
  width: 30px;
  margin: 8px 2px;
}

ul.nw_zoom li {
  list-style-type: none;
  text-align: center;
  margin-top: 20px;
}

ul.nw_zoom li p {
  font-size: 14px;
}

.modal-dialog.modal-lg.zoom_full_width {
  height: 100%;
}

.media .pi-shirt-containers {}

.media .pi_container .custom_shirt {
  width: 17%;
  left: 3%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cart_suit {
  width: 14% !important;
  left: 4% !important;
}

/* .modal-content.canva_zoom_hgt {
  min-height: 90%;
  height: 100%;
} */

@media only screen and (max-width: 1024px) {

  .c-measure-quickp {
    height: 530px;
    overflow: auto;
  }

  .fabric_styl2 li img {
    max-width: 125px;
    height: 56px;
    position: relative;
    top: 4px;
    left: -2px;
  }

  #cuff li {
    display: inline-block;
    width: 20%;
    margin: 10px;
  }
}

/* @end j6-21 */
@media only screen and (min-width: 768px) {
  .nw_menu .navbar-nav .dropdown-menu {
    position: absolute;
    width: 800px !important;
    left: 0px;
    -webkit-transform: translate3d(-400px, 29px, 0px);
            transform: translate3d(-400px, 29px, 0px);
  }
}

/* @media only screen and (min-width: 540px) and (max-width: 720px) {
  img.img-fluid {
    max-height: 250px !important;
  }
}
@media only screen and (min-width: 280px) and (max-width: 653px) {
  img.img-fluid {
    max-height: 300px !important;
  }
} */

@media only screen and (min-width: 1250px) {
  .fabric_list+#carouselExampleIndicators .carousel-inner img {
    /* max-width: 250px; */
    /* width: calc(100% - 55%);
    margin: 0 auto; */
    width: calc(100% - 58%);
    margin: 0px auto;
    position: absolute;
    top: -30px;
  }

  .fabric_border {
    min-height: calc(100vh - 50vh) !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 991px) {
  .fabric_rght h6 button {
    border-radius: 50px;
    width: 140px;
    margin: 15px 0;
  }
}

@media only screen and (min-width: 1450px) {
  #nek1 img {
    margin: 0 auto;
    height: calc(100vh - 50vh);
  }
}

@media only screen and (min-width: 991px) {

  #l-r-dir,
  p.apply-btn {
    display: none;
  }

  .m2logo,
  .m2right {
    position: absolute;
  }

  .m2right {
    right: 0;
  }

  .m2logo {
    right: calc(100% - 60%);
  }

  .menu2_rgt {
    position: absolute;
    right: 0;
    top: 8px;
  }

  .menu2_cnt {
    position: absolute;
  }

  .fab-menu {
    padding: 0 120px;
    margin-top: -10px;
  }
}

@media only screen and (min-width: 1024px) {

  .fb-img,
  .fab-d-b {
    display: none;
  }

  .fab-d-n {
    display: inline;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {

  ul.nw_zoom li p {
    font-size: 12px;
  }

  .shopping_crt ul li a span {
    display: none;
  }

  .fabric_lft::-webkit-scrollbar {
    width: 2px;
    background: #eeeeee;
  }

  .c-measurements ul li {
    display: flex;
    min-height: auto;
    border: 0;
    border-bottom: 1px solid #bebebe;
    margin: 0;
  }

  .c-measurements ul li h6 {
    display: none;
  }

  .c-measurements ul li.active {
    border-top: 0 !important;
  }

  .c-measurements ul li p {
    padding: 0;
    text-align: left;
  }

  .c-measurements ul li {
    padding: 0;
  }

  .c-measurements ul li h3 {
    width: 30%;
    text-align: center;
  }

  .c-measurements ul li:nth-child(2) {
    min-height: auto;
    border: 0;
    border-bottom: 1px solid #bebebe;
  }

  .c-measure-list {
    display: inline;
    width: 65%;
    text-align: left;
    float: left;
  }

  .c-measurements ul li button.btn {
    display: none;
  }

  .nos p {
    display: none;
  }

  .nos button.btn {
    font-size: 12px;
    margin: 5px 7px;
  }

  #profile_tbl {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .nw_tbl_mbl {
    display: block;
    width: 100%;
    overflow-x: auto;
    max-width: 475px;
  }

  #profile_tbl {
    width: 375px;
    text-align: center;
  }

  #profile_tbl tr td,
  #profile_tbl tr th {
    font-size: 14px;
  }

  #profile_tbl tr td button {
    font-size: 13px;
  }

  .nw_ord_nm {
    display: none;
  }

  span.fb-txt,
  span.txt-arrow {
    display: none;
  }

  #nw_mbl_icon a i {
    display: inline;
  }

  .fabric_styl2::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }

  .meas_001 h2 {
    font-size: 23px;
    font-weight: 500;
  }

  form.meas_001 {
    padding: 20px 10px 0px 10px;
  }

  #m_001 {
    margin-top: 18px;
  }

  .meas_001 label {
    margin: 15px auto;
    font-size: 18px;
  }

  .media .pi_container .custom_shirt {
    /* left: 0;
    top: calc(100% - 100%);
    position: absolute; */
    width: 24%;
    /* left: 3%; */
    position: absolute;
    top: 49px !important;
    bottom: 0;
    margin: 0 !important;
    /* margin: auto; */
  }

  .cart_suit {
    width: 24% !important;
  }

}

@media only screen and (min-width: 1440px) {
  .media .pi_container .custom_shirt {
    width: 11%;
    left: 3%;
    position: absolute;
    top: 0;
    bottom: 15px;
    margin: auto;
  }

  .cart_suit {
    width: 11% !important;
    left: 3% !important;
  }
}

@media only screen and (max-width: 1024px) {

  .left-bar {
    display: block;
  }

  #fab_fxd_n p {
    width: 50%;
  }

  .custom-collar li.active:after {
    right: 10px !important;
    border: 6px solid #ccc !important;
  }

  .btn_desg li.active:after {
    right: 65px !important;
  }

  .casual-icons {
    position: fixed;
    top: 80px;
    right: 0;
    z-index: 9999 !important;
    margin: 10px 0;
    cursor: pointer;
  }

  .mbl-fxd-fab {
    height: auto !important;
  }

  .mbl-shar-ico {
    position: fixed !important;
    top: 58px !important;
  }

  #cuff {
    display: none;
    position: absolute;
    left: 0;
    height: 50%;
    background: #fff;
    z-index: 1;
    overflow: auto;
    bottom: -40px;
    width: 100%;
  }

  .canvas-loaderAlign {
    left: calc(100% - 50%);
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30px;
    height: 30px;
  }

  .casual-icon {
    position: absolute;
    top: 46px;
    right: 15px;
    z-index: 999;
    bottom: auto;
    font-size: 12px;
    text-align: center;
  }

  .mbl-fxd-fab {
    position: fixed;
    bottom: 40px;
    width: 100%;
    background: #f7f7f7;
    padding-top: 6px;
    left: 0;
    z-index: 1023;
  }

  .fab-cnt-p,
  .fab-d-n {
    display: none;
  }

  .mbl-shar-ico {
    position: fixed;
    top: 58px;
    right: 0;
  }

  .fabric_rght {
    margin-top: 90px;
    padding-top: 20px;
  }

  .fabric_list+#carouselExampleIndicators ol.carousel-indicators {
    bottom: -95px;
  }

  span.fb-txt,
  span.txt-arrow {
    display: none;
  }

  .fabric_rght p {
    font-size: 12px;
    margin-bottom: 0 !important;
    text-align: right;
  }

  .casual-icons img {
    max-width: 46px !important;
  }

  #fab_fxd_n li {
    width: 49% !important;
  }

  .fabric_rght h4 {
    font-size: 18px;
  }

  .prc-fab {
    width: 30%;
    float: left;
  }

  .fabric_rght h3 {
    color: #283941;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .fabric_rght h6 button {
    border-radius: 50px;
    width: 78px;
    margin: 5px -5px;
    font-size: 11px;
    position: absolute;
    top: 0;
    right: 15px;
  }

  .m_p {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .tal_img {
    display: none;
  }

  .sho_p .tab-pane .card {
    margin-bottom: 15px;
  }

  .btn-s-cus {
    margin: 10px 8px 0px 0px;
  }

  .c-measurements ul li {
    width: 100%;
    margin-bottom: 25px;
    padding: 12px 12px;
  }

  #measure .modal-body {
    padding: 0px;
  }

  #measure .modal-body form {
    height: 100%;
    overflow-x: auto;
  }

  #measure .modal-title {
    font-size: 16px;
  }

  .c-measure-quickp ul {
    max-width: 550px;
    overflow: auto;
    display: block;
    width: 100%;
  }

  .c-measure-quickp ul li {
    display: inline-block;
    width: 33% !important;
    padding: 0px 6px;
  }

  .c-measurements ul li p {
    font-size: 12px;
  }

  .accordion ul li {
    display: block;
    padding: 12px 0;
  }

  /* #progressbar {
    display: none;
  } */

  #progressbar li {
    width: 160px;
    height: 60px;
  }

  #progressbar li p {
    font-size: 9px;
  }

  #progressbar .process_state:after {
    position: absolute;
    top: 10px;
    display: flex;
    align-items: center;
    content: "";
    width: 115px !important;
    height: 2px;
    background-color: #4E4E4E;
  }

  #progressbar .process_not::after {
    width: 0 !important;
    height: 0 !important;
  }

  .order-h3 {
    font-size: 18px;
  }

  .order-status-confrm .btn-gren {
    font-size: 12px;
  }

  .fabric_rght {
    margin-top: auto;
    padding-top: 20px;
  }


  .fabric_style li .media img {
    max-width: 50px;
  }

  .nec_k_list {
    position: static !important;
    background: white !important;
    padding: 0;
    margin: auto !important;
    text-align: center;
  }

  .nec_k {
    padding: 10px 0 !important;
  }

  span.f-close {
    position: absolute;
    right: 30px;
    top: 0px;
    display: none;
  }

  .close-icon {
    display: contents;
    position: absolute;
    right: 0px;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner img {
    height: auto;
  }

  span.f-close i {
    font-size: 18px;
  }

  .bars .bar .subar {
    background-color: #c19d56;
    height: 5px;
    width: 30px;
    float: left;
    margin-right: 3px;
    opacity: 0.3;
  }

  #nek1 img {
    margin: 0 auto;
    height: 200px;
  }

  #nek1 {
    min-height: auto;
  }
}

@media only screen and (max-width: 1250px) {
  .desk_menu {
    display: block;
  }

  .menu2 ul li {
    display: block;
  }

  #menu_desk {
    z-index: 10000;
  }

  .close_icon {
    position: absolute;
    top: 30px;
    right: 15px;
    font-size: 28px;
  }

  ul.car_t {
    float: left;
  }

  .sc_m,
  .car_t li input,
  .logo {
    display: none;
  }

  #menu_desk .col-lg-4.my-auto {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .second-header .nw_menu {
    box-shadow: none;
    width: 100%;
  }

  .sec_mnu .nav-link.active:before {
    display: none;
  }
}

@media only screen and (max-width: 575px) {

  .btn_desg li {
    width: 25%;
    text-align: center;
  }

  .fabric_style li {
    padding: 10px 20px;
  }

  .fabric_style li .media img {
    max-width: 34px;
  }

  .fabric_style li .media-body,
  .fabric_styl2 li {
    font-size: 12px;
    width: 160px !important;
    height: 78px;
    padding: 0px 8px;
  }

  .fabr-header h3 {
    display: none;
    margin-top: 45px;
  }

  .scnd table {
    margin: 25px 0px;
  }

}

@media only screen and (max-width: 991px) {

  .fabric_lft li {
    padding: 10px 3px 10px 0;
  }

  .fabric_lft li.cursor-pointer {
    width: 50% !important;
  }

  .fabric_styl2 li {
    margin: 3px 6px;
  }

  .fabric_style li .media-body {
    text-align: center;
    align-items: revert;
    position: relative;
    top: 35px;
  }

  .cus-slider1-nxt,
  .cus-slider2-nxt,
  .cus-slider3-nxt,
  .cus-slider4-nxt,
  .cus-slider5-nxt {
    height: 35px;
    padding: 4px 2px;
    color: #fff;
  }

  .cus-slider1-prve,
  .cus-slider2-prve,
  .cus-slider3-prve,
  .cus-slider4-prve,
  .cus-slider5-prve {
    height: 35px;
    padding: 4px 2px;
    color: #fff;
  }

  .min-header {
    display: none;
  }

  .mbl-fxd {
    position: fixed;
    top: 65px;
    right: 0px;
  }

  .c-measure-quickp li p {
    min-height: 38px;
    position: relative;
  }

  .c-measure-quickp {
    padding: 0;
  }

  #measure .btn.btn-secondary {
    margin: 12px 0px;
    padding: 3px 10px;
  }

  ul#btns {
    text-align: center;
    padding: 0;
  }

  .mbl_neck {
    display: block;
  }

  .desk_neck {
    display: none;
  }

  .neck-add {
    display: block;
    margin-bottom: 0;
    text-align: center;
  }

  ul.fabric_list {
    padding-top: 6px;
    padding-bottom: 6px;
    position: fixed;
    bottom: 0;
    background: #ccc;
    width: 100%;
    left: 0;
    margin-bottom: 0;
    z-index: 10;
  }

  /* .fab-stys {
    display: none;
  } */
  .nec_k_list li a {
    font-size: 26px;
    padding: 0 10px;
  }

  .nec_k_list li a i {
    font-size: 24px;
  }

  .nec_k_list li a {
    color: #939393;
  }

  .cus-slider1 h4,
  .cus-slider2 h4,
  .cus-slider3 h4,
  .cus-slider4 h4,
  .cus-slider5 h4 {
    padding: 10px 0;
  }

  .fab-stys {
    display: block;
  }

  #nek3 {
    display: -webkit-box !important;
    /* display: -ms-flexbox; */
    overflow-x: scroll !important;
    position: relative;
  }

  .l-dir {
    border: 1px solid #ccc;
    background: #283941;
    color: #fff;
    padding: 4px 10px;
    font-size: 15px;
    margin: 0 10px;
  }

  .r-dir {
    border: 1px solid #ccc;
    background: #283941;
    color: #fff;
    padding: 4px 10px;
    font-size: 15px;
    margin: 10 0px;
  }

  #l-r-dir {
    position: absolute;
    bottom: calc(100% - 50%);
    right: 15px;
    z-index: 666;
    margin: 0;
    width: 90%;
  }

  button.r-dir {
    position: absolute;
    right: -12px;
  }

  button.l-dir {
    position: absolute;
    left: -32px;
  }

  .nec_k ul.fab-stys li {
    width: 27% !important;
    height: 70%;
    text-align: center;
    border: 1px solid #ccc;
  }

  .fabric_lft li a img,
  .fabric_lft li img {
    max-width: 40px;
  }

  .l-icons {
    max-width: 25px !important;
  }

  .fabric_lft li h6 {
    font-size: 11px;
  }

  .fabric_lft li p span {
    font-size: 12px;
  }

  .fabric_lft li p {
    margin: 0;
  }

  .fab-stys li {
    display: block;
  }

  .btn-s-cus {
    border-radius: 5px;
  }

  .btn-s-cus i {
    display: block;
  }

  .btn-s-cus span {
    display: none;
  }

  .shopping_crt ul {
    text-align: center;
    padding: 0;
  }

  .shopping_crt ul li a i {
    font-size: 22px;
    margin: 0 12px;
  }

  .second-menu .megamenu.show {
    -webkit-transform: translate3d(-0px, -11px, 0px) !important;
            transform: translate3d(-0px, -11px, 0px) !important;
  }

  .second-menu .dropdown-menu {
    min-width: 0px;
  }

  #banner_slider {
    position: relative;
    top: 0px;
  }

  .mobile_menu {
    padding: 12px 0;
    /* display: none; */
  }

  .mobile_menu i {
    font-size: 24px;
  }

  .foot ul {
    margin-bottom: 0;
    text-align: center;
  }

  .footer ul li {
    padding: 6px 8px;
  }

  .foot h6 {
    text-align: center;
  }

  /* shirts */
  .side_banner .i_banner {
    padding: 20px 45px;
    color: #fff;
  }

  .side_banner .i_banner p {
    font-size: 16px;
  }

  .side_banner .i_banner h1 {
    font-size: 26px;
  }

  .side_banner button {
    padding: 5px 15px;
    border-radius: 28px;
    font-size: 14px;
  }

  .how h2,
  .test_i h2,
  .s_heading h2,
  .mail_letter h2 {
    font-size: 22px;
  }

  .h_d_y_s .card p,
  .prdut_list .card ul li button {
    font-size: 14px;
  }

  .dgn_btn button:nth-child(2) {
    margin-top: 20px;
  }

  p {
    font-size: 14px;
  }

  .cus-slider1 p {
    padding: 5px 12px;
  }

  #btns li {
    list-style-type: none;
    display: inline-block;
    margin-top: 10px;
  }

  #btns li .btn {
    background: #ccc;
    margin: 5px 8px;
    width: 100px;
    border-radius: 50px;
  }

  input.value {
    height: 35px;
    border: 1px solid black;
  }

  p.apply-btn a {
    color: #fff;
  }

  p.apply-btn {
    position: absolute;
    bottom: -8px;
    width: 100%;
    text-align: center;
    padding: 6px 2px;
    background: #0085aa;
    left: 0;
  }
}

#nek3 li img {
  width: auto;
  height: auto;
}

@media only screen and (min-width: 1250px) {

  .mobile_menu,
  #close_x,
  #close_mega {
    display: none;
  }

  .measurement-modal .modal-body {
    min-height: calc(100vh - 15vh);
    min-height: 460px;
    overflow: auto;
  }
}

@media only screen and (max-width: 401px) and (min-width: 768px) {
  .shopping_crt .media img {
    max-width: 180px;
    padding: 10px 10px;
  }

  /* .rec-carousel{
      max-height: 250px;
  } */
}


@media only screen and (min-width: 1025px) {
  .right-bar {
    display: none;
  }

  .shopping_crt ul li a i {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .shopping_crt .media img {
    max-width: 70%;
    padding: 10px 10px;
    margin: 0px;
  }

  /* .media .pi-shirt-containers {
    height: 260px !important;
  } */

  .desk_menu {
    height: 20%;
    box-shadow: none;
  }
}

#initials_svg {
  right: 0;
  z-index: 1;
  left: 0;
  margin-top: 25%;
  position: absolute;
  width: 100%;
}

.active-mono::after {
  content: "";
  position: absolute;
  border-bottom: 4px solid red;
  bottom: 2px;
  width: 40px;
}

.custom-collar li {
  width: 24%;
  text-align: center;
  float: left;
  position: relative;
}

.custom-collar li.active,
.btn_desg li.active {
  position: relative;
}

.custom-collar li.active:after,
.btn_desg li.active:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 35px;
  border: 6px solid #264350;
  border-radius: 50%;
}

.casual-icons {
  margin: 20px 0 20px 0;
}

.btn_desg li.active:after {
  right: 130px;
}

.casual-icons img {
  max-width: 76px;
}

.casual-icons p {
  margin-bottom: 5px;
}

.mbl-fxd-fab {
  /* border-left: 1px solid #dcdcdc; */
  padding: 0 5px;
  height: 100%;
  margin: 0 auto;
}

.fabric_rght {
  border-left: 1px solid #dcdcdc;
}

.mbl-shar-ico {
  position: absolute;
  top: 50px;
}

.fabric_lft .cursor-pointer img {
  width: 100%;
  height: 100px;
  max-width: initial;
  border-radius: 5px;
}

.fabric_lft li.cursor-pointer {
  width: 32.5%;
}

.fabric_lft li.cursor-pointer p {
  position: absolute;
  top: 80px;
  text-align: center;
  width: 100%;
  color: #fff;
}

.fabric_lft li.cursor-pointer h6 {
  text-align: center;
  width: 100%;
  padding-top: 5px;
}

p#s_icons {
  position: absolute;
  left: 18px;
  top: 12px;
}

.fab_split .fabric_lft li img {
  max-width: 60px;
  height: auto;
}

.accordion__button {
  text-align: center !important;
  background-color: #ffffff !important;
  font-size: 25px;
}

.accordion__button:before {
  position: relative;
  left: 200px;
  display: none !important;
}

#cuff_close {
  float: right;
  margin-top: 10px;
}

.card .card_ll img {
  height: 382px;
}

/* filters search options */
#filters_collapse1 ul li,
#filters_collapse2 ul li,
#filters_collapse3 ul li,
#filters_collapse4 ul li,
#filters_collapse5 ul li,
#filters_collapse6 ul li {
  display: inline-block;
  padding: 15px;
  width: 49%;
}

#filters_collapse1 ul,
#filters_collapse2 ul {
  padding: 0 15px;
}

#filters_collapse2 ul li {
  display: inline-block;
  padding: 12px 45px;
  width: 50px;
  height: 50px;
}

#filters_collapse2 ul li span {
  /* background: url(../images/colors_filter_sprite_shirt.svg) no-repeat; */
  background-size: 100%;
  border: 2px solid transparent;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: block;
}

#filters_collapse2 ul li span.grey {
  background-position: 0 -1px !important;
}

#filters_collapse2 ul li span.blue {
  background-position: 0 -36px !important;
}

#filters_collapse2 ul li span.black {
  background-position: 0 -70px !important;
}

#filters_collapse2 ul li span.brown {
  background-position: 0 -105px !important;
}

#filters_collapse2 ul li span.green {
  background-position: 0 -313px !important;
}

#filters_collapse2 ul li span.white {
  background-position: 0 -139px !important;
}

#filters_collapse2 ul li span.purple {
  background-position: 0 -382px !important;
}

#filters_collapse2 ul li span.orange {
  background-position: 0 -174px !important;
}

#filters_collapse2 ul li span.beige {
  background-position: 0 -207px !important;
}

#filters_collapse2 ul li span.yellow {
  background-position: 0 -242px !important;
}

#filters_collapse2 ul li span.pink {
  background-position: 0 -277px !important;
}

#filters_collapse2 ul li span.red {
  background-position: 0 -346px !important;
}

span.title_c {
  float: right;
  font-size: 25px;
  color: #6d6d6d;
  font-weight: 100;
  line-height: 1;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.titles {
  padding: 16px 12px;
}

#accordion .card-header,
#accordion .card {
  background: #fff;
  border: 0;
}

.modal_custom {
  overflow: auto !important;
}

#accordion .card-header .btn::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
  font-size: 17px;
  color: #717171;
}

#accordion .card-header .collapsed::after {
  content: "\f106";
}

#filters {
  position: absolute;
  left: 0;
  z-index: 100;
  background: #fff;
  top: 0px;
  box-shadow: 3px 3px 3px 1px rgb(202 202 202 / 50%);
  height: 100%;
  z-index: 99999;
}

#accordion {
  overflow-y: scroll;
  height: 445px;
}

#accordion .card-header {
  border-bottom: 1px solid #ccc;
  padding: 0;
}

#accordion .card-header .btn {
  padding: 15px 15px;
  width: 100%;
  color: #333;
  text-decoration: none;
  text-align: left;
}

#accordion .card-header .btn:focus {
  outline: 0;
  box-shadow: none;
}

#fab_fxd_n li button {
  width: 90%;
  border: 0;
  padding: 6px 12px;
  font-size: 18px;
  color: #000;
  letter-spacing: 2px;
  border-radius: 8px;
}

#fab_fxd_n li {
  display: inline-block;
  width: 50%;
  text-align: center;
}

#measure-form .add_content {
  height: 50vh;
  overflow: auto;
}

#fab_fxd_n {
  position: fixed;
  bottom: 1px;
  width: 50%;
  background: #fff;
  margin: 0;
  left: 0;
  /* z-index: 100; */
  padding: 0;
}

#fab_fxd_n li:last-child button {
  background: #333333;
  color: #fff;
}

li.cursor-pointer.active {
  background: white;
  border: 1px solid #264350;
  border-radius: 5px
}

li.cursor-pointer.active p {
  padding-top: 10px;
}

.fabric_styl2 .cursor-pointer.active {
  display: inherit;
}

.navTemplateDesign {
  z-index: 9;
}

@media (min-height: 801px) and (max-height: 900px) {
  .fabric {
    height: 78vh;
  }
}

@media (min-height: 901px) and (max-height: 1100px) {
  .fabric {
    height: 100%;
  }
}

@media (min-height: 600px) and (min-width: 600px) {
  .sport-jacket {
    height: 100vh;
  }
}

.nw_login .empty {
  height: 23px;
}

.pant_canvas ul.nw_zoom {
  z-index: 100;
  position: absolute;
  top: 5%;
  right: 0%;
  padding: 0;
}

@media screen and (max-width: 400px) and (min-width: 300px) {

  /* .carousel-indicators {
    display: none !important;
  } */

  .shopping_crt,
  .shopping_crt .media .col-4 {
    padding: 0;
  }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

  /* .carousel-indicators {
    display: none !important;
  } */

  .shopping_crt,
  .shopping_crt .media .col-4 {
    padding: 0;
  }

  /* .rec-carousel {
    max-height: 250px !important;
  } */
}


@media screen and (max-width: 1025px) and (min-width: 768px) {
  .nw_menu .navbar-brand {
    line-height: 1 !important;
    font-size: 30px !important;
  }

  #fab_fxd_n {
    width: 50%;
  }
}

.verify-casual .carousel,
.verify-casual .carousel .carousel-inner,
.verify-casual .carousel .carousel-inner .carousel-item {
  height: 100%;
}

#f-one ul.fabric-splits {
  margin: 0;
}

.fabric #f-one {
  padding: 0;
}


@media screen and (max-width: 1024px) and (min-width: 769px) {

  ul.nw_zoom li img {
    width: 40px;
    margin: 8px 2px;
  }
}


@media screen and (max-width: 426px) and (min-width: 376px) {
  ul.nw_zoom li img {
    width: 26px;
    margin: 0px 2px;
  }

}

@media (max-width: 575.98px) {

  /* .accordion .card-body{
    padding: 0 !important;
  } */
  #cart_deatils .modal-content {
    margin-top: 0vh !important;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
  }



  ul.fabric_lft.me_chest {
    height: 100%;
  }

  .shopping_crt .media img {
    max-width: 80%;
    padding: 0px 4px;
  }

  .close span {
    font-size: 30px;
  }

  #measure .modal-content {
    border-radius: 0;
    max-width: 500px;
    padding: 20px;
    /* height: 130vh; */
    margin-top: 0 !important;
    margin: 0;
    width: 100vw;
    border: none;
  }

  .nos_pop .modal-body {
    height: 480px !important;
    overflow: auto;
  }

  .fabric_lft.fabric_style#fabric_style {
    height: 15vh;
    overflow: auto;
  }

  #fab_fxd_n {
    width: 100% !important;
    /* position: initial !important; */
  }

  #measure-form .add_content {
    height: 75vh;
    overflow: auto;
  }

  /* .widget-app-container{
    bottom: -14px !important;
  } */

  /* .rec-carousel {
    max-height: 250px !important;
  } */
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .second-header {
    height: 10vh;
  }

  ul.fabric_lft.fabric_border {
    height: 27vh;
  }

  .fabric .verify-casual {
    height: 50vh !important;
  }

  .fabric #f-one {
    height: 32vh !important;
  }

  .vh_80 {
    height: 100vh !important;
  }

  .fabric_lft.fabric_style#fabric_style {
    height: 23vh;
  }

  ul.fabric_lft#fab-font-ht {
    height: 30vh;
  }

  .fabric .suit-monogram {
    width: 100%;
    top: 60px;
    left: 0;
  }

  .ml-3.breadcum.d-none.d-sm-block {
    display: none !important;
  }

  /* img .img-fluid {
    max-height: 400px !important;
  } */
}

@media screen and (max-width: 400px) and (max-height: 850px) {
  #fab_fxd_n {
    bottom: 20px;
    z-index: 5;
  }

  .widget-app-container {
    bottom: -45px;
    -webkit-transform: scale(0.5);
            transform: scale(0.5)
  }

  .dvBAhE {
    right: -55px;
  }
}
.pi_elements_container .element {
    border-bottom: 1px solid #e3e3e3;
    height: 100px;
    padding: 23px 10px;
    cursor: pointer;
}

.pi_elements_container .element div img {
    width: 80%;
}

.pi_elements_container .element div.text-uppercase {
    font-size: 20px;
}

.hide {
    display: none;
}

/* .active {
  display: flex;
} */

.pi_elements_container .element .pi_dropdown {
    position: absolute;
    width: 160px;
    min-height: 100px;
    padding: 5px 16px;
    background: #fff;
    border: 1px solid #e3e3e3;
    text-align: center;
    right: -90px;
    z-index: 99999999;
    margin-top: -80px;
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}

.pi_elements_container .element .element_container_parent {
    width: 100%;
    min-height: 70px;
}

.pi_elements_container .element .pi_dropdown .head {
    border-bottom: 1px solid #e3e3e3;
    text-align: left;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item {
    padding: 5px;
    border-bottom: 1px solid #e3e3e3;
    margin-top: 10px;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item:last-child {
    border: none;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_item:hover {
    background: #f1f1f1;
}

.pi_elements_container .element .pi_dropdown .pi_dropdown_dismiss {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999999;
    border: 1px solid #e3e3e3;
    padding: 6.5px 6px;
    border-radius: 0px !important;
}

.pi_sidebar_toggle_dismiss {
    display: none;
}

.cloth_container {
    overflow: auto;
    padding: 15px 5px;
}

.cloth_container .cloth_elements {
    list-style: none;
    padding-left: 0;
}

.cloth_container .cloth_elements .cloth_el {
    display: inline-block;
    width: 110px;
    margin: 0 5px;
    text-align: center;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #f0f0f0;
}

.cloth_container .cloth_elements .cloth_el:hover {
    border: #444;
    box-shadow: 0px 0px 4px 0px #969696;
}

.cloth_container .cloth_elements .cloth_el img {
    width: 100%;
}

.pi_fabric_previewer img {
    margin-top: 35%;
}

.pi_fabric_previewer button {
    text-align: center;
    width: 49%;
}

li.pi_selected_option:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background-size: cover;
    margin-left: 90px;
    margin-top: -10px;
}

li.pi_selected_option.fit_el:before {
    margin-left: 20px !important;
    margin-top: 15px !important;
}

.pi_selection_overview li {
    padding: 10px 5px;
    border-bottom: 1px solid #e3e3e3;
}

.pi-card-body {
    min-height: 650px;
}

.pi_sidebar_toggle_dismiss {
    position: absolute;
    top: 4px;
    right: -40px;
}

/* .modal-body.zoom-canvas {
  height: 95vh;
  overflow: auto;
} */

.zoom-canvas .pi-shirt-containers {
    height: 150vh;
    overflow: auto;
}

.blazer-canvas .pi_container .custom_shirt {
    top: 100px;
}

.zoom_full_width .custom_shirt {
    top: 10px;
}

@media only screen and (max-width: 900px) {
    .pi_sidebar_floating {
        display: none;
        position: absolute;
        top: -50px;
        left: -15px;
        z-index: 99999999;
        background: #ffffff;
        max-width: 300px;
        height: 100vh;
    }

    .pi_toggler {
        display: block;
    }

    .pi_sidebar_toggle_dismiss {
        display: block;
    }

    .pi_elements_container .element {
        height: 80px;
        padding: 15px 10px;
    }

    .pi_elements_container .element .pi_dropdown {
        top: 125px;
    }

}

@media (max-width: 1240px) and (min-width: 992px) {

    .shirt_zoom .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .pop_up_design .card {
        max-width: 390px;
        top: 65px;
        left: 50px;
    }
}

@media (max-width: 1023px) and (min-width: 426px) {
    .vh_80 {
        height: 150vh;
    }

    .fabric .verify-casual {
        height: 70vh;
    }

    .fabric #f-one {
        height: 80vh;
        display: block !important;
    }

    #fab_fxd_n {
        width: 100% !important;
        position: fixed !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }
}


/*----------------------------------------------- Shirt css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    position: absolute;
    left: 28%;
    width: 47%;
    bottom: 0;
}

.carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
.carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    position: absolute;
    left: 28%;
    width: 48%;
    bottom: 0;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    left: 30% !important;
    width: 50% !important;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    left: 25%;
    width: 55%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 33%;
        width: 34%;
    }
}

@media screen and (min-width: 1441px) and (min-height: 900px) and (max-height: 1500px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 23% !important;
        width: 55% !important;
        bottom: 13% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 19%;
        width: 62%;
    }

}

@media screen and (min-width: 1441px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 26% !important;
        width: 49% !important;
        bottom: 6% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 26%;
        width: 48%;
        bottom: 0;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 23% !important;
        width: 53% !important;
        bottom: 10% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 23%;
        width: 54%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 18% !important;
        width: 65% !important;
        bottom: 10% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 17%;
        width: 65%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 27% !important;
        width: 46% !important;
        bottom: 5% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 27%;
        width: 45%;
        bottom: 0;
    }
}


/* @media (min-width: 1440px) {

  .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
  .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
    position: absolute;
    left: 26%;
    width: 48%;
    top: 0;
  }
}


@media screen and (max-width: 1441px) and (min-width: 1025px) {

  .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
    position: absolute;
    left: 28%;
    width: 47%;
    bottom: 0;
  }

  .pop_up_design {
    width: 100%;
    margin-left: 0;
    float: right;
    display: inline;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 10000;
  }
} */



@media screen and (max-width: 1023px) and (min-width: 769px) {
    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-height: 700px) {
    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        overflow: auto;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 25vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 64% !important;
        bottom: 5% !important;
        left: 18% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 40% !important;
        left: 28% !important;
        bottom: 6% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 29%;
        width: 41%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 20%;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) {

    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 25vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 60% !important;
        bottom: 7% !important;
        left: 19% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 60%;
        left: 20%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 850px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 55% !important;
        bottom: 8% !important;
        left: 21% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 53%;
        left: 23%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 750px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 52% !important;
        bottom: 3% !important;
        left: 23% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 45%;
        left: 27%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 64% !important;
        bottom: 2% !important;
        left: 17% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 64%;
        left: 18%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}


@media screen and (max-width: 460px) and (min-width: 401px) and (max-height: 700px) {
    .col-lg-6.verify-casual {
        padding: 0;
    }

    ul.fabric_lft#fab-font-ht,
    ul.fabric_lft.fabric_border {
        top: 138px;
        height: 25vh;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .desk_menu {
        height: 10vh;
    }

    .fabr-header {
        border-bottom: none;
    }

    .fabric #f-one {
        height: 55vh;
        padding-top: 10px;
        overflow: auto;
        display: block !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .pop_up_design {
        width: 100%;
        right: 0;
        top: 0;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .ul.nw_zoom {
        width: 40px;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 45% !important;
        bottom: 4% !important;
        left: 26% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 43%;
        left: 27%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0 !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-height: 700px) and (min-height: 800px) {

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 64%;
        left: 17%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (max-height:700px) {

    .fabric #f-one {
        padding-top: 10px;
        height: 55vh;
        overflow: auto;
        display: block !important;
    }

    ul.me_chest {
        top: 0px !important;
    }

    .fabr-header {
        border-bottom: none;
    }

    ul.nw_zoom li img {
        width: 25px;
        margin: 0;
    }

    #fab_fxd_n {
        width: 100% !important;
    }

    .vh_80 {
        height: 90vh;
        overflow: hidden;
    }

    .verify-casual {
        height: 45vh !important;
    }

    .fabric_styl2 {
        display: flex;
        height: 70px;
        width: 100%;
        min-height: 120px;
    }

    .fabric-splits ul.fabric_lft {
        height: 20vh !important;
    }

    .fabric_style#fabric_style {
        height: 15vh;
    }

    .fabr-header {
        margin-top: -1px;
    }

    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 51% !important;
        bottom: 4% !important;
        left: 24% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 53%;
        left: 24%;
    }

    .px-0 .vh_80 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        left: 0% !important;
        width: 100% !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .col-lg-6.verify-casual {
        padding: 0;
    }
}










/*------------------------------------------ Shirt css size End===============================================------------------ */


/*------------------------------------------- Pant css size Start --------------------------------------------------------------*/

.carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
    position: absolute;
    left: 0%;
    width: 100%;
    top: 15%;
}

.carousel-inner .carousel-item .pi_pant_front .custom_shirt,
.carousel-inner .carousel-item .pi_pant_back .custom_shirt {
    position: absolute;
    width: 41%;
    left: 29%;
    top: 0;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
    left: 5%;
    top: 0;
    width: 85%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
    left: 30%;
    top: 0;
    width: 40%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        position: absolute;
        width: 28%;
        left: 36%;
        top: 0;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 45% !important;
        left: 26% !important;
    }

}


@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 53% !important;
        left: 23% !important;
    }

}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 42% !important;
        left: 30% !important;
    }

}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 1000px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 51% !important;
        left: 23% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 55% !important;
        left: 22% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 40% !important;
        left: 29% !important;
    }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 27% !important;
        width: 43% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 29%;
        width: 43%;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 95%;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 30% !important;
        width: 39% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 31%;
        width: 39%;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 95%;
    }

}


@media screen and (max-width: 461px) and (min-width: 426px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 25% !important;
        width: 50% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 27%;
        width: 50%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}


@media screen and (max-width: 461px) and (min-width: 426px) and (max-height: 800px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 28% !important;
        width: 43% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 29%;
        width: 43%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 30%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        width: 63% !important;
        top: 0%;
        left: 18% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 63%;
        top: 0%;
        left: 18%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}



@media screen and (max-width: 426px) and (min-width: 400px) and (max-height: 800px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 30%;
        left: 7%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        width: 47% !important;
        top: 0%;
        left: 26% !important;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        width: 47%;
        top: 0%;
        left: 27%
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 20% !important;
        width: 59% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 76%;
        top: 33%;
        left: 10%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 20%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 24% !important;
        width: 50% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 25%;
        width: 50%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 20%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_back .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 0 !important;
        width: 100% !important;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 700px) and (max-height: 799px) {
    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 22% !important;
        width: 56% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 27%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 21%;
        width: 61%;
        top: 0;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_pant_front .custom_shirt {
        left: 19% !important;
        width: 61% !important;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        width: 80%;
        top: 27%;
        left: 5%;
    }

    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        left: 21%;
        width: 61%;
        top: 0;
    }
}

@media screen and (max-width: 299px) {

    .carousel-inner .carousel-item .pi_pant_front .custom_shirt,
    .carousel-inner .carousel-item .pi_pant_back .custom_shirt {
        position: absolute;
        width: 63%;
        left: 18%;
        top: 0;
    }

    .carousel-inner .carousel-item .pi_pant_cuff .custom_shirt {
        position: absolute;
        left: 12%;
        width: 72%;
        top: 38%;
    }
}







@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*---------------------------------------------- Pant css size End -------------------------------------------------------------*/

/*----------------------------------------------- Suit css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
    position: absolute;
    left: 23%;
    width: 52%;
    bottom: 0%;
}

.suit_p .custom_shirt {
    top: 66% !important;
}

.carousel-inner .carousel-item .pi_suit_front .custom_shirt,
.carousel-inner .carousel-item .pi_suit_back .custom_shirt {
    position: absolute;
    left: 27%;
    width: 46%;
    bottom: 0%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
    left: 30%;
    width: 40%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
    left: calc(50% - 15%);
    top: 20%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
    left: 30%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 34%;
        width: 32%;
    }
}



@media screen and (min-width: 1700px) and (max-width: 1699px) and (min-height: 900px) and (min-height: 1500px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 26%;
        width: 45%;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 900px) and (max-height: 1100px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 16%;
        width: 68%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 19%;
        width: 62%;
    }
}

@media screen and (min-width: 1441px) and (max-width: 1699px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 26%;
        width: 47%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 26%;
        width: 45%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 20%;
        width: 60%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 22%;
        width: 54%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 20%;
        width: 57%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 20%;
        width: 57%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 27%;
        width: 45%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 29%;
        width: 42%;
    }

}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 28%;
        width: 42%;
    }
}






@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 29%;
        width: 41%;
    }



    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        position: absolute;
        left: 31%;
        width: 36%;
    }

}

@media screen and (max-width: 461px) and (min-width: 426px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 24%;
        width: 50%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 48%;
        /* bottom: 0%; */
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 21%;
        width: 58%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width: 400px) and (max-width:426px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 58%;
        /* bottom: 0%; */
        left: 19%;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 19%;
        width: 60%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 21%;
        width: 56%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 47%;
        /* bottom: 0%; */
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 47%;
        left: 26%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 670px) and (max-height: 760px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 55%;
        /* bottom: 0%; */
        left: 21%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 56%;
        left: 20%;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        width: 65%;
        /* bottom: 0%; */
        left: 15%;
    }

    .carousel-inner .carousel-item .pi_suit_back .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_front .custom_shirt {
        width: 58%;
        left: 20%;
    }
}


@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_suit_cuff .custom_shirt {
        position: absolute;
        left: 20%;
        width: 58%;
        bottom: 0%;
    }

    .carousel-inner .carousel-item .pi_suit_front .custom_shirt,
    .carousel-inner .carousel-item .pi_suit_back .custom_shirt {
        position: absolute;
        left: 19%;
        width: 59%;
        bottom: 0%;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*------------------------------------------ Suit css size End===============================================------------------ */
/*----------------------------------------------- Vest css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
    position: absolute;
    width: 45%;
    left: 25%;
    bottom: 0%;
}

.carousel-inner .carousel-item .pi_vest_front .custom_shirt,
.carousel-inner .carousel-item .pi_vest_back .custom_shirt {
    position: absolute;
    width: 45%;
    left: 27%;
    bottom: 0%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
    left: 30%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
.modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
    left: 30%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 32%;
        left: 34%;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1500px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 45%;
        left: 27%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 25%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 900px) and (max-height: 1100px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 53%;
        left: 23%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 55%;
        left: 23%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 42%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 44%;
        left: 29%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 50%;
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 53%;
        left: 24%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 54%;
        left: 22%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 56%;
        left: 22%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        position: absolute;
        width: 40%;
        left: 30%;
        bottom: 0%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 28%;
    }
}

@media screen and (max-width: 1023px) and (min-width: 769px) {

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 41%;
        left: 30%;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 45%;
        left: 25%;
    }
}


@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        position: absolute;
        width: 38%;
        left: 31%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        position: absolute;
        width: 36%;
        left: 32%;
    }

}

@media screen and (max-width: 461px) and (min-width: 426px) {


    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 51%;
        bottom: 2%;
        left: 24%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 50%;
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 50%;
        left: 26%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* 
  .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 50%;
        left: 25%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 800px) {

    .pp_0 .c-measurements {
        padding: 0;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    /* 
  .exchange {
    padding: 100px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 40px 10px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 58%;
        bottom: 3%;
        left: 21%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 21%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        left: 0%;
        width: 100%;
        top: 0%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }
}

@media screen and (min-width:430px) and (max-width:460px) and (min-height: 650px) and (max-height:760px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 41%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 39%;
        left: 29%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 39%;
        left: 31%;
    }
}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 60%;
        bottom: 2%;
        left: 19%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 59%;
        left: 21%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 47%;
        left: 27%;
        bottom: 2%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 49%;
        left: 26%;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 49%;
        left: 26%;
    }

    .zoom-canvas .pi-shirt-containers {
        height: 90vh !important;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        top: 0;
        left: 0;
        width: 100%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .modal-body.zoom-canvas .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        left: 0;
        width: 100%;
        top: 0;
    }

    /* .exchange {
    padding: 45px 10px !important;
    margin-top: 10px;
  } */

    .copo {
        padding: 10px 10px 25px !important;
        margin-top: 10px;
    }

    .exchange h1,
    .copo h1 {
        padding: 35px 11px 0px !important;
        font-size: 32px !important;
    }

    .exchange p,
    .copo p {
        font-size: 15px !important;
        padding: 15px 0 !important;
        letter-spacing: 0px;
    }

    .ex_content p {
        font-size: 15px !important;
    }

    .ex_content h1 {
        padding: 35px 20px !important;
        font-size: 30px !important;
    }

    .pp_0 .c-measurements {
        padding: 0;
    }

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 49%;
        left: 26%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {

    .carousel-inner .carousel-item .pi_vest_back .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_front .custom_shirt {
        width: 57%;
        left: 22%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 57%;
        left: 21%;
        bottom: 2%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 650px) and (max-height: 799px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 50%;
        left: 23%;
        bottom: 2%;
    }
}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_vest_cuff .custom_shirt {
        width: 52%;
        left: 22%;
        bottom: 10%;
    }

    .carousel-inner .carousel-item .pi_vest_front .custom_shirt,
    .carousel-inner .carousel-item .pi_vest_back .custom_shirt {
        width: 57%;
        left: 21%;
        bottom: 0%;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1025px) {}

/*------------------------------------------ Vest css size End===============================================------------------ */

/*----------------------------------------------- Blazer css size Start --------------------------------------------------*/

.carousel-inner .carousel-item .blazer_cuff .custom_shirt {
    position: absolute;
    left: 21%;
    width: 58%;
    top: 8%;
}

.modal-body.zoom-canvas .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
    left: 35%;
    top: 20%;
}

@media screen and (min-width: 4000px) and (min-height: 2100px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 31%;
        width: 39%;
    }
}

@media screen and (min-width: 1700px) and (min-height: 900px) and (max-height: 1500px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 18%;
        width: 60%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 900px) and (max-height: 1100px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 12%;
        width: 71%;
    }
}

@media screen and (min-width: 1441px) and (max-height: 1699px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 19%;
        width: 58%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 15%;
        width: 69%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 11%;
        width: 74%;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 21%;
        width: 56%;
    }
}

@media screen and (max-width: 768px) and (min-width: 461px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 51%;
        left: 24%;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 72%;
        top: 15%;
        left: 12%;
    }

    .modal-body.zoom-canvas .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 20%;
        width: 60%;
        top: 22%;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 680px) and (max-height: 780px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 63%;
        top: 11%;
        left: 16%;
    }

}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        position: absolute;
        left: 11%;
        width: 75%;
        top: 11%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 63%;
        top: 10%;
        left: 17%;
    }

}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 700px) {

    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 68%;
        top: 14%;
        left: 14%;
    }
}

@media screen and (max-width: 400px) and (min-width: 300px) and (min-height: 800px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        width: 73%;
        top: 17%;
        left: 11%;
    }
}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .blazer_cuff .custom_shirt {
        left: 12%;
        width: 69%;
        top: 18%;
    }
}




/*------------------------------------------ Blazer css size End===============================================------------------ */


/*------------------------------------------ Monogram css Start------------------------------------ */


.folded_svg {
    position: absolute;
    bottom: 23%;
    right: 33%;
    z-index: 1;
    width: 150px;
    height: 50px;
}

.pi_shirt_front .front_svg {
    position: absolute;
    z-index: 1;
    top: 35%;
    right: 37%;
    width: 150px;
    height: 50px;
}

.pi_shirt_front .front_svg text {
    font-size: 60px;
}

.folded_svg text {
    font-size: 140px;
}

.cuff_svg text {
    font-size: 80px;
}

.cuff_svg {
    z-index: 1;
    position: absolute;
    width: 110px;
    height: 50px;
}

.suit_front_svg {
    top: 9%;
    z-index: 1;
    margin-top: 31%;
    left: 25%;
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
    position: absolute;
    width: 150px;
    overflow: visible;
    height: 50px;
}

.suit_front_svg text {
    font-size: 185px;
}

.fabric .suit-monogram text {
    font-size: 170px;
}

@media screen and (min-width: 1441px) and (min-height: 900px) and (max-height: 1100px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 37%;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 240px;
    }

    .cuff_svg {
        bottom: 18%;
        right: 28%;
    }

    .cuff_svg text {
        font-size: 120px;
    }
}

@media screen and (min-width: 1441px) and (min-height: 700px) and (max-height: 900px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 38%;
    }

    .cuff_svg {
        bottom: 18%;
        right: 32%;
    }

    .cuff_svg text {
        font-size: 90px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1440px) and (min-height: 700px) and (max-height: 900px) {
    .pi_shirt_front .front_svg {
        top: 45%;
        right: 37%;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 170px;
    }

    .cuff_svg {
        bottom: 16%;
        right: 30%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 701px) {

    .folded_svg {
        bottom: 23%;
        right: 28%;
    }

    .pi_shirt_front .front_svg {
        top: 39%;
        right: 36%;
    }

    .suit_front_svg {
        top: 26%;
        left: 39%;
    }

    .cuff_svg {
        bottom: 18%;
        right: 27%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 700px) {

    .folded_svg {
        bottom: 23%;
        right: 33%;
    }

    .cuff_svg {
        bottom: 16%;
        right: 33%;
    }

}


@media screen and (max-width: 1440px) and (min-width: 1025px) {

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 120px;
    }

}


@media screen and (max-width: 1024px) and (min-width: 769px) {
    .folded_svg {
        bottom: 12%;
        right: 30%;
    }

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 110px;
    }

    .suit_front_svg {
        top: 24%;
        left: 39%;
    }

    .suit_front_svg text {
        font-size: 250px;
    }

    .cuff_svg {
        bottom: 18%;
        right: 30%;
    }

}

@media screen and (max-width: 768px) and (min-width: 462px) {
    .folded_svg {
        bottom: 22%;
        right: 36%;
    }

    .fabric .suit-monogram text,
    .folded_svg text {
        font-size: 90px;
    }

    .pi_shirt_front .front_svg {
        top: 41%;
        right: 39%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .cuff_svg {
        bottom: 16%;
        right: 34%;
    }
}


@media (max-width: 575.98px) {

    .fabric .suit-monogram {
        top: 10%;
        left: 0;
        width: 100%;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 170px;
    }
}

@media screen and (max-width: 461px) and (min-width: 427px) {
    .folded_svg {
        bottom: 20%;
        right: 33%;
    }

    .folded_svg text {
        font-size: 100px;
    }


    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 15%;
        right: 30%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .suit_front_svg text {
        font-size: 90px;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

/* @media screen and (max-width: 426px) and (min-width: 376px) {
  .folded_svg {
    left: 6%;
    margin-top: 65%;
  }

  .pi_shirt_front .front_svg {
    left: 0%;
    margin-top: 29%;
  }

  .pi_shirt_front .front_svg text {
    font-size: 40px;
  }

  .fabric .suit-monogram text,
  .folded_svg text {
    font-size: 90px;
  }

  .suit_front_svg {
    margin-top: 26% !important;
    left: 25% !important;
  }

  .fabric .suit-monogram text {
    font-size: 120px !important;
  }
} */

@media screen and (max-width: 461px) and (min-width: 427px) and (min-height: 700px) and (max-height: 800px) {
    .cuff_svg {
        bottom: 14%;
        right: 28%;
    }
}

@media screen and (max-width: 461px) and (min-width: 427px) and (min-height: 801px) {
    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }
}


@media screen and (max-width: 426px) and (min-width: 401px) {

    .folded_svg text {
        font-size: 100px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 33%;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 426px) and (min-width: 400px) and (min-height: 670px) and (max-height: 760px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        width: 56% !important;
        bottom: 2% !important;
        left: 24% !important;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 52%;
        left: 25%;
    }
}

@media screen and (max-width: 470px) and (min-width: 401px) and (min-height: 860px) {

    .cuff_svg {
        bottom: 15%;
        right: 23%;
    }

    .pi_shirt_front .front_svg {
        top: 43%;
        right: 32%;
    }

}

@media screen and (min-width: 400px) and (max-width: 480px) and (min-height:900px) {

    .folded_svg {
        bottom: 20%;
        right: 28%;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        width: 65%;
        left: 17%;
    }

}

@media screen and (max-width: 400px) and (min-width: 372px) {
    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 30%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 19%;
        left: 32%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) and (min-height: 680px) and (max-height: 780px) {
    .pi_shirt_front .front_svg {
        top: 41%;
        right: 29%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }
}

@media screen and (max-width: 400px) and (min-width: 372px) and (min-height: 820px) {

    .pi_shirt_front .front_svg {
        top: 46%;
        right: 30%;
    }

    .suit_front_svg {
        top: 23%;
        left: 32%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 22%;
    }

}

@media screen and (max-width: 371px) and (min-width: 351px) {
    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 24%;
    }

    .cuff_svg text {
        font-size: 40px;
    }

    .suit_front_svg {
        top: 23%;
        left: 30%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 371px) and (min-width: 351px) and (min-height: 800px) {


    .pi_shirt_front .front_svg {
        top: 52%;
        right: 30%;
    }

    .cuff_svg {
        bottom: 14%;
        right: 22%;
    }

}

@media screen and (max-width: 350px) and (min-width: 322px) {

    .folded_svg {
        bottom: 20%;
        right: 30%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 40px;
    }

    .cuff_svg {
        bottom: 13%;
        right: 21%;
    }

    .cuff_svg text {
        font-size: 40px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 321px) and (min-width: 300px) {
    .folded_svg {
        bottom: 19%;
        right: 29%;
    }

    .folded_svg text {
        font-size: 100px;
    }

    .pi_shirt_front .front_svg {
        top: 40%;
        right: 31%;
    }

    .pi_shirt_front .front_svg text {
        font-size: 34px;
    }

    .cuff_svg {
        bottom: 12%;
        right: 20%;
    }

    .cuff_svg text {
        font-size: 40px !important;
    }

    .suit_front_svg {
        top: 23%;
        left: 45%;
    }

    .fabric .suit-monogram text {
        font-size: 120px !important;
    }
}

@media screen and (max-width: 460px) and (min-width: 300px) and (min-height: 700px) and (max-height: 900px) {

    .folded_svg {
        bottom: 19%;
        right: 26%;
    }

}

@media screen and (max-width: 299px) {
    .carousel-inner .carousel-item .pi_shirt_cuff .custom_shirt {
        position: absolute;
        left: 22%;
        width: 55%;
        bottom: 13%;
    }

    .carousel-inner .carousel-item .pi_shirt_front .custom_shirt,
    .carousel-inner .carousel-item .pi_shirt_back .custom_shirt {
        position: absolute;
        left: 18%;
        width: 67%;
        bottom: 0;
    }

    .pi_shirt_front .front_svg {
        top: 39%;
        right: 24%;
    }

    .suit_front_svg {
        top: 25%;
        left: 18%;
    }

    .suit_front_svg text {
        font-size: 150px;
    }

    .cuff_svg {
        bottom: 14%;
        right: 16%;
    }

    .cuff_svg text {
        font-size: 50px;
    }
}



/*------------------------------------------ Monogram css End------------------------------------ */

@media screen and (max-width: 461px) and (min-width: 426px) {
    .second-header {
        height: 10vh;
    }
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: inline-block;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent}.owl-theme .owl-nav{margin-top:10px}.owl-theme .owl-nav [class*=owl-]{color:#FFF;font-size:14px;margin:5px;padding:4px 7px;background:#D6D6D6;display:inline-block;cursor:pointer;border-radius:3px}.owl-theme .owl-nav [class*=owl-]:hover{background:#869791;color:#FFF;text-decoration:none}.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}
.nw_banner h1 {
  padding: 35px 50px;
  text-align: center;
  color: #fff;
  font-size: 52px;
}

.nw_banner {
  /* background: url(../images/nw_banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 175px 0px;
  text-align: center;
}

.nw_banner button {
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 150px;
  color: #fff;
}

.nw_own {
  background: #f9fbff;
  padding: 50px 30px;
  font-family: "Roboto Condensed", sans-serif;
}

.nw_own h2 span {
  font-size: 18px;
  color: #949495;
}

.nw_own h2 {
  font-size: 30px;
  color: #264350;
  font-weight: 600;
}

.nw_own p {
  font-size: 22px;
}

.nw_own button {
  background: #264350;
  border: 0;
  padding: 5px 25px;
  border-radius: 50px;
  color: #fff;
  margin-top: 20px;
}

.nw_own img {
  max-width: 90px;
}

.nw_own p {
  font-size: 22px;
  padding: 15px 0;
  letter-spacing: 0px;
}

.nw_wh_start {
  padding: 50px 30px;
  max-width: 991px;
  margin: 0 auto;
}

.nw_wh_start ul li img {
  max-width: 150px;
}

.nw_wh_start ul li {
  display: inline-block;
  padding: 12px 12px;
  margin: 20px 50px;
}

.nw_wh_start ul {
  padding: 0;
  text-align: center;
}

.nw_wh_start h3 {
  text-align: center;
}

.nw_wh_start h4 {
  color: #595858;
  padding: 10px 0px;
  max-width: 768px;
  text-align: left;
  margin: 0 auto;
  font-weight: 700;
}

.nw_wh_start button {
  padding: 10px 20px;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #ccc;
}

.nw_wh_start {
  text-align: center;
}

.nw_sports {
  max-width: 870px;
  margin: 0 auto;
}

.nw_sports .card,
.nw_featured .card {
  border: 0;
  position: relative;
  align-items: center;
}

.nw_sports .card-body,
.nw_featured .card-body {
  padding: 0;
}

.nw_sports .card-footer,
.nw_featured .card-footer {
  background: #00000073;
  border: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 25px 0;
}

.nw_sports .card-footer button,
.nw_featured .card-footer button {
  border: 1px solid #ccc;
  background: transparent;
  color: #fff;
  padding: 5px 25px;
  border-radius: 10px;
}

.nw_own1 {
  max-width: 100%;
  margin: 0 auto;
}

.nw_wh_start p {
  max-width: 768px;
  margin: 0 auto;
  font-size: 22px;
  text-align: left;
}

.nw_featured .card-footer {
  left: 5px;
}

.nw_featured .card-footer {
  max-width: 490px;
}

.nw_featured h2 {
  padding: 50px 0;
}

.nw_featured {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
}

.nw_next_purc {
  /* background: url(../images/nw_bg.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

.nw_next_purc h3 {
  color: #fff;
  font-size: 30px;
  padding: 50px 5px;
  font-weight: 200;
}

.nw_next_purc p {
  color: #fff;
  padding: 50px 5px;
  font-size: 22px;
}

.nw_sports h3 {
  font-size: 39px;
}

.nw_next_purc_card {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0px;
  margin: 50px auto;
}

.nw_next_purc_card .input-group {
  max-width: 450px;
  margin: 0 auto;
}

.nw_next_purc_card #basic-addon2 {
  border: 0;
  border-radius: 0;
  background: #264350;
  color: #fff;
}

.nw_next_purc_card .form-control {
  border: 1px solid #ccc;
  border-radius: 0;
}

.nw_footer ul {
  padding: 0;
}

.nw_footer {
  text-align: center;
}

.nw_footer ul li {
  display: block;
  padding: 8px 12px;
}

.nw_footer button {
  color: #585757;
  background: transparent;
  border: 0;
  font-size: 22px;
  font-weight: 600;
}

.nw_footer ul li a {
  color: #585858;
  font-size: 16px;
}

.nw_social li {
  display: inline-block !important;
  border: 1px solid #ccc;
  align-items: center;
  text-align: center;
  padding: 15px 7px !important;
  vertical-align: middle;
  border-radius: 50px;
  width: 55px;
  margin: 15px 20px;
}

.nw_social li a i {
  color: #000;
}

.nw_slider p {
  position: absolute;
  top: 46%;
  width: 100%;
  text-align: center;
  font-size: 35px;
  color: #fff;
}

.nw_slider img {
  position: relative;
}

.nw_slider {
  padding: 60px 15px;
}

p.nw_top {
  margin: 0;
  text-align: center;
  padding: 5px 10px;
  background: #264350;
  color: #fff;
}

.nw_login {
  margin: 0;
  text-align: right;
  padding: 5px 15px;
  height: 34px;
}

.nw_megamenu {
  width: 100% !important;
}

.nw_form input {
  border-radius: 25px !important;
  width: 260px !important;
  height: 35px;
}

.nw_form a {
  color: #264350;
  font-size: 22px;
  padding: 5px 11px;
}

.nw_menu a {
  color: #2b2a2a;
  font-size: 20px;
}

.nw_menu span {
  color: #2b2a2a;
  font-size: 20px;
}

.nw_menu ul.navbar-nav {
  margin-left: 50px;
}

.sec_mnu {
  margin: 0 auto !important;
}

.nw_menu {
  padding-bottom: 30px;
}

.nw_menu .navbar-brand {
  font-size: 40px;
  font-weight: 400;
  line-height: 0;
}

.nw_login a {
  color: #656565;
  font-size: 14px;
}

.nw_hme {
  display: none;
}

.nw_mbl_form {
  display: none;
}

.nw_footer button:nth-child(1) {
  border-bottom: 1px solid transparent;
}

.nw_container {
  max-width: 991px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {

  #nw_mbl_icon,
  .nw_login_mbl {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  #nw_mbl_icon {
    display: inline;
    float: right;
  }

  .nw_menu .navbar-brand {
    font-size: 20px;
    font-weight: 400;
    position: relative;
    right: -30%;
    top: 8px;
  }

  .navbar-toggler-icon {
    /* background: url(../images/nw_menu.png); */
    background-repeat: no-repeat;
    background-size: 25px;
    z-index: 33;
  }

  .nw_toggler {
    position: absolute;
    left: 0;
    top: 0;
  }

  .nw_login {
    height: 0px;
  }
}

@media only screen and (max-width: 768px) {

  .nw_social li {
    margin: 15px 8px;
  }

  .navbar-nav .dropdown-menu {
    border: 0;
  }

  .owl-carousel .owl-stage {
    left: -50px;
  }

  .nw_social {
    display: block !important;
    margin-top: 30px;
  }

  .nw_wh_start ul li {
    display: contents;
    padding: 12px 12px;
    margin: 20px 27px;
  }

  .nw_wh_start ul li img {
    max-width: 90px;
    margin: 0 10px;
  }

  .nw_wh_start ul {
    margin: 50px 0;
  }

  .nw_mbl_form {
    display: flex !important;
  }

  .nw_form {
    display: none;
  }

  .nw_megamenu {
    width: 75% !important;
  }

  .nw_login {
    display: none;
  }

  .nw_login_mbl {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 100;
  }

  .nw_login_mbl a {
    font-size: 14px;
  }

  /* .exchange,
  .copo {
    height: 400px;
    background-size: cover !important;
  } */
}

@media only screen and (max-width: 575px) {

  .nw_banner h1 {
    padding: 25px 50px;
    text-align: center;
    color: #fff;
    font-size: 23px;
  }

  .nw_banner {
    /* background: url(../images/banner_mobile.png); */
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    height: 450px;
    background-size: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
  }

  .nw_own img {
    max-width: 90px;
  }

  .nw_own h2 {
    font-size: 22px;
  }

  .nw_own p {
    font-size: 14px;
  }

  .nw_own h3 {
    font-size: 18px;
  }

  .nw_featured .card-footer h3 {
    font-size: 22px;
  }

  .nw_featured .card-footer {
    left: 0px;
  }

  .nw_next_purc_card {
    padding: 0;
  }

  .nw_next_purc h3 span {
    font-weight: 600;
  }

  .nw_footer button {
    font-size: 22px;
    font-weight: 600;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
  }

  .nw_banner_cnt {
    background: #00000063;
  }

  .nw_navbar_collpse {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    min-height: 991px;
    height: 100%;
    z-index: 100;
    overflow: auto;
    bottom: 1px;
    width: 80%;
  }

  .nw_mbl_form span {
    position: relative;
    left: 18px;
    margin: 0 8px;
  }

  .nw_menu ul.navbar-nav {
    margin-left: 35px !important;
  }

  .nw_form input {
    width: 180px !important;
    margin-left: 12px;
  }

  .nw_hme {
    display: block;
  }

  .nw_menu {
    padding-bottom: 26px;
  }

  .nw_megamenu ul li a {
    font-size: 16px;
  }

  .nw_megamenu ul li {
    display: block;
    padding: 6px 0;
  }

  .nw_megamenu ul {
    padding: 0;
  }

  #nw_mbl_icon {
    display: block;
    position: absolute;
    right: 12px;
    top: 5px;
  }

}

.exchange {
  /* background: url(../images/exchange.png); */
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 175px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .copo {
  background: url(../images/copo.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 175px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .exchange h1,
.copo h1 {
  padding: 35px 50px;
  text-align: center;
  color: #fff;
  font-size: 52px;
} */

/* .exchange p,
.copo p {
  color: #fff;
  /* font-size: 22px; */
/* padding: 15px 0; */
/* letter-spacing: 0px; */


*/ .ex_content p {
  /* font-size: 18px; */
  /* padding: 15px 0; */
  letter-spacing: 0px;
}

.ex_content h1 {
  padding: 35px 50px;
  text-align: center;
  font-size: 52px;
}

.story video {
  width: 100%;
}

.carousel-control-next,
.carousel-control-prev {
  right: 40px;
}

.vh_20 {
  height: 20vh;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: Helvetica-CondensedBold;
    src: url(/static/media/NunitoSans_7pt-Bold.5eca7f02.ttf);
}

@font-face {
    font-family: Helvetica_Light;
    src: url(/static/media/NunitoSans_7pt-Regular.b31d295e.ttf);
}

@font-face {
    font-family: Helvetica_Medium;
    src: url(/static/media/NunitoSans_7pt-Medium.d7b72fa3.ttf);
}

@font-face {
    font-family: PPWriter-RegularItalic;
    src: url(/static/media/NunitoSans_7pt-SemiBold.35d5db5e.ttf);
}

body {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
}

:root {
    --primary: #F89B60;
    --bg_color: #F0F0F0;
    --black: #000000;
    --header-text-color: #5F5F5F;
    --border-line: #E7E7E7;
    --text-muted: #949AA1;
    --box-border: #d4d4d4;
    --white: #FFFFFF;
    --text-color: #737373;
    --cart-bg: #F6F6F6;
    --cart-divider: #EFEFEF;
    --canvas-text-color: #AAAAAA;
    --canvas-active-text: #343434;
    --btn-gradient: linear-gradient(92deg, #F89B60 41.36%, #F36D55 110.59%);
}

iframe {
    display: none !important;
}

.H-l {
    font-family: Helvetica_Light !important;
}

.H-m {
    font-family: Helvetica_Medium !important;
}

.H-cb {
    font-family: Helvetica-CondensedBold !important;
}

.PP_R {
    font-family: PPWriter-RegularItalic !important;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-34 {
    font-size: 34px;
}

.fs-44 {
    font-size: 44px;
}

.fs-52 {
    font-size: 52px;
}

.fs-64 {
    font-size: 64px;
}

.fs-80 {
    font-size: 80px;
}

.fs-84 {
    font-size: 84px;
}

.gap-6 {
    grid-gap: 6px;
    gap: 6px;
}

.gap-8 {
    grid-gap: 8px;
    gap: 8px;
}

.gap-10 {
    grid-gap: 10px;
    gap: 10px;
}

.gap-11 {
    grid-gap: 11px;
    gap: 11px;
}

.gap-12 {
    grid-gap: 12px;
    gap: 12px;
}

.gap-14 {
    grid-gap: 14px;
    gap: 14px;
}

.gap-15 {
    grid-gap: 15px;
    gap: 15px;
}

.gap-16 {
    grid-gap: 16px;
    gap: 16px;
}

.gap-18 {
    grid-gap: 18px;
    gap: 18px;
}

.gap-20 {
    grid-gap: 20px;
    gap: 20px;
}

.gap-22 {
    grid-gap: 22px;
    gap: 22px;
}

.gap-24 {
    grid-gap: 24px;
    gap: 24px;
}

.gap-28 {
    grid-gap: 28px;
    gap: 28px;
}

.gap-30 {
    grid-gap: 30px;
    gap: 30px;
}

.gap-32 {
    grid-gap: 32px;
    gap: 32px;
}

.gap-37 {
    grid-gap: 37px;
    gap: 37px;
}

.gap-42 {
    grid-gap: 42px;
    gap: 42px;
}

.gap-44 {
    grid-gap: 44px;
    gap: 44px;
}

.gap-49 {
    grid-gap: 49px;
    gap: 49px;
}

.gap-64 {
    grid-gap: 64px;
    gap: 64px;
}

.gap-70 {
    grid-gap: 70px;
    gap: 70px;
}

.gap-80 {
    grid-gap: 80px;
    gap: 80px;
}

.gap-y-3 {
    grid-row-gap: 10px;
    row-gap: 10px;
}

.pt-44 {
    padding-top: 44px;
}

.pb-16 {
    padding-bottom: 16px;
}

.py-6 {
    padding: 6px 0;
}

.py-12 {
    padding: 12px 16px;
}

.py-24 {
    padding: 24px 0;
}

.py-32 {
    padding: 32px 0;
}

.w-15 {
    width: 15%;
}

.w-17 {
    width: 17px;
    height: 17px;
}

.w-500 {
    width: 500px;
}

p {
    margin-bottom: 0px !important;
}

.text-color {
    color: #737373;
    color: var(--text-color);
}

.text-color-white {
    color: #FFFFFF;
    color: var(--white);
}

.text-color-black {
    color: #000000;
    color: var(--black);
}

.text_nowrap {
    text-wrap: nowrap;
}

/* ----- Header start ----- */

.header_height {
    height: 100dvh;
}

.nav_top {
    padding: 12px 0;
    background-color: #F0F0F0;
    background-color: var(--bg_color);
    color: #5F5F5F;
    color: var(--header-text-color);
}

.header_nav {
    padding: 22px 0;
    background-color: #FFFFFF;
    background-color: var(--white);
}

.header_nav ul li {
    color: #5F5F5F;
    color: var(--header-text-color);
}

.text_color {
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.scroll_header {
    top: 0;
    background-color: #FFFFFF !important;
    background-color: var(--white) !important;
    z-index: 9;
}

.position_absolute {
    position: absolute !important;
    top: 40px;
}

.position_fixed {
    position: fixed !important;
    top: 0 !important;
}

.z_index {
    z-index: 9;
}

.header {
    background-color: transparent;
}

.header-text {
    color: #5F5F5F !important;
    color: var(--header-text-color) !important;
}

.logo_position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}

.mobile-header {
    position: absolute;
}

.mobile-img-hide {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
}

.cart_position {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    position: relative !important;
}

.cart_header_icon {
    background-color: #000000;
    background-color: var(--black);
    text-align: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    content: '';
    left: -11px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    color: white;
}

.cart-count-scroll {
    top: 9px;
}

.cart-count-default {
    top: -5px;
}

.overlay-bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100% !important;
    background-color: black;
    opacity: 0.3;
}

/* ----- Header end ----- */

/* ----- header popup start ----- */

.accordion-button:not(.collapsed),
.accordion-button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    color: var(--black) !important;
}

.header_popup_left {
    padding: 24px 80px;
}

.accordion-button::after {
    background-image: url(/static/media/arrow_heading.357723d7.svg) !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/static/media/arrow_heading.357723d7.svg) !important;
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
}

.web_popup_logo {
    padding: 50px 80px 16px;
}

.header_exit_popup_img {
    width: 35px;
}

.header_popup_img {
    object-fit: cover;
    width: 437px;
    height: 345px;
}

.header_facebook_icon {
    width: 8px;
    height: 17px;
}

.header_whatsapp_icon {
    width: 18px;
    height: 18px;
}

.header_twitter_icon {
    width: 17px;
    height: 15px;
}

.header_instagram_icon {
    width: 15px;
    height: 15px;
}

.header_about_akal_icon {
    width: 15px;
    height: 15px;
}

.header_popup_logo {
    width: 110px !important;
}

.header_popup_right {
    padding: 60px 90px;
}

.header_popup_menu {
    border-bottom: 0.5px solid #BBBBBB;
    padding: 20px 35px;
    text-align: left !important;
    cursor: pointer;
}

.header_popup_menu_dropdown {
    padding: 12px 55px;
}

.copyrights_web_popup {
    align-self: flex-end;
}

.popup_copyrights {
    padding: 16px 0px;
}

.header_popup_dropdown_menu {
    font-size: 34px !important;
}

.mobile_popup {
    display: none;
}

.web_popup {
    background-color: #FFFFFF;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
}

.header_dropdown {
    background-image: url(/static/media/header_popup_dropdown_icon.ab3ee42d.svg) !important;
    width: 20px !important;
    height: 20px !important;
    background-size: cover !important;
}

.header_dropdown:not(.collapsed) {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
    transition: .4s;
}

.web_popup_hidden {
    display: none;
}

.copyrights {
    width: 11px;
    height: 11px;
}

/* .modal-backdrop {
    --bs-backdrop-zindex: 0 !important;
} */

/* .modal-backdrop.show {
    display: none !important;
} */

/* ----- header popup end ----- */


/* ----- profile start ----- */

.profile-letter {
    background-color: #F89B60;
    background-color: var(--primary);
    width: 95px;
    height: 95px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto !important;
}

.profile-tab-align {
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
    overflow: auto;
    grid-gap: 44px;
    gap: 44px;
}

.profile-tab-align .active_item {
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
}

.logout-muted {
    color: #949AA1;
    color: var(--text-muted)
}

.cart_form span {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4 !important;
    border-bottom: 1px solid var(--box-border) !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 0px 10px 0px 0px !important;
}

.cart_form input {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 7px 0 !important;
}

.cart_form .password {
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
}

.form-control:disabled {
    background-color: transparent !important;
}

input::-webkit-input-placeholder {
    font-family: Helvetica_Light;
    font-size: 14px;
}

input::placeholder,
input[type="text"],
input[type="password"],
input[type="email"] {
    font-family: Helvetica_Light;
    font-size: 14px;
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
.form-control:focus {
    box-shadow: none !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    /* border-bottom: 1px solid #dee2e6 !important; */
}

.canvas-input {
    border-radius: 0px !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none !important;
}

.form-check-input {
    box-shadow: none !important;
}

.input-group-text {
    border: 0 !important;
    background-color: transparent !important;
}

.icon-bg {
    background-color: #F89B60;
    background-color: var(--primary);
    width: 68px;
    height: 68px;
}

.icon_alignment {
    width: 30px;
    height: 30px;
}

/* .profile-alignment-design {
    margin-top: 70px;
} */

.buttonDesign {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 25px;
}

.profile-order-status {
    padding-right: 120px !important;
}

.ordered_products {
    padding-top: 44px;
}

.profile-order-status-align {
    padding-top: 30px;
    padding-bottom: 44px;
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.add_measurement_popup_circle {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 68px;
    width: 68px;
    border-radius: 100px;
    border: 0.5px solid #d4d4d4;
    border: 0.5px solid var(--box-border);
}

.profile_popup_close_btn {
    width: 57px;
}

.profile_popup_proceed_btn {
    width: 170px;
}

.profile_measurement_popup {
    padding: 0px 0px 0px 54px;
    width: 90%;
}

.profile-icon {
    width: 20px;
    height: 20px;
}

.profile-data-border:not(:nth-of-type(3n)) {
    border-right: 1px solid #f0f0f0 !important;
}

/* ----- profile end ----- */

/* ----- home page start ----- */

.main_banner {
    text-align: left;
    height: 100vh;
    /* position: relative; */
}

.carousel_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 40% !important;
    left: 0;
}

.gap-align {
    grid-gap: 24px;
    gap: 24px;
}

.banner_content {
    /* padding-left: 100px; */
    /* width: 100%; */
    margin: auto;
    color: #FFFFFF;
    color: var(--white);
}

.carousel_img {
    width: 60px !important;
}


.home_img_section {
    background-color: #F0F0F0;
    height: 743px;
}

.blazer_section,
.suit_section {
    position: relative;
}

.home_section_names {
    position: absolute;
    left: 3.6rem;
    bottom: 20px;
    color: #FFFFFF;
    color: var(--white);
}

.get_more_section {
    padding: 90px 0px;
}

.get_more_left_section {
    background-color: #EAEAE4;
    height: 641px;
}

.get_more_left_section_img {
    object-fit: cover;
    height: 641px;
}

.get_more_center_section_img_div {
    width: 344px;
    background-color: #EAEAE4;
    height: 337px;
}

.get_more_center_section_img {
    object-fit: cover;
    height: 337px;
}

.get_more_right_section_img_div {
    background-color: #EAEAE4;
    height: 303px;
}

.get_more_right_section_img {
    object-fit: cover;
    height: 303px;
}

.get_more_center_section {
    flex-direction: column;
    width: 344px;
    height: 528px;
}

.get_more_right_section {
    flex-direction: column;
    width: 205px;
    /* height: 641px; */
}

.suit_blazer_img_section {
    object-fit: cover;
    height: 743px;
}

.offer_section {
    padding: 70px 0px;
}

.offer_section_left {
    /* width: 97%; */
    border-right: 1px solid #000000;
    border-right: 1px solid var(--black);
    padding-right: 115px;
}

.offer_section_right {
    padding-left: 115px;
}

.btn-get-more {
    width: 205px;
    height: 48px;
    font-size: 18px !important;
    font-family: Helvetica-CondensedBold !important;
    background-color: #F89B60 !important;
    background-color: var(--primary) !important;
    border-radius: 0px !important;
}

.get_more_section2 {
    padding: 24px 0px 90px;
}

/* .get_more_left2 {
    width: 626px;
} */

.get_more_left_img2 {
    object-fit: cover;
    height: 553px;
}

/* .get_more_right2 {
    padding-top: 82px;
} */

.get_more_right_section2_img {
    height: 296px;
    object-fit: cover;
    background-color: #f0f0f0;
}

.style_section {
    padding: 24px 0px 60px;
}

.style_section_border {
    padding: 16px 24px 16px 24px;
    border-top: 1px solid #737373;
    border-top: 1px solid var(--text-color);
}

.style_section_border:last-child {
    border-bottom: 1px solid #737373;
    border-bottom: 1px solid var(--text-color);
}

s .style_section_text_color {
    color: #737373;
    color: var(--text-color);
}

.style_see_collection_section {
    align-items: center;
}

.style_banner {
    background: url(/static/media/style_img_1.2574c325.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 635px;
}

g .mobile_style_banner {
    background: url(/static/media/style_img_1.2574c325.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 635px;
}

.style_banner_content {
    color: #FFFFFF;
    color: var(--white);
}

.btn-getmore {
    width: 205px;
}

.banner-slider-line-img {
    width: 60px;
    height: 3px;
    background-color: #FFFFFF;
    background-color: var(--white);
}

.carousel-head {
    font-size: 44px;
}

.carousel-subtext {
    font-size: 15px;
    max-width: 640px;
}

.button-design {
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 15rem;
    grid-gap: 12px;
    gap: 12px;
}

.button-primary {
    background: linear-gradient(92deg, #F89B60 41.36%, #F36D55 110.59%) !important;
    background: var(--btn-gradient) !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.banner-btn {
    border: 1px solid #FFFFFF !important;
    border: 1px solid var(--white) !important;
    background-color: transparent !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.get_more_left2_heading {
    max-width: 550px;
}

/* ----- home page end ----- */


/* ----- footer page start ----- */

.footer_content {
    background-color: #000000;
    background-color: var(--black);
    color: #FFFFFF;
    color: var(--white);
}

.footer_style_advice_section {
    padding-top: 44px !important;
}

.footer_style_advice_section .w-90 {
    padding-left: 0.8rem !important;
}

.footer_style_advice_section_content {
    padding: 52px 0px 62px 0px;
}

.newsletter {
    background-color: #2C2C2C !important;
    height: 64px;
    padding-left: 24px !important;
}

.footer_newsletter {
    border-bottom: 1px solid #E8E8E8 !important;
}

.footer_newsletter_content {
    padding: 44px 0px;
}

input.newsletter[type="text"]::-webkit-input-placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
    color: #B3B3B3;
}

input.newsletter[type="text"]::placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
    color: #B3B3B3;
}

.newsletter,
.newsletter_form_text {
    border-radius: 0 !important;
}

.newsletter_form_text {
    font-family: Helvetica-CondensedBold;
    font-size: 16px !important;
    background-color: #2C2C2C !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    padding-right: 24px !important;
}


.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}

.footer_border {
    border-bottom: 0.3px solid #000000;
    border-bottom: 0.3px solid var(--black);
}

.footer_content input[type="text"] {
    color: white;
    font-family: Helvetica_Light;
}

.footer_logo {
    width: 90px;
}

.footer_facebook_icon {
    width: 8px;
    height: 17px;
}

.footer_whatsapp_icon {
    width: 18px;
    height: 18px;
}

.footer_twitter_icon {
    width: 17px;
    height: 15px;
}

.footer_instagram_icon {
    width: 15px;
    height: 15px;
}

.footer_about_akal_icon {
    width: 15px;
    height: 15px;
}

.footer_popup {
    height: 744px;
    border-radius: 0px !important;
    padding: 70px 32px;
    color: #000000 !important;
    color: var(--black) !important;
    border: none !important;
}

.footer_popup_list {
    padding: 20px 18px;
    border-top: 1px solid #E7E7E7;
    border-top: 1px solid var(--border-line);
}

.footer_popup_list:last-child {
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.footer_exit_popup_img {
    width: 28px;
    left: 20px;
    position: absolute;
    top: 20px;
}

.footer_accordion .pdp_accordion_btn::after {
    background-image: url(/static/media/plus.2b5a6d0a.svg) !important;
    visibility: hidden;
    width: 24px !important;
    height: 24px !important;
    background-size: cover !important;
}

.footer_accordion .pdp_accordion_btn:not(.collapsed):after {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
    transition: .4s;
}


.footer_akal_gap {
    padding-bottom: 44px;
}

.footer_reverse {
    display: flex;
    flex-direction: row;
    grid-gap: 80px;
    gap: 80px;
}

.footer_subhead {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer_accordion,
.pdp_accordion_btn {
    background-color: #000000 !important;
    background-color: var(--black) !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
    color: #000000 !important;
    color: var(--black) !important;
}

.accordion-active {

    .accordion-button:not(.collapsed),
    .accordion-button:focus {
        color: #FFFFFF !important;
        color: var(--white) !important;
    }

}


/* ----- footer page end ----- */

/* ----- plp page start ----- */

.para_width {
    max-width: 650px;
    text-align: center;
}

.category_list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.category_list p {
    margin: 0;
    color: var(--secondary_text);
}

.active_category {
    border-bottom: 1.3px solid #000000;
    border-bottom: 1.3px solid var(--black);
}

.category_description {
    color: var(--secondary_text);
}

.category_part {
    background-color: #F0F0F0;
    background-color: var(--bg_color);
    display: flex;
    height: 552px !important;
    cursor: pointer;
}

.category_content,
.category_content_2_items .category_1_2,
.category_content_2_items .category_2_2 {
    width: calc(50% - 3px);
}

.category_content_3_items,
.category_content_2_items {
    width: 100%;
    grid-row-gap: 4px;
    row-gap: 4px;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
}

.category_content_3_items .category_1_1_3,
.category_content_3_items .category_2_1_3,
.category_content_3_items .category_2_2_3 {
    width: calc(33.25% - 3px);
}

.dropdown_border {
    border: 0.5px solid #d4d4d4 !important;
    border: 0.5px solid var(--box-border) !important;
    border-radius: 0 !important;
    width: 195px;
}

.dropdown_border li span {
    padding: 8px 33px;
}

.dropdown_border li span:hover,
.dropdown_border li span:active {
    background-color: #fafafa !important;
    color: #000000;
    color: var(--black);
}

.category_content p {
    font-size: 14px;
    font-family: Helvetica_Light;
}

/* .category_content.category3 {
    height: 370px !important;
} */

.overall_width {
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
}

.color_select {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    margin: 0 9px 0 0;
    cursor: pointer;
}

.material_name {
    max-width: 270px;
    margin: 0 !important;
}

.category_align {
    margin-top: 13px;
}

.currency_amount {
    display: flex;
    align-items: center;
    color: var(--secondary_text);
    margin: 0;
}

.dropdown_toggle_remove {
    padding: 0 20px;
}

.dropdown_toggle_remove::after {
    display: none !important;
}

.sort_btn {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 0px !important;
    color: #737373 !important;
    color: var(--text-color) !important;
    padding: 5px 12px !important;
}

.plp_sort_btn {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 0px !important;
    color: #737373 !important;
    color: var(--text-color) !important;
    padding: 5px 12px !important;
}

.category_part img,
.PDP_image_content img {
    max-width: 100%;
    width: 100%;
    height: 80%;
    object-fit: contain;
}

.shirt_list_page {
    padding: 40px 0 0 0;
}

.padding_gaps {
    margin-top: 24px;
    padding: 0 24px;
}

/* .padding_gaps_img_section {
    padding-top: 28px;
} */

.padding_gaps_img {
    padding-bottom: 50px;
}


.plp_img_col-3 {
    height: 550px;
    background-color: #F0F0F0;
}

.plp_header {
    padding: 24px 90px;
}

.plp_para {
    align-items: flex-end;
}

.para_width {
    /* max-width: 512px; */
    text-align: right;
}

.para_width3 {
    text-align: right;
    text-wrap: nowrap;
}

.para_width2 {
    width: 376px;
    text-align: left;
}

.plp_para_reverse {
    align-items: flex-end;
}

.category_list {
    grid-gap: 34px;
    gap: 34px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

/* .sort_section {
    padding: 0px 90px;
} */

.sort_section_list {
    grid-gap: 30px;
    gap: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    color: #737373;
    color: var(--text-color);
    padding-top: 5px;
}

.plp_dropdown_icon {
    width: 8px;
}

.dropdown_toggle_remove {
    padding: 0 20px;
}

.dropdown_toggle_remove::after {
    display: none !important;
}

.sort_by_icon {
    padding-left: 14px;
}

.mobile_sort_section {
    display: none !important;
}

.img_section_content {
    padding: 0 10px;
}

.color_circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #915252;
}

.max_width_content {
    max-width: 310px;
}

.w-51 {
    width: 50%;
}

.w-33 {
    width: 33%;
}

.currency_icon {
    width: 8px;
    height: 12px;
}

.plp_looks_section {
    padding-top: 70px;
    padding-bottom: 60px;
}

.plp_fit_icon {
    width: 93px;
    height: 93px;
}

.plp_looks_section_max_width {
    max-width: 973px;
}

.plp_last_section_right {
    width: 55%;
}

.plp_last_section_left {
    flex-direction: column;
    width: 45%;
    padding: 40px 90px 90px 90px;
    background-color: #F0F0F0;
}

.plp_last_section_img {
    object-fit: cover;
    width: 100%;
    height: 886px;
}

/* 
.padding_gaps_top_bottom {
    margin-top: 64px;
} */

/* ----- plp page end ----- */

/* ----- pdp page start ----- */

.PDP_container {
    padding: 0px 90px !important;
}

.PDP_content_part {
    border-top: 0.5px solid #E7E7E7;
    border-top: 0.5px solid var(--border-line);
}

.PDP_header_detail {
    padding-bottom: 15px;
    border-bottom: 0.2px solid #E7E7E7;
    border-bottom: 0.2px solid var(--border-line);
}

.PDP_btn {
    border: none;
    background: none;
}

.row_margin_top {
    /* margin-top: 65px !important; */
    padding-bottom: 70px;
}

.detail_first_img {
    width: 100%;
    height: 50% !important;
}

.PDP_mobile_fabric_p {
    padding-top: 20px;
}

.PDP_fabric_img_col {
    max-width: 100%;
    width: 100%;
    /* height: 641px; */
    object-fit: fill;
}

.detail_page_img_col {
    /* width: 100% !important; */
    height: 50% !important;
    object-fit: fill !important;
    padding-left: 6px !important;
}

/* .detail_page_img_col2 {
    padding-top: px;
} */

.PDP_fabric_col_img {
    height: 641px;
    width: 100%;
    object-fit: contain;
}

.PDP_padding_right {
    padding-right: 44px !important;
}

.mobile_pt-44 {
    padding-top: 0px;
}

/* ----- pdp page end ----- */

/* ----- pdp popup start ----- */
.buttons_align {
    text-align: center;
    margin-top: 35px;
    grid-gap: 0.6rem;
    gap: 0.6rem;
}

.common_btn {
    /* border-radius: none !important; */
    padding: 10px 17px !important;
    font-family: Helvetica_Light !important;
    font-size: 13px !important;
}

.measurement_btn {
    border-radius: 0px !important;
    /* border: 0.3px solid var(--box-border) !important; */
    width: 26%;
    font-family: Helvetica_Light !important;
    padding: 10px 12px !important;
}

.measurement_btn.active_btn {
    border: 0.3px solid #4E4E4E !important;
}

.measurement_btn span {
    font-family: Helvetica_Light !important;
    display: block;
    font-size: 13px;
}

.measurement_btn_close {
    color: #000000;
    color: var(--black);
    cursor: pointer;
}

.size_chart_btn {
    width: 32%;
}

.table_class {
    overflow-x: auto;
    position: relative;
}

.table_class th,
.table_class td {
    border: 0.3px solid var(--category-bg-color);
    text-align: center;
    padding: 15px 25px;
    font-family: Helvetica_Light;

}

.table_class th {
    padding: 5px 20px;
}

.table_class th:nth-child(even),
.table_class td:nth-child(even) {
    background-color: rgba(189, 225, 246, 0.20);
}

.table_class th:first-child,
.table_class td:first-child {
    width: 175px !important;
    display: inline-block;
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0 !important;
    z-index: 2;
    background-color: var(--white_color);
    text-align: start !important;
    border-left: 0;
    padding-left: 0 !important;
}

.table_class td:first-child {
    cursor: pointer;
}

/* .table_class th,
.table_class td {
    position: relative !important;
    left: 200px !important;
} */

.table_class tr {
    border-top: 0.3px solid #d4d4d4;
    border-top: 0.3px solid var(--box-border);
    max-width: 100%;
}

.table_class th,
.table_class td {
    width: 100px;
}

.toggle_icon {
    font-size: 26px;
}

.measure_info {
    color: #d4d4d4;
    color: var(--box-border);
}

.measure_contact_align {
    grid-gap: 1.3rem;
    gap: 1.3rem;
}

.measure_contact {
    border: 1.5px solid #d4d4d4 !important;
    border: 1.5px solid var(--box-border) !important;
    border-radius: 10px;
    cursor: pointer;
}

.PDP_exit_popup_img {
    width: 28px;
    /* left: 20px;
    position: relative;
    top: 20px; */
}

.pdp_popup_close_btn {
    width: 57px;
}

.pdp_popup_proceed_btn {
    width: 111px;
}

.pdp_icon {
    width: 26px;
}

.margin_align {
    margin-top: 30px;
    margin-bottom: 5px;
}

.px_measure_values {
    padding: 1.5rem 3.5rem;
}

.measure_3_items_align .col-3:nth-of-type(1) {
    text-align: center;
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
}

.measure_3_items_align .col-3:nth-of-type(2) {
    padding: 0 0.25rem !important;
}

.measure_3_items_align .col-3:nth-of-type(3) {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
}

.measure_3_items_align .col-3:nth-of-type(4) {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
}

.measure_data {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55px;
    height: 55px;
    border-radius: 100px;
    border: 1px solid #f1f1f1 !important;
    padding: 8px 10px;
    cursor: pointer;
    margin: 0;
    text-align: center;
    font-family: Helvetica-CondensedBold;
    font-size: 14px;
    color: #737373;
    color: var(--text-color);
}

.measure_data:hover,
.measure_data.active_measure {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55px;
    height: 55px;
    border-radius: 100px;
    border: 1px solid #F9F9F9 !important;
    background-color: #eaeaea;
    color: #737373;
    color: var(--text-color);
    font-family: Helvetica-CondensedBold;
    font-size: 14px;
}

.measure_data p {
    text-align: center;
    margin: 0;
}


/* ----- pdp popup end ----- */

/* ----- login start ----- */

.login_img_section {
    width: 60%;
}

.login_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.forgot_password_design {
    padding: 30px !important;
}

.login_content_section {
    position: relative;
    width: 40%;
    padding: 30px 90px;
}

.login_form_control {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 0.5px solid #d4d4d4 !important;
    border-bottom: 0.5px solid var(--box-border) !important;
    border-radius: 0px !important;
}

.login_input_text_color {
    color: #949AA1;
    color: var(--text-muted);
}

.login_btn {
    height: 45px;
    background-color: #F89B60 !important;
    background-color: var(--primary) !important;
    font-family: Helvetica-CondensedBold !important;
}

.remember-me {
    border-radius: 0px !important;
    width: 19px !important;
    height: 19px !important;
}

.login_logo {
    width: 96px;
    height: 29px;
    position: absolute;
    top: 30px;
    right: 45px;
    object-fit: contain;
}

/* ----- login end ----- */

/* ----- cart start ----- */

.btn-width {
    width: 100% !important;
}

.cart-divider-line {
    border-top: 1px solid #EFEFEF;
    border-top: 1px solid var(--cart-divider);

}

.cart-quantity-border {
    border: 0.5px solid #d4d4d4;
    border: 0.5px solid var(--box-border);
    padding: 6px 9px;
    grid-gap: 18px;
    gap: 18px;
}

.cart-product-alignment:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
    border-bottom: 1px solid var(--cart-divider);
}

.cart-product-alignment {
    padding: 55px 0 !important;
}

.cart-padding {
    padding: 24px 0 60px 0;
}

.cart-left {
    padding: 0 40px 0 0 !important;
}

.cart-right {
    padding: 0 0 0 40px !important;
    border-left: 1px solid #EFEFEF;
    border-left: 1px solid var(--cart-divider);
}

.heading-dotted {
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
}

.text_grey {
    color: #737373;
    color: var(--text-color);
}

.cart-img img {
    width: 130px;
    height: 100px;
    object-fit: contain !important;
    /* background-color: var(--cart-bg); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-icon {
    width: 16px;
    height: 16px;
}

/* ----- cart end ----- */

/* ----- address popup start ----- */

.address_exit_icon {
    width: 22px;
    height: 22px;
}

.address_popup {
    /* border-top: 0.5px solid var(--box-border); */
    border-bottom: 0.5px solid #d4d4d4;
    border-bottom: 0.5px solid var(--box-border);
    padding: 28px 0px;
}

.address_popup:first-child {
    border-top: 0.5px solid #d4d4d4;
    border-top: 0.5px solid var(--box-border);
}

.address_popup_btn {
    border-top: 0.3px solid #d4d4d4;
    border-top: 0.3px solid var(--box-border);
}

.address_popup_close_btn {
    width: 57px;
}

.address_popup_proceed_btn {
    width: 111px;
}

.cart_form select {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 7px 0 !important;
}

/* ----- address popup end ----- */

/* ----- canvas start ----- */

.filter-mobile-icon {
    display: none !important;
}

.canvas_align {
    background-color: #EFEFEF;
    background-color: var(--cart-divider);
}

.carousel_canvas {
    height: calc(100svh);
    overflow: hidden;
    margin: 0 !important;
}

.canvas_img_change {
    height: 80% !important;
    object-fit: contain !important;
}

.canavas_slide_change {
    height: 100%;
    background-color: var(--category-bg-color);
}

.canvas_align {
    height: 100%;
    position: relative;
}

.border_canvas {
    border-top: 0.2px solid #d4d4d4;
    border-top: 0.2px solid var(--box-border);
    border-right: 0.2px solid #d4d4d4;
    border-right: 0.2px solid var(--box-border);
}

.canvas_contents {
    width: 80%;
}

.canvas_headings {
    width: 20%;
    height: calc(100vh - 72.67px);
    overflow-y: auto;
    overflow-x: hidden;
}

.canvas_headings::-webkit-scrollbar {
    display: none !important;
}

.canvas_top_btns {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d4d4d4 !important;
    border-bottom: 1px solid var(--box-border) !important;
    padding: 8px 17px !important;
    border-radius: 0px !important;
    color: var(--secondary_text) !important;
    font-size: 13px !important;
}

.canvas_search {
    font-size: 14px !important;
}

.canvas_search::-webkit-input-placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
}

.canvas_search::placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
}

.canvas_fabric_name,
.canvas_fabric_dollar {
    font-size: 12px;
    color: #737373;
    color: var(--text-color);
    margin: 0 !important;
    font-family: Helvetica_Light;
}

.canvas_fabric_name {
    font-size: 12px;
    color: #737373;
    color: var(--text-color);
    margin: 0 !important;
    font-family: Helvetica_Light;
    text-transform: lowercase !important;
}

.canvas_fabric_name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 110px;
}

.fabric_section {
    width: 100%;
    height: 15vh;
    border-radius: 100px !important;
}

.fabric_material {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
}

.canvas_headings .heading_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.canvas_headings .head_detail {
    padding: 20px 18px;
    text-align: center;
    cursor: pointer;
}

.canvas_headings .heading_align.active {
    background-color: var(--category-bg-color) !important;
    border-radius: 0px;
}

.canvas_headings p {
    margin: 0;
    padding-top: 7px;
}

.canvas_bottom_btns {
    position: absolute;
    bottom: 0px;
    background-color: #FFFFFF;
    background-color: var(--white);
    width: 100%;
    padding: 0.75rem 1.2rem;
    border-top: 0.2px solid #d4d4d4;
    border-top: 0.2px solid var(--box-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.canvas_bottom_text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.canvas_headings_align {
    padding: 0 1.2rem 5rem;
    grid-gap: 11px;
    gap: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canvas_content_scroll {
    height: calc(100vh - 59.33px);
    overflow: auto;
    padding-bottom: 20vh;
}

.fabric_icon {
    width: 26px;
}

.canvas_top_section {
    padding: 27px 20px 13px;
}

.canvas_prev_next {
    padding: 0 !important;
    width: 34px;
    height: 34px;
    background-color: #F89B60 !important;
    background-color: var(--primary) !important;
    border-radius: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.canvas_style_prev,
.canvas_style_prev:active {
    width: 58px !important;
    border: 1px solid #d9d9d9 !important;
    background-color: transparent !important;
}

.canvas_style_headings {
    overflow-x: auto !important;
    display: flex;
    flex-wrap: nowrap;
    /* border-bottom: 0.3px solid var(--box-border); */
    margin: 0 15px;
}

.menu-wrapper {
    border-bottom: 0.3px solid #d4d4d4;
    border-bottom: 0.3px solid var(--box-border);
}

.canvas_style_headings a {
    padding: 18.5px 15px;
    text-decoration: none;
    color: #949AA1;
    color: var(--text-muted);
    display: inline-block;
    font-size: 14px !important;
    font-family: 'Helvetica_Light';
}

.canvas_style_headings a.active_text {
    color: #000000;
    color: var(--black);
    border-bottom: 1.3px solid #737373;
    border-bottom: 1.3px solid var(--text-color);
}

.canvas_style_contents {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
}

/* .canvas_style_contents .canvas_style_section:not(:last-child) {
    border-bottom: 0.3px solid var(--box-border);
} */

.canvas_measure_align {
    border: 1px solid var(--category-bg-color);
    border-radius: 10px;
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
    height: 98.33px;
    display: flex;
    align-items: center;
}

.canvas_measure_align p {
    font-size: 12px;
    margin: 0;
}

.style_img {
    width: 50px;
    height: 50px;
    margin: auto;
}

.style_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.style_type {
    border: 1px solid var(--category-bg-color);
    border-radius: 10px;
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
    height: 100%;
}

.style_type.active_type {
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 0px;
}

.style_type.active_type p {
    color: #000000;
    color: var(--black);
}

.style_type p {
    color: #949AA1;
    color: var(--text-muted);
    font-size: 13px;
    margin: 0;
}

.style_type.measure p:first-child {
    font-size: 13px;
    font-family: Open-sans-m;
    color: #000000;
    color: var(--black);
}

.measure_contents .canvas_measure_align p:first-child {
    color: #000000;
    color: var(--black);
    font-size: 14px !important;
}

.measure_contents {
    height: calc(100vh - 72.67px);
    overflow: auto;
    padding-bottom: 13vh !important;
}

.canvas_details_icons {
    position: absolute;
    bottom: 8vh !important;
    right: 30px !important;
    z-index: 1;
}

.canvas_icon_color {
    color: var(--secondary_text);
}

.carousel_canvas .mobile_canvas_part {
    position: relative;
}

.canvas_details_icons .icons_name {
    background-color: #FFFFFF;
    background-color: var(--white);
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 9px;
}

.canvas_close_btn {
    opacity: 1 !important;
    border-radius: 50% !important;
}

.canvas_close_btn:focus,
input[type="search"]:focus {
    box-shadow: none !important;
}

.canvas_fabric_img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.canvas_bottom_btn_align {
    grid-gap: 12px;
    gap: 12px;
}

.measure_inputs {
    background-color: var(--summary-bg-color);
    border: 1px solid var(--footer-border);
    border-radius: 10px;
}

.px_summary {
    padding: 2.2rem 4rem;
    grid-gap: 13px;
    gap: 13px;
}

.measure_inputs p {
    margin: 0;
}

.measure_inputs.summary_style .row:not(:last-child) {
    border-bottom: 0.3px solid #EEEEEE;
}

.measure_inputs.summary_style .row {
    padding: 14px 0 !important;
}

.active_color {
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 0px;
}

.active_button {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 50%;
}

.formal_casual_text {
    position: absolute;
    bottom: -35px;
}

.canvas_formal_icon {
    height: 30px !important;
    width: 27px !important;
    /* padding: 0.3rem !important; */
}

.prev_icon {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 300px;
    height: 300px;
}

.modal {
    height: 100% !important;
}

.canvas-logo {
    width: 100px !important;
}

.canvas_padding {
    position: absolute;
    top: 0;
    padding: 30px 40px !important;
}

.canvas-active-text {
    color: #343434;
    color: var(--canvas-active-text);
}

.canvas-text-color {
    color: #AAAAAA;
    color: var(--canvas-text-color);
}

.canvas-menu-divider:not(:last-child) {
    border-bottom: 1px solid #ececec;
    width: 100%;
}

.icon_alignment.canvas {
    width: 24px;
    height: 24px;
}

.icon-bg.canvas {
    width: 52px;
    height: 52px;
}

.border-divider-summary {
    border-bottom: 1px solid #EEEEEE;
}

.canvas-finish {
    display: none;
}

.arrowCanvas {
    width: 15px;
    height: 15px;
}

.arrowCanvas.prev {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.canvas_popup_proceed_btn {
    width: 110px;
}

.close-search {
    display: none;
}

.custom_measure {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 10px;
    padding: 8px 0;
    border-radius: 0px;
}

.canvas_summary_addtocart {
    width: 180px;
}

.measure_content_scroll {
    height: calc(100vh - 122px);
    overflow: auto;
    padding-bottom: 30px;
}

.measurement_popup {
    border-top: 0.5px solid #d4d4d4;
    border-top: 0.5px solid var(--box-border);
}

.measurement_popup:last-child {
    padding-bottom: 30px;
    border-bottom: 0.5px solid #d4d4d4;
    border-bottom: 0.5px solid var(--box-border);
}

.disable-measurement {
    color: #d4d4d4;
    color: var(--box-border);
    /* cursor: not-allowed; */
}

.disable-measurement:hover {
    background-color: transparent;
    cursor: not-allowed;
    color: #d4d4d4;
    color: var(--box-border);
}

/* ----- canvas end ----- */

/* ----- faq start ----- */

.faq-accordion {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #E7E7E7 !important;
    border-bottom: 1px solid var(--border-line) !important;
}

.faq-div {
    padding-top: 50px;
}

/* ----- faq end ----- */


/* ----- blog start ----- */
.blog-img {
    background-color: #F0F0F0;
}

.blog-para {
    max-width: 200px
}

/* ----- blog end ----- */

/* our story start */

.our_story_banner {
    height: 600px;
}

.icon-bg.our_story {
    width: 50px;
    height: 50px;
}

.icon_alignment.our_story_icon {
    width: 34px;
    height: 34px;
}

.our_story_icon {
    width: 170px;
    height: 170px;
    object-fit: contain;
}

.our_story_align {
    padding-top: 20px;
    padding-bottom: 50px;
}

.exchange_text {
    max-width: 550px;
}

.exchange_contents {
    max-width: 800px;
    margin: auto;
}

/* .exchange_banner_head {
    font-size: 30px;
} */

.return_exchange_icon {
    width: 100px;
    height: 100px;
}

.corporate-align {
    bottom: 20px;
    left: 40px;
}

.text-dark {
    color: #000000 !important;
    color: var(--black) !important;
}

/* our story end */

/* webkit scrollbar start */

/* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgb(179, 179, 179);
}

::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: rgb(243, 243, 243);
}

.header_scroll_hide .offcanvas-body::-webkit-scrollbar {
    display: none !important;
}

.header_scroll_hide {
    z-index: 1047 !important;
} */

/* webkit scrollbar end */

/* ----- admin canvas start ----- */

.admin_canvas_product_list {
    padding: 32px 0px;
}

.admin_canvas_tab {
    padding-left: 0px !important;
    padding-bottom: 20px;
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.active_items {
    position: relative;
    top: 20px;
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
}

.admin_canvas_row {
    padding-top: 44px;
}

.admin_canvas_div {
    /* padding: 0 20px 0px 40px !important; */
    border-right: 1px solid #E7E7E7;
    border-right: 1px solid var(--border-line);
}

.admin_canvas_div:nth-of-type(3) {
    border-right: none;
}

/* .admin_canvas_content {
    max-width: 200px;
} */

.admin_canvas_content_padding {
    padding-bottom: 5px;
}

.admin_canvas_img {
    /* background-image: url(../images/akal_images/images/admin_canvas_img.png); */
    width: 113px;
    height: 109px;
    object-fit: cover;
    background-repeat: no-repeat;
}

.admin_canvas_styles {
    background-color: #FBFBFB;
    height: 100vh;
    overflow: auto;
}

.admin_canvas_styles_list:not(:last-child) {
    padding: 20px;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
}

.admin_canvas_detail_header {
    align-items: center;
    padding: 20px 90px;
    border-bottom: 0.3px solid #737373;
    border-bottom: 0.3px solid var(--text-color);
}

.admin_canvas_search {
    background-image: url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/admin/admin_search_bg.png);
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    /* opacity: 0.5; */
    background-color: #000000;
    background-color: var(--black);
}

.admin_canvas_search_div {
    height: 51px !important;
    width: 350px !important;
    background-color: #FFFFFF;
    background-color: var(--white);
    border: 1px solid #FFFFFF !important;
    border: 1px solid var(--white) !important;
    padding: 8px 17px !important;
    border-radius: 0px !important;
    color: var(--secondary_text) !important;
    font-size: 13px !important;
}

.admin_canvas_search_input {
    border: none !important;
}

.admin_canvas_btn {
    padding: 0px !important;
}

.admin_canvas_row_gap {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
}

.admin_canvas_col-4_padding {
    padding-left: 20px !important;
}


.admin_canvas_div .media .pi_container .custom_shirt {
    width: 20%;
    /* left: 3%; */
    position: absolute;
    top: -15px !important;
    bottom: 0;
    margin: 0 !important;
    /* margin: auto; */
}

.admin_canvas_view_btn {
    height: 90px;
}

/* ----- admin canvas end ----- */

/* ----- chatbot start ----- */

.brevo-conversations--side-bottom {
    bottom: 67px !important;
}


.h-35 {
    height: 35px !important;
}

/* ----- chatbot end ----- */

@media screen and (min-width: 2500px) and (min-height: 1200px) {

    .canvas_style_prev,
    .canvas_style_prev:active {
        width: 40px !important;
    }
}

@media (min-width: 993px) and (max-width: 1199px) {

    .footer_akal_gap {
        grid-gap: 32px;
        gap: 32px;
    }
}

@media (max-width: 1024px) {
    .style_banner_content {
        bottom: 22vh;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
        border: none !important;
    }


    .footer_below_akal {
        flex-direction: column;
    }

    .text_nowrap {
        text-wrap: wrap;
    }

    .footer_style_wrap {
        text-wrap: nowrap;
    }

    .style_section {
        grid-gap: 130px;
        gap: 130px;
    }

    .style_section_border_both {
        width: 550px;
    }

    .style_section_border {
        width: 550px;
    }

    .style_section_text_color {
        text-wrap: nowrap;
    }

    .offer_section_right {
        padding-left: 55px;
    }

    .offer_section_left {
        width: 100%;
    }

}

@media (max-width: 992px) {

    .button-design {
        padding-top: 18rem;
    }

    .w-500 {
        width: 100%;
    }

    .cart-checkout-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #FFFFFF;
        background-color: var(--white);
        padding: 10px 24px;
        z-index: 1;
    }

    .cart-price-items {
        padding-top: 32px;
    }

    .cart-align-mobile {
        padding-bottom: 120px;
    }

    .cart-product-alignment {
        padding: 40px 0 !important;
    }

    .header-menu-align {
        grid-gap: 49px;
        gap: 49px;
    }

    .our_story_banner {
        height: 350px;
    }

    .container {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .carousel-indicators {
        display: none !important;
    }

    .carousel-control-next-icon {
        /* background-image: url(../images/right-arrow1.png) !important; */
        background-image: url(/static/media/canvas_arrow.9d3b845f.svg) !important;
    }

    .carousel-control-prev-icon {
        /* background-image: url(../images/left-arrow.png) !important; */
        background-image: url(/static/media/canvas_arrow.9d3b845f.svg) !important;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

    .mobile-img-hide {
        display: none !important;
    }

    .header_popup_left {
        padding: 0 24px;
    }

    .style_banner {
        background: url(/static/media/style_img_mob.ec3663f3.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 635px;
    }


    .mobile-header {
        position: relative;
    }

    .footer_akal_gap {
        display: flex;
        flex-direction: column-reverse;
        grid-gap: 44px;
        gap: 44px;
        padding-bottom: 44px !important;
    }

    .footer_accordion {
        width: 100% !important;
    }

    .line_border_accordion {
        border-top: 1px solid #686868 !important;
        padding: 7px 0;
    }

    .line_border_accordion:last-child {
        border-bottom: 1px solid #686868 !important;
    }

    .footer_accordion .pdp_accordion_btn::after {
        visibility: visible;
        width: 21px !important;
        height: 21px !important;
    }

    .footer_accordion .pdp_accordion_btn:not(.collapsed):after {
        -webkit-transform: rotate(45deg) !important;
                transform: rotate(45deg) !important;
        transition: .4s;
    }

    .category_content,
    .category_content_2_items .category_1_2,
    .category_content_2_items .category_2_2 {
        width: 100% !important;
    }

    .home_section_names {
        text-wrap: nowrap;
        left: 700px !important;
        bottom: 20px;
    }

    .mobile_hidden {
        display: none;
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 44px 0px !important;
    }

    .offer_section_right {
        padding-left: 0px;
    }

    .style_section {
        flex-direction: column;
        padding: 24px 24px 60px 24px !important;
        margin-top: 24px;
        margin-bottom: 60px;
        grid-gap: 44px;
        gap: 44px;
    }

    .style_section_border {
        width: 100%;
    }

    .style_section_border_both {
        width: 100%;
    }

    .mobile_style_heading {
        font-size: 64px;
    }

    .mobile_style_names {
        font-size: 24px;
    }

    .style_section_text_color {
        font-size: 14px;
    }

    .plp_last_section_left {
        padding: 40px 40px 90px 40px;
    }

    .profile-tab-align {
        grid-gap: 24px;
        gap: 24px;
    }

    .footer_style_advice_section_content {
        padding: 32px 0px 32px 0px;
    }

    .get_more_section2 {
        flex-direction: column;
        padding: 24px 0px 90px 0px;
        /* margin-top: 24px; */
        /* margin-bottom: 20px; */
    }

    .get_more_left2 {
        width: auto;
    }

    .get_more_left2_heading {
        font-size: 34px;
    }

    .get_more_left_img2 {
        height: auto;
    }

    .get_more_right2 {
        flex-direction: column;
        grid-gap: 44px;
        gap: 44px;
        padding-top: 0px;
    }

    .get_more_right_section2 {
        align-self: flex-end;
    }

    .offer_section_left {
        width: 100%;
        border-right: 0px;
        margin-bottom: 54px !important;
    }

    .offer_section {
        flex-direction: column;
        padding: 44px 24px;
        margin: 44px 0px;
    }

    .suit_section {
        flex-direction: column-reverse;
    }

    .get_more_section {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column;
        padding: 44px 24px;
        /* margin: 44px 0px; */
    }

    .get_more_center_section {
        width: 100%;
        flex-direction: column-reverse;
    }

    .get_more_right_section {
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
    }

    .get_more_right_section_img_div {
        align-self: flex-end;
    }

    .mobile_get_more_section_reverse {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column-reverse;
    }

    .cart-left,
    .cart-right {
        padding: 0px !important;
        border: 0px !important;
    }

    .cart-quantity-border {
        border: 0.5px solid #d4d4d4;
        border: 0.5px solid var(--box-border);
        padding: 4px 13px !important;
        grid-gap: 10px;
        gap: 10px;
    }

    .fabric-text {
        max-width: 130px;
        font-size: 14px !important;
    }

    .subtotal-text {
        font-size: 14px;
    }

    .cart-gap {
        grid-gap: 11px;
        gap: 11px;
    }

    .cart-img img {
        width: 100px;
        height: 90px;
    }

    .header_popup_right {
        width: 100%;
        padding: 0 24px;
    }

    .web_popup {
        padding: 0 0 24px;
    }

    .web_popup_hidden {
        display: block;
    }

    .header_popup_menu {
        font-size: 20px !important;
        border-bottom: 0.5px solid #BBBBBB;
        padding: 20px 0px;
    }

    .header_popup_dropdown_menu {
        font-size: 20px !important;
    }

    .header_popup_menu_dropdown {
        padding: 12px 36px;
    }

    .copyrights_web_popup {
        align-self: flex-start;
    }

    .web_popup_logo {
        padding: 24px 24px 16px 24px;
    }

    .get_more_center_section_img {
        object-fit: cover;
    }

    .get_more_center_section_img_div {
        width: 100%;
        background-color: #EAEAE4;
    }

    .mobile_footer_logo {
        width: 90px;
    }

    .footer_logo {
        display: block;
        margin: auto;
    }

    .profile-order-status {
        padding: 0 !important;
    }

    .ordered_products {
        padding-top: 8px !important;
        padding-bottom: 44px;
    }

    .data-divider {
        padding-bottom: 32px;
    }

    .product_detail_page {
        flex-direction: column-reverse;
    }


    .PDP_mobile_fabric_p {
        padding: 20px 0px 10px 0px !important;
        font-size: 25px !important;
    }

    .PDP_fabric_img {
        width: 100%;
        flex-direction: column;
    }

    .detail_page_img {
        width: 100% !important;
        padding: 0px !important;
    }

    .detail_page_img_col {
        align-items: center;
        width: 100% !important;
    }

    .PDP_content_part {
        padding-bottom: 64px;
    }

    .PDP_fabric_img_col {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: fill;
    }

    .PDP_fabric_col_img {
        height: 440px;
        width: 100%;
    }

    .mobile_display {
        display: none;
    }

    .row_margin_top {
        padding-bottom: 64px;
    }

    .detail_first_img {
        width: 100%;
        height: 50% !important;
    }

    .PDP_container_mobile {
        padding: 0px 24px !important;
    }

    .PDP_container {
        padding: 0px 0px !important;
    }

    .mobile_pt-44 {
        padding-top: 44px;
    }

    .carousel_canvas .mobile_canvas_part {
        height: 52dvh !important;
    }

    .carousel_canvas .mobile_canvas_content {
        height: 40dvh !important;
    }

    .carousel_canvas {
        height: calc(100svh - 68.67px) !important;
    }

    .filter-mobile-icon {
        display: block !important;
    }

    .canvas_contents,
    .canvas_headings {
        width: 100% !important;
        background-color: #FFFFFF !important;
        background-color: var(--white) !important;
    }

    .canvas_headings {
        position: absolute;
        bottom: 0;
        height: auto !important;
    }

    .canvas_headings_align {
        grid-gap: 5px;
        gap: 5px;
        flex-direction: row;
    }

    .canvas_headings .head_detail {
        padding: 12px 10px;
    }

    .canvas_headings_align {
        padding: 0 18px !important;
    }

    .canvas_padding,
    .canvas-data-hide {
        display: none !important;
    }

    .canvas-menu-divider:not(:last-child) {
        border-bottom: 0px;
    }

    .canvas_details_icons .icons_name {
        width: 31px;
        height: 31px;
        padding: 6px;
    }

    .canvas_details_icons {
        bottom: 4vh;
        right: 13px;
        grid-gap: 5px !important;
        gap: 5px !important;
    }

    .canvas_top_btns {
        padding: 10px 17px !important;
    }

    .canvas_top_section {
        padding: 20px 20px 10px !important;
    }

    .canvas-text-color {
        font-size: 12px !important;
    }

    .fabric_icon {
        width: 24px !important;
    }

    .canvas-finish {
        display: block;
        position: absolute;
        top: 12px;
        right: 25px;
        z-index: 9;
        background-color: #FFFFFF;
        background-color: var(--white);
        padding: 5px 15px;
    }

    .canvas_close_btn {
        padding-top: 50px !important;
    }

    .fabric_detail_popup_col {
        height: 50%;
    }

    /* .canvas_content_scroll {
        height: calc(45% - 59.33px);
        overflow: auto;
        padding-bottom: 14vh;
    } */

    .header_nav {
        border-bottom: 0.5px solid #F0F0F0;
        border-bottom: 0.5px solid var(--bg_color);
        padding: 0px !important;
    }

    .mobile-menu {
        height: 8dvh;
    }

    .mobile_hidden {
        display: none;
    }

    .header_cart_img {
        width: 21px;
        height: 21px;
    }


    .home_section_names {
        text-wrap: nowrap;
        left: 533px !important;
        bottom: 40px;
    }

    .suit_section {
        flex-direction: column-reverse;
    }

    .suit_blazer_img_section {
        height: auto;
    }

    .home_img_section {
        width: 100%;
        height: auto;
    }

    .get_more_section {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column;
        padding: 44px 24px;
        /* margin: 44px 0px; */
    }

    .get_more_left_section_img {
        object-fit: cover;
        height: auto;
    }

    .get_more_right_section {
        /* align-items: flex-end; */
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
    }

    .get_more_center_section {
        width: 100%;
        flex-direction: column-reverse;
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 44px 0px;
    }

    .mobile_offer_80 {
        font-size: 54px;
    }

    .style_section {
        flex-direction: column;
        padding: 24px 24px 60px 24px !important;
        grid-gap: 44px;
        gap: 44px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .style_section_border {
        width: 100%;
    }

    .style_section_border_both {
        width: 100%;
    }

    .mobile_style_heading {
        font-size: 64px;
    }

    .mobile_style_names {
        font-size: 24px;
    }

    .style_section_text_color {
        font-size: 14px;
    }

    .get_more_left2 {
        width: auto;
    }

    .get_more_left_img2 {
        height: auto;
    }

    .get_more_right2 {
        flex-direction: column;
        grid-gap: 44px;
        gap: 44px;
        padding-top: 0px;
    }

    .get_more_right_section2 {
        align-self: flex-end;
    }

    .footer_style_advice_section_content {
        padding: 30px 0px;
    }

    .footer_style_advice_section .col-lg-4 {
        padding-right: 0px !important;
    }

    .mobile_footer_hidden {
        display: none;
    }

    .footer_shop_by {
        padding: 24px 0px;
        border-top: 1px solid #4D4D4D;
        border-bottom: 1px solid #4D4D4D;
    }

    .padding_gaps {
        margin-top: 24px;
    }

    .padding_gaps_top_bottom {
        padding-top: 24px;
        /* padding-bottom: 40px; */
    }

    .plp_header {
        padding: 0px 24px;
    }

    .plp_para {
        align-items: normal;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
    }

    .plp_para_reverse {
        flex-direction: column-reverse;
        align-items: normal;
        grid-gap: 20px;
        gap: 20px;
    }

    .para_width {
        text-align: left;
        font-size: 28px;
    }

    .para_width2 {
        width: auto;
        text-align: left;
    }

    .para_width3 {
        font-size: 16px;
    }

    .plp_right_icon {
        width: 16px;
    }

    .w-51 {
        width: 100% !important;
    }

    .w-33 {
        width: 100% !important;
    }

    .img_section {
        flex-direction: column;
    }

    .sort_section {
        padding: 0px 44px;
    }

    .sort_section_list {
        grid-gap: 17px;
        gap: 17px
    }

    .plp_looks_section {
        padding-top: 0px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 60px !important;
    }

    .mobile_plp_looks_section_header {
        font-size: 34px;
    }

    .plp_last_section_left {
        grid-gap: 64px;
        gap: 64px;
        padding: 40px 24px 90px 24px;
        width: 100%;
        flex-direction: column-reverse;
    }

    .plp_last_section_content {
        flex-direction: column-reverse;
    }

    .plp_last_section_right {
        width: 100%;
    }

    .plp_last_section_img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .mobile_padding_gaps_category_list {
        padding-top: 0px;
    }

    .footer_newsletter {
        flex-direction: column;
        grid-gap: 32px;
        gap: 32px;
        border-bottom: 0px solid #E8E8E8 !important;
    }

    .footer_newsletter_content {
        padding: 0px 0px;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
        border: none !important;
    }

    .mobile_login {
        flex-direction: column;
    }

    .login_img_section {
        width: 100%;
    }

    .login_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .login_content_section {
        position: relative;
        width: 100%;
        padding: 90px 90px;
    }

    .offer_section_left {
        width: 100%;
        border-right: none;
    }

    .get_more_left_section {
        height: auto;
    }

    .get_more_center_section_img_div {
        width: 100%;
        background-color: #EAEAE4;
        /* height: auto; */
    }

    .get_more_right_section_img_div {
        width: 256px;
        background-color: #EAEAE4;
        height: auto;
    }

    .get_more_center_section_img {
        object-fit: cover;
        /* height: auto; */
    }

    .mobile_get_more_section_reverse {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column-reverse;
    }

    .sort_btn {
        padding: 0px 12px !important;
    }

    .search_mobile_hide,
    .sort_btn {
        display: none !important;
    }



    .gap-align {
        grid-gap: 10px;
        gap: 10px;
    }

    .carousel-head {
        font-size: 24px;
    }

    .carousel-subtext {
        font-size: 12px;
        max-width: 240px;
    }

    .banner-slider-line-img {
        width: 39px;
        height: 2px;
        background-color: #FFFFFF;
        background-color: var(--white);
    }

    .canvas_content_scroll,
    .measure_contents {
        height: calc(42vh - 70px);
        overflow: auto;
        padding-bottom: 14vh;
    }

    .canvas_bottom_btns {
        display: none;
    }

    .admin_canvas_row {
        padding-top: 0px;
    }

    .admin_canvas_div {
        padding: 20px 20px 20px 20px !important;
        border-right: none;
        border-bottom: 1px solid #E7E7E7;
        border-bottom: 1px solid var(--border-line);
    }

    .admin_canvas_div:last-child {
        border-right: none;
        border-bottom: none;
    }

    .admin_canvas_fs_mobile {
        font-size: 12px !important;
    }

    /* .close-search {
        display: block;
    } */

    .cart_header_icon {
        left: -9px;
    }

    .cart-count-default {
        top: -4px;
    }

    .cart-count-scroll {
        top: -1px;
    }

    .mobile_admin_canvas_detail_meas {
        padding-left: 24px !important;
        padding-bottom: 20px !important;
    }

    .admin_canvas_styles {
        overflow: hidden;
        height: auto;
    }

    .mobile_admin_canvas_header {
        position: relative;
        left: -50px;
    }

    .admin_canvas_img_height {
        height: 600px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 24%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

    .corporate-align {
        left: 0px;
    }

    .canvas_style_prev,
    .canvas_style_prev:active,
    .canvas_style_prev:focus {
        width: 80px !important;
    }

}

@media (max-width: 912px) {
    .admin_canvas_img_height {
        height: 600px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 820px) {
    .admin_canvas_img_height {
        height: 520px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 770px) {
    .admin_canvas_img_height {
        height: 470px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 540px) {
    .home_section_names {
        text-wrap: nowrap;
        left: 350px !important;
        bottom: 25px;
    }

    .padding_gaps {
        margin-top: 24px;
    }

    .padding_gaps_top_bottom {
        padding-top: 24px;
        /* padding-bottom: 24px; */
    }

    .plp_header {
        padding: 0px 24px;
    }

    .plp_para {
        align-items: normal;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
    }

    .plp_para_reverse {
        flex-direction: column-reverse;
        align-items: normal;
        grid-gap: 20px;
        gap: 20px;
    }

    .para_width {
        text-align: left;
        font-size: 28px;
    }

    .para_width2 {
        width: auto;
        text-align: left;
    }

    .para_width3 {
        font-size: 16px;
    }

    .plp_right_icon {
        width: 16px;
    }

    /* .web_sort_section {
        display: none !important;
    } */

    .mobile_sort_section {
        display: block !important;
    }

    .mobile_sort_section_content {
        padding: 6px 24px;
    }

    .mobile_filter {
        width: 50%;
        padding: 12px 14px;
        border: 0.5px solid #AFAFAF;
    }

    .mobile_sort {
        width: 50%;
        padding: 12px 14px;
        border: 0.5px solid #AFAFAF;
    }

    .w-51 {
        width: 100% !important;
    }

    .w-33 {
        width: 100% !important;
    }

    .img_section {
        flex-direction: column;
    }

    /* .padding_gaps_img_section {
        padding-top: 28px;
    } */

    .mobile_padding_gaps_img_section {
        padding-top: 0px;
    }

    /* .padding_gaps_img {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 32px;
    } */
    .padding_gaps_img {
        padding-bottom: 25px;
    }


    .plp_looks_section {
        padding-top: 60px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 60px !important;
    }

    .mobile_plp_looks_section_header {
        font-size: 34px;
    }

    .plp_last_section_left {
        grid-gap: 64px;
        gap: 64px;
        padding: 40px 24px 90px 24px;
        width: 100%;
        flex-direction: column-reverse;
    }

    .plp_last_section_content {
        flex-direction: column-reverse;
    }

    .plp_last_section_right {
        width: 100%;
    }

    .plp_last_section_img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .mobile_padding_gaps_category_list {
        padding-top: 0px;
    }

    .max_width_content {
        max-width: 249px;
    }

    .mobile_popup_height {
        grid-gap: 44px;
        gap: 44px;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
    }

    .login_content_section {
        position: relative;
        width: 100%;
        padding: 80px 24px;
    }

    .style_section_border_both {
        padding: 16px 16px 16px 16px;
    }

    .style_section_border {
        padding: 16px 16px 16px 16px;
    }

    .style_see_collection_section {
        grid-gap: 16px !important;
        gap: 16px !important;
    }

    .get_more_right2_content {
        max-width: 230px;
    }

    .get_more_right_section_data {
        max-width: 242px;
    }

    .admin_canvas_row_gap {
        grid-row-gap: 1rem !important;
        row-gap: 1rem !important;
    }

    .admin_canvas_img_height {
        height: 330px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 15%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

    .admin_canvas_search_div {
        width: 100% !important;
    }

    .admin_canvas_search {
        /* background-image: url(../images/akal_images/images/admin_search_mobile_img.png); */
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/admin/admin_search_mobile_img.png);
        height: 100vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        /* opacity: 0.5; */
        /* background-color: var(--black); */
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 0px 0px !important;
    }

    .get_more_right_section_img_div {
        width: 200px;
        background-color: #EAEAE4;
        height: auto;
    }

    .get_more_center_section {

        height: 100%;
    }

    .mobile_flex {
        flex-direction: column;
    }
}

@media (max-width: 430px) {

    .home_section_names {
        left: 247px !important;
        bottom: 20px;
    }

    /* .admin_canvas_div .col-8 {
        padding-left: 35px;
    } */
    .admin_canvas_img_height {
        height: 400px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 22%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

}

@media (max-width: 380px) {
    .home_section_names {
        left: 212px !important;
        bottom: 20px;
    }

    .max_width_content {
        max-width: 200px;
    }

    .admin_canvas_img_height {
        height: 300px;
    }

}

@media (max-width: 360px) {

    .admin_canvas_img_height {
        height: 350px;
    }

    .canvas_details_icons {
        right: 11px !important;
    }

    .canvas-finish {
        right: 13px;
    }

}

@media (min-width: 993px) {

    .canvas_headings_align {
        grid-gap: 5px;
        gap: 5px;
        flex-direction: column;
    }

    .collapse_show,
    .profile_offcanvas {
        display: block !important;
    }

    .mobile_canvas_part {
        width: 60% !important;
    }

    .mobile_canvas_content {
        width: 40% !important;
    }

    .carousel-indicators {
        display: flex !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        display: none !important;
    }
}

@media (min-width:1024px) {

    .para_width {
        max-width: 512px !important;
        text-align: right;
    }

    .style_banner_content {
        bottom: 35vh;
    }

}

@media (min-width:1440px) {

    .carousel-head {
        font-size: 44px;
    }

    .carousel-subtext {
        font-size: 15px;
        max-width: 540px;
    }

    .cart-left {
        padding: 0 40px 0 0 !important;
    }

    .cart-right {
        padding: 0 0 0 72px !important;
        border-left: 1px solid #EFEFEF;
        border-left: 1px solid var(--cart-divider);
    }

}
.column {
  float: left;
  padding: 0;
}

.column .img-fluid{
  width: 100%;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: Helvetica-CondensedBold;
    src: url(/static/media/HelveticaNow-CondensedBold.ef591ead.ttf);
}

@font-face {
    font-family: Helvetica_Light;
    src: url(/static/media/HelveticaNowText-Light.226a5339.otf);
}

@font-face {
    font-family: Helvetica_Medium;
    src: url(/static/media/HelveticaNowText-Medium.304b6150.otf);
}

@font-face {
    font-family: PPWriter-RegularItalic;
    src: url(/static/media/PPWriter-RegularItalic.35daa61c.otf);
}

body {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
}

:root {
    --primary: #BDE1F6;
    --bg_color: #F0F0F0;
    --black: #000000;
    --header-text-color: #5F5F5F;
    --border-line: #E7E7E7;
    --text-muted: #949AA1;
    --box-border: #d4d4d4;
    --white: #FFFFFF;
    --text-color: #737373;
    --cart-bg: #F6F6F6;
    --cart-divider: #EFEFEF;
    --canvas-bg: #EFF8FD;
    --canvas-text-color: #AAAAAA;
    --canvas-active-text: #343434;
}

iframe {
    display: none !important;
}

.H-l {
    font-family: Helvetica_Light !important;
}

.H-m {
    font-family: Helvetica_Medium !important;
}

.H-cb {
    font-family: Helvetica-CondensedBold !important;
}

.PP_R {
    font-family: PPWriter-RegularItalic !important;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.fs-30 {
    font-size: 30px;
}

.fs-32 {
    font-size: 32px;
}

.fs-34 {
    font-size: 34px;
}

.fs-44 {
    font-size: 44px;
}

.fs-52 {
    font-size: 52px;
}

.fs-64 {
    font-size: 64px;
}

.fs-80 {
    font-size: 80px;
}

.fs-84 {
    font-size: 84px;
}

.gap-6 {
    grid-gap: 6px;
    gap: 6px;
}

.gap-8 {
    grid-gap: 8px;
    gap: 8px;
}

.gap-10 {
    grid-gap: 10px;
    gap: 10px;
}

.gap-11 {
    grid-gap: 11px;
    gap: 11px;
}

.gap-12 {
    grid-gap: 12px;
    gap: 12px;
}

.gap-14 {
    grid-gap: 14px;
    gap: 14px;
}

.gap-15 {
    grid-gap: 15px;
    gap: 15px;
}

.gap-16 {
    grid-gap: 16px;
    gap: 16px;
}

.gap-18 {
    grid-gap: 18px;
    gap: 18px;
}

.gap-20 {
    grid-gap: 20px;
    gap: 20px;
}

.gap-22 {
    grid-gap: 22px;
    gap: 22px;
}

.gap-24 {
    grid-gap: 24px;
    gap: 24px;
}

.gap-28 {
    grid-gap: 28px;
    gap: 28px;
}

.gap-30 {
    grid-gap: 30px;
    gap: 30px;
}

.gap-32 {
    grid-gap: 32px;
    gap: 32px;
}

.gap-37 {
    grid-gap: 37px;
    gap: 37px;
}

.gap-42 {
    grid-gap: 42px;
    gap: 42px;
}

.gap-44 {
    grid-gap: 44px;
    gap: 44px;
}

.gap-49 {
    grid-gap: 49px;
    gap: 49px;
}

.gap-64 {
    grid-gap: 64px;
    gap: 64px;
}

.gap-70 {
    grid-gap: 70px;
    gap: 70px;
}

.gap-80 {
    grid-gap: 80px;
    gap: 80px;
}

.gap-y-3 {
    grid-row-gap: 10px;
    row-gap: 10px;
}

.pt-44 {
    padding-top: 44px;
}

.pb-16 {
    padding-bottom: 16px;
}

.py-6 {
    padding: 6px 0;
}

.py-12 {
    padding: 12px 0;
}

.py-24 {
    padding: 24px 0;
}

.py-32 {
    padding: 32px 0;
}

.w-15 {
    width: 15%;
}

.w-17 {
    width: 17px;
    height: 17px;
}

.w-500 {
    width: 500px;
}

p {
    margin-bottom: 0px !important;
}

.text-color {
    color: #737373;
    color: var(--text-color);
}

.text-color-white {
    color: #FFFFFF;
    color: var(--white);
}

.text-color-black {
    color: #000000;
    color: var(--black);
}

.text_nowrap {
    text-wrap: nowrap;
}

/* ----- Header start ----- */

.header_height {
    height: 100dvh;
}

.nav_top {
    padding: 12px 0;
    background-color: #F0F0F0;
    background-color: var(--bg_color);
    color: #5F5F5F;
    color: var(--header-text-color);
}

.header_nav {
    padding: 22px 0;
    background-color: #FFFFFF;
    background-color: var(--white);
}

.header_nav ul li {
    color: #5F5F5F;
    color: var(--header-text-color);
}

.text_color {
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.scroll_header {
    top: 0;
    background-color: #FFFFFF !important;
    background-color: var(--white) !important;
    z-index: 9;
}

.position_absolute {
    position: absolute !important;
    top: 40px;
}

.position_fixed {
    position: fixed !important;
    top: 0 !important;
}

.z_index {
    z-index: 9;
}

.header {
    background-color: transparent;
}

.header-text {
    color: #5F5F5F !important;
    color: var(--header-text-color) !important;
}

.logo_position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.mobile-header {
    position: absolute;
}

.mobile-img-hide {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
}

.cart_position {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    position: relative !important;
}

.cart_header_icon {
    background-color: #000000;
    background-color: var(--black);
    text-align: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    content: '';
    left: -11px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    color: white;
}

.cart-count-scroll {
    top: 9px;
}

.cart-count-default {
    top: -5px;
}

.overlay-bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100% !important;
    background-color: black;
    opacity: 0.3;
}

/* ----- Header end ----- */

/* ----- header popup start ----- */

.accordion-button:not(.collapsed),
.accordion-button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    color: var(--black) !important;
}

.header_popup_left {
    padding: 24px 80px;
}

.accordion-button::after {
    background-image: url(/static/media/arrow_heading.357723d7.svg) !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/static/media/arrow_heading.357723d7.svg) !important;
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
}

.web_popup_logo {
    padding: 50px 80px 16px;
}

.header_exit_popup_img {
    width: 35px;
}

.header_popup_img {
    object-fit: cover;
    width: 437px;
    height: 345px;
}

.header_facebook_icon {
    width: 8px;
    height: 17px;
}

.header_whatsapp_icon {
    width: 18px;
    height: 18px;
}

.header_twitter_icon {
    width: 17px;
    height: 15px;
}

.header_instagram_icon {
    width: 15px;
    height: 15px;
}

.header_about_akal_icon {
    width: 15px;
    height: 15px;
}

.header_popup_logo {
    width: 110px;
    height: 42px;
}

.header_popup_right {
    padding: 60px 90px;
}

.header_popup_menu {
    border-bottom: 0.5px solid #BBBBBB;
    padding: 20px 35px;
    text-align: left !important;
    cursor: pointer;
}

.header_popup_menu_dropdown {
    padding: 12px 55px;
}

.copyrights_web_popup {
    align-self: flex-end;
}

.popup_copyrights {
    padding: 16px 0px;
}

.header_popup_dropdown_menu {
    font-size: 34px !important;
}

.mobile_popup {
    display: none;
}

.web_popup {
    background-color: #FFFFFF;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
}

.header_dropdown {
    background-image: url(/static/media/header_popup_dropdown_icon.ab3ee42d.svg) !important;
    width: 20px !important;
    height: 20px !important;
    background-size: cover !important;
}

.header_dropdown:not(.collapsed) {
    -webkit-transform: rotate(180deg) !important;
            transform: rotate(180deg) !important;
    transition: .4s;
}

.web_popup_hidden {
    display: none;
}

.copyrights {
    width: 11px;
    height: 11px;
}

/* .modal-backdrop {
    --bs-backdrop-zindex: 0 !important;
} */

/* .modal-backdrop.show {
    display: none !important;
} */

/* ----- header popup end ----- */


/* ----- profile start ----- */

.profile-letter {
    background-color: #BDE1F6;
    background-color: var(--primary);
    width: 95px;
    height: 95px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto !important;
}

.profile-tab-align {
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
    overflow: auto;
    grid-gap: 44px;
    gap: 44px;
}

.profile-tab-align .active_item {
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
}

.logout-muted {
    color: #949AA1;
    color: var(--text-muted)
}

.cart_form span {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4 !important;
    border-bottom: 1px solid var(--box-border) !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 0px 10px 0px 0px !important;
}

.cart_form input {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 7px 0 !important;
}

.cart_form .password {
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
}

.form-control:disabled {
    background-color: transparent !important;
}

input::-webkit-input-placeholder {
    font-family: Helvetica_Light;
    font-size: 14px;
}

input::placeholder,
input[type="text"],
input[type="password"],
input[type="email"] {
    font-family: Helvetica_Light;
    font-size: 14px;
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
.form-control:focus {
    box-shadow: none !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    /* border-bottom: 1px solid #dee2e6 !important; */
}

.canvas-input {
    border-radius: 0px !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #dee2e6 !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none !important;
}

.form-check-input {
    box-shadow: none !important;
}

.input-group-text {
    border: 0 !important;
    background-color: transparent !important;
}

.icon-bg {
    background-color: #BDE1F6;
    background-color: var(--primary);
    width: 68px;
    height: 68px;
}

.icon_alignment {
    width: 30px;
    height: 30px;
}

/* .profile-alignment-design {
    margin-top: 70px;
} */

.buttonDesign {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 25px;
}

.profile-order-status {
    padding-right: 120px !important;
}

.ordered_products {
    padding-top: 44px;
}

.profile-order-status-align {
    padding-top: 30px;
    padding-bottom: 44px;
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.add_measurement_popup_circle {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 68px;
    width: 68px;
    border-radius: 100px;
    border: 0.5px solid #d4d4d4;
    border: 0.5px solid var(--box-border);
}

.profile_popup_close_btn {
    width: 57px;
}

.profile_popup_proceed_btn {
    width: 170px;
}

.profile_measurement_popup {
    padding: 0px 0px 0px 54px;
    width: 90%;
}

.profile-icon {
    width: 20px;
    height: 20px;
}

.profile-data-border:not(:nth-of-type(3n)) {
    border-right: 1px solid #f0f0f0 !important;
}

/* ----- profile end ----- */

/* ----- home page start ----- */

.main_banner {
    text-align: left;
    height: 100vh;
    /* position: relative; */
}

.carousel_banner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 40% !important;
    left: 0;
}

.gap-align {
    grid-gap: 24px;
    gap: 24px;
}

.banner_content {
    /* padding-left: 100px; */
    /* width: 100%; */
    margin: auto;
    color: #FFFFFF;
    color: var(--white);
}

.carousel_img {
    width: 60px !important;
}


.home_img_section {
    background-color: #F0F0F0;
    height: 743px;
}

.blazer_section,
.suit_section {
    position: relative;
}

.home_section_names {
    position: absolute;
    left: 3.6rem;
    bottom: 20px;
    color: #FFFFFF;
    color: var(--white);
}

.get_more_section {
    padding: 90px 0px;
}

.get_more_left_section {
    background-color: #EAEAE4;
    height: 641px;
}

.get_more_left_section_img {
    object-fit: cover;
    height: 641px;
}

.get_more_center_section_img_div {
    width: 344px;
    background-color: #EAEAE4;
    height: 337px;
}

.get_more_center_section_img {
    object-fit: cover;
    height: 337px;
}

.get_more_right_section_img_div {
    background-color: #EAEAE4;
    height: 303px;
}

.get_more_right_section_img {
    object-fit: cover;
    height: 303px;
}

.get_more_center_section {
    flex-direction: column;
    width: 344px;
    height: 528px;
}

.get_more_right_section {
    flex-direction: column;
    width: 205px;
    /* height: 641px; */
}

.suit_blazer_img_section {
    object-fit: cover;
    height: 743px;
}

.offer_section {
    padding: 70px 0px;
}

.offer_section_left {
    /* width: 97%; */
    border-right: 1px solid #000000;
    border-right: 1px solid var(--black);
    padding-right: 115px;
}

.offer_section_right {
    padding-left: 115px;
}

.btn-get-more {
    width: 205px;
    height: 48px;
    font-size: 18px !important;
    font-family: Helvetica-CondensedBold !important;
    background-color: #BDE1F6 !important;
    background-color: var(--primary) !important;
    border-radius: 0px !important;
}

.get_more_section2 {
    padding: 24px 0px 90px;
}

/* .get_more_left2 {
    width: 626px;
} */

.get_more_left_img2 {
    object-fit: cover;
    height: 553px;
}

/* .get_more_right2 {
    padding-top: 82px;
} */

.get_more_right_section2_img {
    height: 296px;
    object-fit: cover;
}

.style_section {
    padding: 24px 0px 60px;
}

.style_section_border {
    padding: 16px 24px 16px 24px;
    border-top: 1px solid #737373;
    border-top: 1px solid var(--text-color);
}

.style_section_border:last-child {
    border-bottom: 1px solid #737373;
    border-bottom: 1px solid var(--text-color);
}

s .style_section_text_color {
    color: #737373;
    color: var(--text-color);
}

.style_see_collection_section {
    align-items: center;
}

.style_banner {
    background: url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/style_img.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 635px;
}

g .mobile_style_banner {
    background: url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/mobile_style_img.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 635px;
}

.style_banner_content {
    color: #FFFFFF;
    color: var(--white);
}

.btn-getmore {
    width: 205px;
}

.banner-slider-line-img {
    width: 60px;
    height: 3px;
    background-color: #FFFFFF;
    background-color: var(--white);
}

.carousel-head {
    font-size: 44px;
}

.carousel-subtext {
    font-size: 15px;
    max-width: 640px;
}

.button-design {
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 15rem;
    grid-gap: 12px;
    gap: 12px;
}

.button-primary {
    background-color: #BDE1F6 !important;
    background-color: var(--primary) !important;
    color: #000000 !important;
    color: var(--black) !important;
}

.banner-btn {
    border: 1px solid #FFFFFF !important;
    border: 1px solid var(--white) !important;
    background-color: transparent !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.get_more_left2_heading {
    max-width: 550px;
}

/* ----- home page end ----- */


/* ----- footer page start ----- */

.footer_content {
    background-color: #000000;
    background-color: var(--black);
    color: #FFFFFF;
    color: var(--white);
}

.footer_style_advice_section {
    padding-top: 44px !important;
}

.footer_style_advice_section .w-90 {
    padding-left: 0.8rem !important;
}

.footer_style_advice_section_content {
    padding: 52px 0px 62px 0px;
}

.newsletter {
    background-color: #2C2C2C !important;
    height: 64px;
    padding-left: 24px !important;
}

.footer_newsletter {
    border-bottom: 1px solid #E8E8E8 !important;
}

.footer_newsletter_content {
    padding: 44px 0px;
}

input.newsletter[type="text"]::-webkit-input-placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
    color: #B3B3B3;
}

input.newsletter[type="text"]::placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
    color: #B3B3B3;
}

.newsletter,
.newsletter_form_text {
    border-radius: 0 !important;
}

.newsletter_form_text {
    font-family: Helvetica-CondensedBold;
    font-size: 16px !important;
    background-color: #2C2C2C !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    padding-right: 24px !important;
}


.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}

.footer_border {
    border-bottom: 0.3px solid #000000;
    border-bottom: 0.3px solid var(--black);
}

.footer_content input[type="text"] {
    color: white;
    font-family: Helvetica_Light;
}

.footer_logo {
    width: 90px;
}

.footer_facebook_icon {
    width: 8px;
    height: 17px;
}

.footer_whatsapp_icon {
    width: 18px;
    height: 18px;
}

.footer_twitter_icon {
    width: 17px;
    height: 15px;
}

.footer_instagram_icon {
    width: 15px;
    height: 15px;
}

.footer_about_akal_icon {
    width: 15px;
    height: 15px;
}

.footer_popup {
    height: 744px;
    border-radius: 0px !important;
    padding: 70px 32px;
    color: #000000 !important;
    color: var(--black) !important;
    border: none !important;
}

.footer_popup_list {
    padding: 20px 18px;
    border-top: 1px solid #E7E7E7;
    border-top: 1px solid var(--border-line);
}

.footer_popup_list:last-child {
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.footer_exit_popup_img {
    width: 28px;
    left: 20px;
    position: absolute;
    top: 20px;
}

.footer_accordion .pdp_accordion_btn::after {
    background-image: url(/static/media/plus.2b5a6d0a.svg) !important;
    visibility: hidden;
    width: 24px !important;
    height: 24px !important;
    background-size: cover !important;
}

.footer_accordion .pdp_accordion_btn:not(.collapsed):after {
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
    transition: .4s;
}


.footer_akal_gap {
    padding-bottom: 44px;
}

.footer_reverse {
    display: flex;
    flex-direction: row;
    grid-gap: 80px;
    gap: 80px;
}

.footer_subhead {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer_accordion,
.pdp_accordion_btn {
    background-color: #000000 !important;
    background-color: var(--black) !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.accordion-button:not(.collapsed),
.accordion-button:focus {
    color: #000000 !important;
    color: var(--black) !important;
}

.accordion-active {

    .accordion-button:not(.collapsed),
    .accordion-button:focus {
        color: #FFFFFF !important;
        color: var(--white) !important;
    }

}


/* ----- footer page end ----- */

/* ----- plp page start ----- */

.para_width {
    max-width: 650px;
    text-align: center;
}

.category_list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.category_list p {
    margin: 0;
    color: var(--secondary_text);
}

.active_category {
    border-bottom: 1.3px solid #000000;
    border-bottom: 1.3px solid var(--black);
}

.category_description {
    color: var(--secondary_text);
}

.category_part {
    background-color: #F0F0F0;
    background-color: var(--bg_color);
    display: flex;
    height: 552px !important;
    cursor: pointer;
}

.category_content,
.category_content_2_items .category_1_2,
.category_content_2_items .category_2_2 {
    width: calc(50% - 3px);
}

.category_content_3_items,
.category_content_2_items {
    width: 100%;
    grid-row-gap: 4px;
    row-gap: 4px;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
}

.category_content_3_items .category_1_1_3,
.category_content_3_items .category_2_1_3,
.category_content_3_items .category_2_2_3 {
    width: calc(33.25% - 3px);
}

.dropdown_border {
    border: 0.5px solid #d4d4d4 !important;
    border: 0.5px solid var(--box-border) !important;
    border-radius: 0 !important;
    width: 195px;
}

.dropdown_border li span {
    padding: 8px 33px;
}

.dropdown_border li span:hover,
.dropdown_border li span:active {
    background-color: #fafafa !important;
    color: #000000;
    color: var(--black);
}

.category_content p {
    font-size: 14px;
    font-family: Helvetica_Light;
}

/* .category_content.category3 {
    height: 370px !important;
} */

.overall_width {
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 6px;
    -webkit-column-gap: 6px;
            column-gap: 6px;
}

.color_select {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    margin: 0 9px 0 0;
    cursor: pointer;
}

.material_name {
    max-width: 270px;
    margin: 0 !important;
}

.category_align {
    margin-top: 13px;
}

.currency_amount {
    display: flex;
    align-items: center;
    color: var(--secondary_text);
    margin: 0;
}

.dropdown_toggle_remove {
    padding: 0 20px;
}

.dropdown_toggle_remove::after {
    display: none !important;
}

.sort_btn {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 0px !important;
    color: #737373 !important;
    color: var(--text-color) !important;
    padding: 5px 12px !important;
}

.plp_sort_btn {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 0px !important;
    color: #737373 !important;
    color: var(--text-color) !important;
    padding: 5px 12px !important;
}

.category_part img,
.PDP_image_content img {
    max-width: 100%;
    width: 100%;
    height: 80%;
    object-fit: contain;
}

.shirt_list_page {
    padding: 40px 0 0 0;
}

.padding_gaps {
    margin-top: 24px;
    padding: 0 24px;
}

/* .padding_gaps_img_section {
    padding-top: 28px;
} */

.padding_gaps_img {
    padding-bottom: 50px;
}


.plp_img_col-3 {
    height: 550px;
    background-color: #F0F0F0;
}

.plp_header {
    padding: 24px 90px;
}

.plp_para {
    align-items: flex-end;
}

.para_width {
    /* max-width: 512px; */
    text-align: right;
}

.para_width3 {
    text-align: right;
    text-wrap: nowrap;
}

.para_width2 {
    width: 376px;
    text-align: left;
}

.plp_para_reverse {
    align-items: flex-end;
}

.category_list {
    grid-gap: 34px;
    gap: 34px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

/* .sort_section {
    padding: 0px 90px;
} */

.sort_section_list {
    grid-gap: 30px;
    gap: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    color: #737373;
    color: var(--text-color);
    padding-top: 5px;
}

.plp_dropdown_icon {
    width: 8px;
}

.dropdown_toggle_remove {
    padding: 0 20px;
}

.dropdown_toggle_remove::after {
    display: none !important;
}

.sort_by_icon {
    padding-left: 14px;
}

.mobile_sort_section {
    display: none !important;
}

.img_section_content {
    padding: 0 10px;
}

.color_circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #915252;
}

.max_width_content {
    max-width: 310px;
}

.w-51 {
    width: 50%;
}

.w-33 {
    width: 33%;
}

.currency_icon {
    width: 8px;
    height: 12px;
}

.plp_looks_section {
    padding-top: 70px;
    padding-bottom: 60px;
}

.plp_fit_icon {
    width: 93px;
    height: 93px;
}

.plp_looks_section_max_width {
    max-width: 973px;
}

.plp_last_section_right {
    width: 55%;
}

.plp_last_section_left {
    flex-direction: column;
    width: 45%;
    padding: 40px 90px 90px 90px;
    background-color: #F0F0F0;
}

.plp_last_section_img {
    object-fit: cover;
    width: 100%;
    height: 886px;
}

/* 
.padding_gaps_top_bottom {
    margin-top: 64px;
} */

/* ----- plp page end ----- */

/* ----- pdp page start ----- */

.PDP_container {
    padding: 0px 90px !important;
}

.PDP_content_part {
    border-top: 0.5px solid #E7E7E7;
    border-top: 0.5px solid var(--border-line);
}

.PDP_header_detail {
    padding-bottom: 15px;
    border-bottom: 0.2px solid #E7E7E7;
    border-bottom: 0.2px solid var(--border-line);
}

.PDP_btn {
    border: none;
    background: none;
}

.row_margin_top {
    /* margin-top: 65px !important; */
    padding-bottom: 70px;
}

.detail_first_img {
    width: 100%;
    height: 50% !important;
}

.PDP_mobile_fabric_p {
    padding-top: 20px;
}

.PDP_fabric_img_col {
    max-width: 100%;
    width: 100%;
    /* height: 641px; */
    object-fit: fill;
}

.detail_page_img_col {
    /* width: 100% !important; */
    height: 50% !important;
    object-fit: fill !important;
    padding-left: 6px !important;
}

/* .detail_page_img_col2 {
    padding-top: px;
} */

.PDP_fabric_col_img {
    height: 641px;
    width: 100%;
    object-fit: contain;
}

.PDP_padding_right {
    padding-right: 44px !important;
}

.mobile_pt-44 {
    padding-top: 0px;
}

/* ----- pdp page end ----- */

/* ----- pdp popup start ----- */
.buttons_align {
    text-align: center;
    margin-top: 35px;
    grid-gap: 0.6rem;
    gap: 0.6rem;
}

.common_btn {
    /* border-radius: none !important; */
    padding: 10px 17px !important;
    font-family: Helvetica_Light !important;
    font-size: 13px !important;
}

.measurement_btn {
    border-radius: 0px !important;
    /* border: 0.3px solid var(--box-border) !important; */
    width: 26%;
    font-family: Helvetica_Light !important;
    padding: 10px 12px !important;
}

.measurement_btn.active_btn {
    border: 0.3px solid #4E4E4E !important;
}

.measurement_btn span {
    font-family: Helvetica_Light !important;
    display: block;
    font-size: 13px;
}

.measurement_btn_close {
    color: #000000;
    color: var(--black);
    cursor: pointer;
}

.size_chart_btn {
    width: 32%;
}

.table_class {
    overflow-x: auto;
    position: relative;
}

.table_class th,
.table_class td {
    border: 0.3px solid var(--category-bg-color);
    text-align: center;
    padding: 15px 25px;
    font-family: Helvetica_Light;

}

.table_class th {
    padding: 5px 20px;
}

.table_class th:nth-child(even),
.table_class td:nth-child(even) {
    background-color: rgba(189, 225, 246, 0.20);
}

.table_class th:first-child,
.table_class td:first-child {
    width: 175px !important;
    display: inline-block;
    position: -webkit-sticky !important;
    position: sticky !important;
    left: 0 !important;
    z-index: 2;
    background-color: var(--white_color);
    text-align: start !important;
    border-left: 0;
    padding-left: 0 !important;
}

.table_class td:first-child {
    cursor: pointer;
}

/* .table_class th,
.table_class td {
    position: relative !important;
    left: 200px !important;
} */

.table_class tr {
    border-top: 0.3px solid #d4d4d4;
    border-top: 0.3px solid var(--box-border);
    max-width: 100%;
}

.table_class th,
.table_class td {
    width: 100px;
}

.toggle_icon {
    font-size: 26px;
}

.measure_info {
    color: #d4d4d4;
    color: var(--box-border);
}

.measure_contact_align {
    grid-gap: 1.3rem;
    gap: 1.3rem;
}

.measure_contact {
    border: 1.5px solid #d4d4d4 !important;
    border: 1.5px solid var(--box-border) !important;
    border-radius: 10px;
    cursor: pointer;
}

.PDP_exit_popup_img {
    width: 28px;
    /* left: 20px;
    position: relative;
    top: 20px; */
}

.pdp_popup_close_btn {
    width: 57px;
}

.pdp_popup_proceed_btn {
    width: 111px;
}

.pdp_icon {
    width: 26px;
}

.margin_align {
    margin-top: 30px;
    margin-bottom: 5px;
}

.px_measure_values {
    padding: 1.5rem 3.5rem;
}

.measure_3_items_align .col-3:nth-of-type(1) {
    text-align: center;
    padding-right: 0.25rem !important;
    padding-left: 0 !important;
}

.measure_3_items_align .col-3:nth-of-type(2) {
    padding: 0 0.25rem !important;
}

.measure_3_items_align .col-3:nth-of-type(3) {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
}

.measure_3_items_align .col-3:nth-of-type(4) {
    padding-left: 0.25rem !important;
    padding-right: 0 !important;
}

.measure_data {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55px;
    height: 55px;
    border-radius: 100px;
    border: 1px solid #f1f1f1 !important;
    padding: 8px 10px;
    cursor: pointer;
    margin: 0;
    text-align: center;
    font-family: Helvetica-CondensedBold;
    font-size: 14px;
    color: #737373;
    color: var(--text-color);
}

.measure_data:hover,
.measure_data.active_measure {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55px;
    height: 55px;
    border-radius: 100px;
    border: 1px solid #F9F9F9 !important;
    background-color: #eaeaea;
    color: #737373;
    color: var(--text-color);
    font-family: Helvetica-CondensedBold;
    font-size: 14px;
}

.measure_data p {
    text-align: center;
    margin: 0;
}


/* ----- pdp popup end ----- */

/* ----- login start ----- */

.login_img_section {
    width: 60%;
}

.login_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.forgot_password_design {
    padding: 30px !important;
}

.login_content_section {
    position: relative;
    width: 40%;
    padding: 30px 90px;
}

.login_form_control {
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 0.5px solid #d4d4d4 !important;
    border-bottom: 0.5px solid var(--box-border) !important;
    border-radius: 0px !important;
}

.login_input_text_color {
    color: #949AA1;
    color: var(--text-muted);
}

.login_btn {
    height: 45px;
    background-color: #BDE1F6 !important;
    background-color: var(--primary) !important;
    font-family: Helvetica-CondensedBold !important;
}

.remember-me {
    border-radius: 0px !important;
    width: 19px !important;
    height: 19px !important;
}

.login_logo {
    width: 59px;
    height: 23px;
    position: absolute;
    top: 30px;
    right: 45px;
}

/* ----- login end ----- */

/* ----- cart start ----- */

.btn-width {
    width: 100% !important;
}

.cart-divider-line {
    border-top: 1px solid #EFEFEF;
    border-top: 1px solid var(--cart-divider);

}

.cart-quantity-border {
    border: 0.5px solid #d4d4d4;
    border: 0.5px solid var(--box-border);
    padding: 6px 9px;
    grid-gap: 18px;
    gap: 18px;
}

.cart-product-alignment:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
    border-bottom: 1px solid var(--cart-divider);
}

.cart-product-alignment {
    padding: 55px 0 !important;
}

.cart-padding {
    padding: 24px 0 60px 0;
}

.cart-left {
    padding: 0 40px 0 0 !important;
}

.cart-right {
    padding: 0 0 0 40px !important;
    border-left: 1px solid #EFEFEF;
    border-left: 1px solid var(--cart-divider);
}

.heading-dotted {
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
}

.text_grey {
    color: #737373;
    color: var(--text-color);
}

.cart-img img {
    width: 130px;
    height: 100px;
    object-fit: contain !important;
    /* background-color: var(--cart-bg); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-icon {
    width: 16px;
    height: 16px;
}

/* ----- cart end ----- */

/* ----- address popup start ----- */

.address_exit_icon {
    width: 22px;
    height: 22px;
}

.address_popup {
    /* border-top: 0.5px solid var(--box-border); */
    border-bottom: 0.5px solid #d4d4d4;
    border-bottom: 0.5px solid var(--box-border);
    padding: 28px 0px;
}

.address_popup:first-child {
    border-top: 0.5px solid #d4d4d4;
    border-top: 0.5px solid var(--box-border);
}

.address_popup_btn {
    border-top: 0.3px solid #d4d4d4;
    border-top: 0.3px solid var(--box-border);
}

.address_popup_close_btn {
    width: 57px;
}

.address_popup_proceed_btn {
    width: 111px;
}

.cart_form select {
    border-radius: 0px !important;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    padding: 7px 0 !important;
}

/* ----- address popup end ----- */

/* ----- canvas start ----- */

.filter-mobile-icon {
    display: none !important;
}

.canvas_align {
    background-color: #EFF8FD;
    background-color: var(--canvas-bg);
}

.carousel_canvas {
    height: calc(100svh);
    overflow: hidden;
    margin: 0 !important;
}

.canvas_img_change {
    height: 80% !important;
    object-fit: contain !important;
}

.canavas_slide_change {
    height: 100%;
    background-color: var(--category-bg-color);
}

.canvas_align {
    height: 100%;
    position: relative;
}

.border_canvas {
    border-top: 0.2px solid #d4d4d4;
    border-top: 0.2px solid var(--box-border);
    border-right: 0.2px solid #d4d4d4;
    border-right: 0.2px solid var(--box-border);
}

.canvas_contents {
    width: 80%;
}

.canvas_headings {
    width: 20%;
    height: calc(100vh - 72.67px);
    overflow-y: auto;
    overflow-x: hidden;
}

.canvas_headings::-webkit-scrollbar {
    display: none !important;
}

.canvas_top_btns {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #d4d4d4 !important;
    border-bottom: 1px solid var(--box-border) !important;
    padding: 8px 17px !important;
    border-radius: 0px !important;
    color: var(--secondary_text) !important;
    font-size: 13px !important;
}

.canvas_search {
    font-size: 14px !important;
}

.canvas_search::-webkit-input-placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
}

.canvas_search::placeholder {
    font-size: 14px;
    font-family: Helvetica_Light;
}

.canvas_fabric_name,
.canvas_fabric_dollar {
    font-size: 12px;
    color: #737373;
    color: var(--text-color);
    margin: 0 !important;
    font-family: Helvetica_Light;
}

.canvas_fabric_name {
    font-size: 12px;
    color: #737373;
    color: var(--text-color);
    margin: 0 !important;
    font-family: Helvetica_Light;
    text-transform: lowercase !important;
}

.canvas_fabric_name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 110px;
}

.fabric_section {
    width: 100%;
    height: 15vh;
    border-radius: 100px !important;
}

.fabric_material {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
}

.canvas_headings .heading_align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.canvas_headings .head_detail {
    padding: 20px 18px;
    text-align: center;
    cursor: pointer;
}

.canvas_headings .heading_align.active {
    background-color: var(--category-bg-color) !important;
    border-radius: 0px;
}

.canvas_headings p {
    margin: 0;
    padding-top: 7px;
}

.canvas_bottom_btns {
    position: absolute;
    bottom: 0px;
    background-color: #FFFFFF;
    background-color: var(--white);
    width: 100%;
    padding: 0.75rem 1.2rem;
    border-top: 0.2px solid #d4d4d4;
    border-top: 0.2px solid var(--box-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.canvas_bottom_text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.canvas_headings_align {
    padding: 0 1.2rem 5rem;
    grid-gap: 11px;
    gap: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canvas_content_scroll {
    height: calc(100vh - 59.33px);
    overflow: auto;
    padding-bottom: 20vh;
}

.fabric_icon {
    width: 26px;
}

.canvas_top_section {
    padding: 27px 20px 13px;
}

.canvas_prev_next {
    padding: 0 !important;
    width: 34px;
    height: 34px;
    background-color: #BDE1F6 !important;
    background-color: var(--primary) !important;
    border-radius: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.canvas_style_prev,
.canvas_style_prev:active {
    width: 58px !important;
    border: 1px solid #d9d9d9 !important;
    background-color: transparent !important;
}

.canvas_style_headings {
    overflow-x: hidden !important;
    display: flex;
    flex-wrap: nowrap;
    /* border-bottom: 0.3px solid var(--box-border); */
    margin: 0 15px;
}

.menu-wrapper {
    border-bottom: 0.3px solid #d4d4d4;
    border-bottom: 0.3px solid var(--box-border);
}

.canvas_style_headings a {
    padding: 18.5px 15px;
    text-decoration: none;
    color: #949AA1;
    color: var(--text-muted);
    display: inline-block;
    font-size: 14px !important;
    font-family: 'Helvetica_Light';
}

.canvas_style_headings a.active_text {
    color: #000000;
    color: var(--black);
    border-bottom: 1.3px solid #737373;
    border-bottom: 1.3px solid var(--text-color);
}

.canvas_style_contents {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
}

/* .canvas_style_contents .canvas_style_section:not(:last-child) {
    border-bottom: 0.3px solid var(--box-border);
} */

.canvas_measure_align {
    border: 1px solid var(--category-bg-color);
    border-radius: 10px;
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
    height: 98.33px;
    display: flex;
    align-items: center;
}

.canvas_measure_align p {
    font-size: 12px;
    margin: 0;
}

.style_img {
    width: 50px;
    height: 50px;
    margin: auto;
}

.style_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.style_type {
    border: 1px solid var(--category-bg-color);
    border-radius: 10px;
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
    height: 100%;
}

.style_type.active_type {
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 0px;
}

.style_type.active_type p {
    color: #000000;
    color: var(--black);
}

.style_type p {
    color: #949AA1;
    color: var(--text-muted);
    font-size: 13px;
    margin: 0;
}

.style_type.measure p:first-child {
    font-size: 13px;
    font-family: Open-sans-m;
    color: #000000;
    color: var(--black);
}

.measure_contents .canvas_measure_align p:first-child {
    color: #000000;
    color: var(--black);
    font-size: 14px !important;
}

.measure_contents {
    height: calc(100vh - 72.67px);
    overflow: auto;
    padding-bottom: 13vh !important;
}

.canvas_details_icons {
    position: absolute;
    bottom: 8vh !important;
    right: 30px !important;
    z-index: 1;
}

.canvas_icon_color {
    color: var(--secondary_text);
}

.carousel_canvas .mobile_canvas_part {
    position: relative;
}

.canvas_details_icons .icons_name {
    background-color: #FFFFFF;
    background-color: var(--white);
    width: 41px;
    height: 41px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 9px;
}

.canvas_close_btn {
    opacity: 1 !important;
    border-radius: 50% !important;
}

.canvas_close_btn:focus,
input[type="search"]:focus {
    box-shadow: none !important;
}

.canvas_fabric_img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.canvas_bottom_btn_align {
    grid-gap: 12px;
    gap: 12px;
}

.measure_inputs {
    background-color: var(--summary-bg-color);
    border: 1px solid var(--footer-border);
    border-radius: 10px;
}

.px_summary {
    padding: 2.2rem 4rem;
    grid-gap: 13px;
    gap: 13px;
}

.measure_inputs p {
    margin: 0;
}

.measure_inputs.summary_style .row:not(:last-child) {
    border-bottom: 0.3px solid #EEEEEE;
}

.measure_inputs.summary_style .row {
    padding: 14px 0 !important;
}

.active_color {
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 0px;
}

.active_button {
    border: 1px solid #d4d4d4 !important;
    border: 1px solid var(--box-border) !important;
    border-radius: 50%;
}

.formal_casual_text {
    position: absolute;
    bottom: -35px;
}

.canvas_formal_icon {
    height: 30px !important;
    width: 27px !important;
    /* padding: 0.3rem !important; */
}

.prev_icon {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 300px;
    height: 300px;
}

.modal {
    height: 100% !important;
}

.canvas-logo {
    width: 60px !important;
}

.canvas_padding {
    position: absolute;
    top: 0;
    padding: 30px 40px !important;
}

.canvas-active-text {
    color: #343434;
    color: var(--canvas-active-text);
}

.canvas-text-color {
    color: #AAAAAA;
    color: var(--canvas-text-color);
}

.canvas-menu-divider:not(:last-child) {
    border-bottom: 1px solid #ececec;
    width: 100%;
}

.icon_alignment.canvas {
    width: 24px;
    height: 24px;
}

.icon-bg.canvas {
    width: 52px;
    height: 52px;
}

.border-divider-summary {
    border-bottom: 1px solid #EEEEEE;
}

.canvas-finish {
    display: none;
}

.arrowCanvas {
    width: 15px;
    height: 15px;
}

.arrowCanvas.prev {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.canvas_popup_proceed_btn {
    width: 110px;
}

.close-search {
    display: none;
}

.custom_measure {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #d4d4d4;
    border: 1px solid var(--box-border);
    border-radius: 10px;
    padding: 8px 0;
    border-radius: 0px;
}

.canvas_summary_addtocart {
    width: 180px;
}

.measure_content_scroll {
    height: calc(100vh - 122px);
    overflow: auto;
    padding-bottom: 30px;
}

.measurement_popup {
    border-top: 0.5px solid #d4d4d4;
    border-top: 0.5px solid var(--box-border);
}

.measurement_popup:last-child {
    padding-bottom: 30px;
    border-bottom: 0.5px solid #d4d4d4;
    border-bottom: 0.5px solid var(--box-border);
}

.disable-measurement {
    color: #d4d4d4;
    color: var(--box-border);
    /* cursor: not-allowed; */
}

.disable-measurement:hover {
    background-color: transparent;
    cursor: not-allowed;
    color: #d4d4d4;
    color: var(--box-border);
}

/* ----- canvas end ----- */

/* ----- faq start ----- */

.faq-accordion {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #E7E7E7 !important;
    border-bottom: 1px solid var(--border-line) !important;
}

.faq-div {
    padding-top: 50px;
}

/* ----- faq end ----- */


/* ----- blog start ----- */
.blog-img {
    background-color: #F0F0F0;
}

.blog-para {
    max-width: 200px
}

/* ----- blog end ----- */

/* our story start */

.our_story_banner {
    height: 600px;
}

.icon-bg.our_story {
    width: 50px;
    height: 50px;
}

.icon_alignment.our_story_icon {
    width: 34px;
    height: 34px;
}

.our_story_icon {
    width: 170px;
    height: 170px;
    object-fit: contain;
}

.our_story_align {
    padding-top: 20px;
    padding-bottom: 50px;
}

.exchange_text {
    max-width: 550px;
}

.exchange_contents {
    max-width: 800px;
    margin: auto;
}

/* .exchange_banner_head {
    font-size: 30px;
} */

.return_exchange_icon {
    width: 100px;
    height: 100px;
}

.corporate-align {
    bottom: 20px;
    left: 40px;
}

.text-dark {
    color: #000000 !important;
    color: var(--black) !important;
}

/* our story end */

/* webkit scrollbar start */

/* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgb(179, 179, 179);
}

::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: rgb(243, 243, 243);
}

.header_scroll_hide .offcanvas-body::-webkit-scrollbar {
    display: none !important;
}

.header_scroll_hide {
    z-index: 1047 !important;
} */

/* webkit scrollbar end */

/* ----- admin canvas start ----- */

.admin_canvas_product_list {
    padding: 32px 0px;
}

.admin_canvas_tab {
    padding-left: 0px !important;
    padding-bottom: 20px;
    border-bottom: 1px solid #E7E7E7;
    border-bottom: 1px solid var(--border-line);
}

.active_items {
    position: relative;
    top: 20px;
    border-bottom: 1px solid #000000;
    border-bottom: 1px solid var(--black);
}

.admin_canvas_row {
    padding-top: 44px;
}

.admin_canvas_div {
    /* padding: 0 20px 0px 40px !important; */
    border-right: 1px solid #E7E7E7;
    border-right: 1px solid var(--border-line);
}

.admin_canvas_div:nth-of-type(3) {
    border-right: none;
}

/* .admin_canvas_content {
    max-width: 200px;
} */

.admin_canvas_content_padding {
    padding-bottom: 5px;
}

.admin_canvas_img {
    /* background-image: url(../images/akal_images/images/admin_canvas_img.png); */
    width: 113px;
    height: 109px;
    object-fit: cover;
    background-repeat: no-repeat;
}

.admin_canvas_styles {
    background-color: #FBFBFB;
    height: 100vh;
    overflow: auto;
}

.admin_canvas_styles_list:not(:last-child) {
    padding: 20px;
    border-bottom: 1px solid #d4d4d4;
    border-bottom: 1px solid var(--box-border);
}

.admin_canvas_detail_header {
    align-items: center;
    padding: 20px 90px;
    border-bottom: 0.3px solid #737373;
    border-bottom: 0.3px solid var(--text-color);
}

.admin_canvas_search {
    background-image: url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/admin/admin_search_bg.png);
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    /* opacity: 0.5; */
    background-color: #000000;
    background-color: var(--black);
}

.admin_canvas_search_div {
    height: 51px !important;
    width: 350px !important;
    background-color: #FFFFFF;
    background-color: var(--white);
    border: 1px solid #FFFFFF !important;
    border: 1px solid var(--white) !important;
    padding: 8px 17px !important;
    border-radius: 0px !important;
    color: var(--secondary_text) !important;
    font-size: 13px !important;
}

.admin_canvas_search_input {
    border: none !important;
}

.admin_canvas_btn {
    padding: 0px !important;
}

.admin_canvas_row_gap {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
}

.admin_canvas_col-4_padding {
    padding-left: 20px !important;
}


.admin_canvas_div .media .pi_container .custom_shirt {
    width: 20%;
    /* left: 3%; */
    position: absolute;
    top: -15px !important;
    bottom: 0;
    margin: 0 !important;
    /* margin: auto; */
}

.admin_canvas_view_btn {
    height: 90px;
}

/* ----- admin canvas end ----- */

/* ----- chatbot start ----- */

.brevo-conversations--side-bottom {
    bottom: 67px !important;
}


.h-35 {
    height: 35px !important;
}

/* ----- chatbot end ----- */

@media screen and (min-width: 2500px) and (min-height: 1200px) {

    .canvas_style_prev,
    .canvas_style_prev:active {
        width: 40px !important;
    }
}

@media (min-width: 993px) and (max-width: 1199px) {

    .footer_akal_gap {
        grid-gap: 32px;
        gap: 32px;
    }
}

@media (max-width: 1024px) {
    .style_banner_content {
        bottom: 22vh;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
        border: none !important;
    }


    .footer_below_akal {
        flex-direction: column;
    }

    .text_nowrap {
        text-wrap: wrap;
    }

    .footer_style_wrap {
        text-wrap: nowrap;
    }

    .style_section {
        grid-gap: 130px;
        gap: 130px;
    }

    .style_section_border_both {
        width: 550px;
    }

    .style_section_border {
        width: 550px;
    }

    .style_section_text_color {
        text-wrap: nowrap;
    }

    .offer_section_right {
        padding-left: 55px;
    }

    .offer_section_left {
        width: 100%;
    }

}

@media (max-width: 992px) {

    .button-design {
        padding-top: 18rem;
    }

    .w-500 {
        width: 100%;
    }

    .cart-checkout-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #FFFFFF;
        background-color: var(--white);
        padding: 10px 24px;
        z-index: 1;
    }

    .cart-price-items {
        padding-top: 32px;
    }

    .cart-align-mobile {
        padding-bottom: 120px;
    }

    .cart-product-alignment {
        padding: 40px 0 !important;
    }

    .header-menu-align {
        grid-gap: 49px;
        gap: 49px;
    }

    .our_story_banner {
        height: 350px;
    }

    .container {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .carousel-indicators {
        display: none !important;
    }

    .carousel-control-next-icon {
        /* background-image: url(../images/right-arrow1.png) !important; */
        background-image: url(/static/media/canvas_arrow.9d3b845f.svg) !important;
    }

    .carousel-control-prev-icon {
        /* background-image: url(../images/left-arrow.png) !important; */
        background-image: url(/static/media/canvas_arrow.9d3b845f.svg) !important;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

    .mobile-img-hide {
        display: none !important;
    }

    .header_popup_left {
        padding: 0 24px;
    }

    .style_banner {
        background: url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/mobile_style_img.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 635px;
    }


    .mobile-header {
        position: relative;
    }

    .footer_akal_gap {
        display: flex;
        flex-direction: column-reverse;
        grid-gap: 44px;
        gap: 44px;
        padding-bottom: 44px !important;
    }

    .footer_accordion {
        width: 100% !important;
    }

    .line_border_accordion {
        border-top: 1px solid #686868 !important;
        padding: 7px 0;
    }

    .line_border_accordion:last-child {
        border-bottom: 1px solid #686868 !important;
    }

    .footer_accordion .pdp_accordion_btn::after {
        visibility: visible;
        width: 21px !important;
        height: 21px !important;
    }

    .footer_accordion .pdp_accordion_btn:not(.collapsed):after {
        -webkit-transform: rotate(45deg) !important;
                transform: rotate(45deg) !important;
        transition: .4s;
    }

    .category_content,
    .category_content_2_items .category_1_2,
    .category_content_2_items .category_2_2 {
        width: 100% !important;
    }

    .home_section_names {
        text-wrap: nowrap;
        left: 700px !important;
        bottom: 20px;
    }

    .mobile_hidden {
        display: none;
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 44px 0px !important;
    }

    .offer_section_right {
        padding-left: 0px;
    }

    .style_section {
        flex-direction: column;
        padding: 24px 24px 60px 24px !important;
        margin-top: 24px;
        margin-bottom: 60px;
        grid-gap: 44px;
        gap: 44px;
    }

    .style_section_border {
        width: 100%;
    }

    .style_section_border_both {
        width: 100%;
    }

    .mobile_style_heading {
        font-size: 64px;
    }

    .mobile_style_names {
        font-size: 24px;
    }

    .style_section_text_color {
        font-size: 14px;
    }

    .plp_last_section_left {
        padding: 40px 40px 90px 40px;
    }

    .profile-tab-align {
        grid-gap: 24px;
        gap: 24px;
    }

    .footer_style_advice_section_content {
        padding: 32px 0px 32px 0px;
    }

    .get_more_section2 {
        flex-direction: column;
        padding: 24px 0px 90px 0px;
        /* margin-top: 24px; */
        /* margin-bottom: 20px; */
    }

    .get_more_left2 {
        width: auto;
    }

    .get_more_left2_heading {
        font-size: 34px;
    }

    .get_more_left_img2 {
        height: auto;
    }

    .get_more_right2 {
        flex-direction: column;
        grid-gap: 44px;
        gap: 44px;
        padding-top: 0px;
    }

    .get_more_right_section2 {
        align-self: flex-end;
    }

    .offer_section_left {
        width: 100%;
        border-right: 0px;
        margin-bottom: 54px !important;
    }

    .offer_section {
        flex-direction: column;
        padding: 44px 24px;
        margin: 44px 0px;
    }

    .suit_section {
        flex-direction: column-reverse;
    }

    .get_more_section {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column;
        padding: 44px 24px;
        /* margin: 44px 0px; */
    }

    .get_more_center_section {
        width: 100%;
        flex-direction: column-reverse;
    }

    .get_more_right_section {
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
    }

    .get_more_right_section_img_div {
        align-self: flex-end;
    }

    .mobile_get_more_section_reverse {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column-reverse;
    }

    .cart-left,
    .cart-right {
        padding: 0px !important;
        border: 0px !important;
    }

    .cart-quantity-border {
        border: 0.5px solid #d4d4d4;
        border: 0.5px solid var(--box-border);
        padding: 4px 13px !important;
        grid-gap: 10px;
        gap: 10px;
    }

    .fabric-text {
        max-width: 130px;
        font-size: 14px !important;
    }

    .subtotal-text {
        font-size: 14px;
    }

    .cart-gap {
        grid-gap: 11px;
        gap: 11px;
    }

    .cart-img img {
        width: 100px;
        height: 90px;
    }

    .header_popup_right {
        width: 100%;
        padding: 0 24px;
    }

    .web_popup {
        padding: 0 0 24px;
    }

    .web_popup_hidden {
        display: block;
    }

    .header_popup_menu {
        font-size: 20px !important;
        border-bottom: 0.5px solid #BBBBBB;
        padding: 20px 0px;
    }

    .header_popup_dropdown_menu {
        font-size: 20px !important;
    }

    .header_popup_menu_dropdown {
        padding: 12px 36px;
    }

    .copyrights_web_popup {
        align-self: flex-start;
    }

    .web_popup_logo {
        padding: 24px 24px 16px 24px;
    }

    .get_more_center_section_img {
        object-fit: cover;
    }

    .get_more_center_section_img_div {
        width: 100%;
        background-color: #EAEAE4;
    }

    .mobile_footer_logo {
        width: 90px;
    }

    .footer_logo {
        display: block;
        margin: auto;
    }

    .profile-order-status {
        padding: 0 !important;
    }

    .ordered_products {
        padding-top: 8px !important;
        padding-bottom: 44px;
    }

    .data-divider {
        padding-bottom: 32px;
    }

    .product_detail_page {
        flex-direction: column-reverse;
    }


    .PDP_mobile_fabric_p {
        padding: 20px 0px 10px 0px !important;
        font-size: 25px !important;
    }

    .PDP_fabric_img {
        width: 100%;
        flex-direction: column;
    }

    .detail_page_img {
        width: 100% !important;
        padding: 0px !important;
    }

    .detail_page_img_col {
        align-items: center;
        width: 100% !important;
    }

    .PDP_content_part {
        padding-bottom: 64px;
    }

    .PDP_fabric_img_col {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: fill;
    }

    .PDP_fabric_col_img {
        height: 440px;
        width: 100%;
    }

    .mobile_display {
        display: none;
    }

    .row_margin_top {
        padding-bottom: 64px;
    }

    .detail_first_img {
        width: 100%;
        height: 50% !important;
    }

    .PDP_container_mobile {
        padding: 0px 24px !important;
    }

    .PDP_container {
        padding: 0px 0px !important;
    }

    .mobile_pt-44 {
        padding-top: 44px;
    }

    .carousel_canvas .mobile_canvas_part {
        height: 52dvh !important;
    }

    .carousel_canvas .mobile_canvas_content {
        height: 40dvh !important;
    }

    .carousel_canvas {
        height: calc(100svh - 68.67px) !important;
    }

    .filter-mobile-icon {
        display: block !important;
    }

    .canvas_contents,
    .canvas_headings {
        width: 100% !important;
        background-color: #FFFFFF !important;
        background-color: var(--white) !important;
    }

    .canvas_headings {
        position: absolute;
        bottom: 0;
        height: auto !important;
    }

    .canvas_headings_align {
        grid-gap: 5px;
        gap: 5px;
        flex-direction: row;
    }

    .canvas_headings .head_detail {
        padding: 12px 10px;
    }

    .canvas_headings_align {
        padding: 0 18px !important;
    }

    .canvas_padding,
    .canvas-data-hide {
        display: none !important;
    }

    .canvas-menu-divider:not(:last-child) {
        border-bottom: 0px;
    }

    .canvas_details_icons .icons_name {
        width: 31px;
        height: 31px;
        padding: 6px;
    }

    .canvas_details_icons {
        bottom: 4vh;
        right: 13px;
        grid-gap: 5px !important;
        gap: 5px !important;
    }

    .canvas_top_btns {
        padding: 10px 17px !important;
    }

    .canvas_top_section {
        padding: 20px 20px 10px !important;
    }

    .canvas-text-color {
        font-size: 12px !important;
    }

    .fabric_icon {
        width: 24px !important;
    }

    .canvas-finish {
        display: block;
        position: absolute;
        top: 12px;
        right: 25px;
        z-index: 9;
        background-color: #FFFFFF;
        background-color: var(--white);
        padding: 5px 15px;
    }

    .canvas_close_btn {
        padding-top: 50px !important;
    }

    .fabric_detail_popup_col {
        height: 50%;
    }

    /* .canvas_content_scroll {
        height: calc(45% - 59.33px);
        overflow: auto;
        padding-bottom: 14vh;
    } */

    .header_nav {
        border-bottom: 0.5px solid #F0F0F0;
        border-bottom: 0.5px solid var(--bg_color);
        padding: 0px !important;
    }

    .mobile-menu {
        height: 8dvh;
    }

    .mobile_hidden {
        display: none;
    }

    .header_cart_img {
        width: 21px;
        height: 21px;
    }


    .home_section_names {
        text-wrap: nowrap;
        left: 533px !important;
        bottom: 40px;
    }

    .suit_section {
        flex-direction: column-reverse;
    }

    .suit_blazer_img_section {
        height: auto;
    }

    .home_img_section {
        width: 100%;
        height: auto;
    }

    .get_more_section {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column;
        padding: 44px 24px;
        /* margin: 44px 0px; */
    }

    .get_more_left_section_img {
        object-fit: cover;
        height: auto;
    }

    .get_more_right_section {
        /* align-items: flex-end; */
        width: 100%;
        flex-direction: column-reverse;
        height: auto;
    }

    .get_more_center_section {
        width: 100%;
        flex-direction: column-reverse;
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 44px 0px;
    }

    .mobile_offer_80 {
        font-size: 54px;
    }

    .style_section {
        flex-direction: column;
        padding: 24px 24px 60px 24px !important;
        grid-gap: 44px;
        gap: 44px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .style_section_border {
        width: 100%;
    }

    .style_section_border_both {
        width: 100%;
    }

    .mobile_style_heading {
        font-size: 64px;
    }

    .mobile_style_names {
        font-size: 24px;
    }

    .style_section_text_color {
        font-size: 14px;
    }

    .get_more_left2 {
        width: auto;
    }

    .get_more_left_img2 {
        height: auto;
    }

    .get_more_right2 {
        flex-direction: column;
        grid-gap: 44px;
        gap: 44px;
        padding-top: 0px;
    }

    .get_more_right_section2 {
        align-self: flex-end;
    }

    .footer_style_advice_section_content {
        padding: 30px 0px;
    }

    .footer_style_advice_section .col-lg-4 {
        padding-right: 0px !important;
    }

    .mobile_footer_hidden {
        display: none;
    }

    .footer_shop_by {
        padding: 24px 0px;
        border-top: 1px solid #4D4D4D;
        border-bottom: 1px solid #4D4D4D;
    }

    .padding_gaps {
        margin-top: 24px;
    }

    .padding_gaps_top_bottom {
        padding-top: 24px;
        /* padding-bottom: 40px; */
    }

    .plp_header {
        padding: 0px 24px;
    }

    .plp_para {
        align-items: normal;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
    }

    .plp_para_reverse {
        flex-direction: column-reverse;
        align-items: normal;
        grid-gap: 20px;
        gap: 20px;
    }

    .para_width {
        text-align: left;
        font-size: 28px;
    }

    .para_width2 {
        width: auto;
        text-align: left;
    }

    .para_width3 {
        font-size: 16px;
    }

    .plp_right_icon {
        width: 16px;
    }

    .w-51 {
        width: 100% !important;
    }

    .w-33 {
        width: 100% !important;
    }

    .img_section {
        flex-direction: column;
    }

    .sort_section {
        padding: 0px 44px;
    }

    .sort_section_list {
        grid-gap: 17px;
        gap: 17px
    }

    .plp_looks_section {
        padding-top: 0px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 60px !important;
    }

    .mobile_plp_looks_section_header {
        font-size: 34px;
    }

    .plp_last_section_left {
        grid-gap: 64px;
        gap: 64px;
        padding: 40px 24px 90px 24px;
        width: 100%;
        flex-direction: column-reverse;
    }

    .plp_last_section_content {
        flex-direction: column-reverse;
    }

    .plp_last_section_right {
        width: 100%;
    }

    .plp_last_section_img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .mobile_padding_gaps_category_list {
        padding-top: 0px;
    }

    .footer_newsletter {
        flex-direction: column;
        grid-gap: 32px;
        gap: 32px;
        border-bottom: 0px solid #E8E8E8 !important;
    }

    .footer_newsletter_content {
        padding: 0px 0px;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
        border: none !important;
    }

    .mobile_login {
        flex-direction: column;
    }

    .login_img_section {
        width: 100%;
    }

    .login_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .login_content_section {
        position: relative;
        width: 100%;
        padding: 90px 90px;
    }

    .offer_section_left {
        width: 100%;
        border-right: none;
    }

    .get_more_left_section {
        height: auto;
    }

    .get_more_center_section_img_div {
        width: 100%;
        background-color: #EAEAE4;
        /* height: auto; */
    }

    .get_more_right_section_img_div {
        width: 256px;
        background-color: #EAEAE4;
        height: auto;
    }

    .get_more_center_section_img {
        object-fit: cover;
        /* height: auto; */
    }

    .mobile_get_more_section_reverse {
        grid-gap: 44px;
        gap: 44px;
        flex-direction: column-reverse;
    }

    .sort_btn {
        padding: 0px 12px !important;
    }

    .search_mobile_hide,
    .sort_btn {
        display: none !important;
    }



    .gap-align {
        grid-gap: 10px;
        gap: 10px;
    }

    .carousel-head {
        font-size: 24px;
    }

    .carousel-subtext {
        font-size: 12px;
        max-width: 240px;
    }

    .banner-slider-line-img {
        width: 39px;
        height: 2px;
        background-color: #FFFFFF;
        background-color: var(--white);
    }

    .canvas_content_scroll,
    .measure_contents {
        height: calc(42vh - 70px);
        overflow: auto;
        padding-bottom: 14vh;
    }

    .canvas_bottom_btns {
        display: none;
    }

    .admin_canvas_row {
        padding-top: 0px;
    }

    .admin_canvas_div {
        padding: 20px 20px 20px 20px !important;
        border-right: none;
        border-bottom: 1px solid #E7E7E7;
        border-bottom: 1px solid var(--border-line);
    }

    .admin_canvas_div:last-child {
        border-right: none;
        border-bottom: none;
    }

    .admin_canvas_fs_mobile {
        font-size: 12px !important;
    }

    /* .close-search {
        display: block;
    } */

    .cart_header_icon {
        left: -9px;
    }

    .cart-count-default {
        top: -4px;
    }

    .cart-count-scroll {
        top: -1px;
    }

    .mobile_admin_canvas_detail_meas {
        padding-left: 24px !important;
        padding-bottom: 20px !important;
    }

    .admin_canvas_styles {
        overflow: hidden;
        height: auto;
    }

    .mobile_admin_canvas_header {
        position: relative;
        left: -50px;
    }

    .admin_canvas_img_height {
        height: 600px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 24%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

    .corporate-align {
        left: 0px;
    }

    .canvas_style_prev,
    .canvas_style_prev:active,
    .canvas_style_prev:focus {
        width: 80px !important;
    }

}

@media (max-width: 912px) {
    .admin_canvas_img_height {
        height: 600px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 820px) {
    .admin_canvas_img_height {
        height: 520px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 770px) {
    .admin_canvas_img_height {
        height: 470px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 11%;
        /* left: 3%; */
        position: absolute;
        top: -33px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }
}

@media (max-width: 540px) {
    .home_section_names {
        text-wrap: nowrap;
        left: 350px !important;
        bottom: 25px;
    }

    .padding_gaps {
        margin-top: 24px;
    }

    .padding_gaps_top_bottom {
        padding-top: 24px;
        /* padding-bottom: 24px; */
    }

    .plp_header {
        padding: 0px 24px;
    }

    .plp_para {
        align-items: normal;
        flex-direction: column;
        grid-gap: 12px;
        gap: 12px;
    }

    .plp_para_reverse {
        flex-direction: column-reverse;
        align-items: normal;
        grid-gap: 20px;
        gap: 20px;
    }

    .para_width {
        text-align: left;
        font-size: 28px;
    }

    .para_width2 {
        width: auto;
        text-align: left;
    }

    .para_width3 {
        font-size: 16px;
    }

    .plp_right_icon {
        width: 16px;
    }

    /* .web_sort_section {
        display: none !important;
    } */

    .mobile_sort_section {
        display: block !important;
    }

    .mobile_sort_section_content {
        padding: 6px 24px;
    }

    .mobile_filter {
        width: 50%;
        padding: 12px 14px;
        border: 0.5px solid #AFAFAF;
    }

    .mobile_sort {
        width: 50%;
        padding: 12px 14px;
        border: 0.5px solid #AFAFAF;
    }

    .w-51 {
        width: 100% !important;
    }

    .w-33 {
        width: 100% !important;
    }

    .img_section {
        flex-direction: column;
    }

    /* .padding_gaps_img_section {
        padding-top: 28px;
    } */

    .mobile_padding_gaps_img_section {
        padding-top: 0px;
    }

    /* .padding_gaps_img {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 32px;
    } */
    .padding_gaps_img {
        padding-bottom: 25px;
    }


    .plp_looks_section {
        padding-top: 60px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 60px !important;
    }

    .mobile_plp_looks_section_header {
        font-size: 34px;
    }

    .plp_last_section_left {
        grid-gap: 64px;
        gap: 64px;
        padding: 40px 24px 90px 24px;
        width: 100%;
        flex-direction: column-reverse;
    }

    .plp_last_section_content {
        flex-direction: column-reverse;
    }

    .plp_last_section_right {
        width: 100%;
    }

    .plp_last_section_img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .mobile_padding_gaps_category_list {
        padding-top: 0px;
    }

    .max_width_content {
        max-width: 249px;
    }

    .mobile_popup_height {
        grid-gap: 44px;
        gap: 44px;
    }

    .footer_popup {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        padding: 70px 32px;
        color: #000000 !important;
        color: var(--black) !important;
    }

    .login_content_section {
        position: relative;
        width: 100%;
        padding: 80px 24px;
    }

    .style_section_border_both {
        padding: 16px 16px 16px 16px;
    }

    .style_section_border {
        padding: 16px 16px 16px 16px;
    }

    .style_see_collection_section {
        grid-gap: 16px !important;
        gap: 16px !important;
    }

    .get_more_right2_content {
        max-width: 230px;
    }

    .get_more_right_section_data {
        max-width: 242px;
    }

    .admin_canvas_row_gap {
        grid-row-gap: 1rem !important;
        row-gap: 1rem !important;
    }

    .admin_canvas_img_height {
        height: 330px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 15%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

    .admin_canvas_search_div {
        width: 100% !important;
    }

    .admin_canvas_search {
        /* background-image: url(../images/akal_images/images/admin_search_mobile_img.png); */
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/admin/admin_search_mobile_img.png);
        height: 100vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        /* opacity: 0.5; */
        /* background-color: var(--black); */
    }

    .offer_section {
        /* flex-direction: column; */
        padding: 44px 24px;
        margin: 0px 0px !important;
    }

    .get_more_right_section_img_div {
        width: 200px;
        background-color: #EAEAE4;
        height: auto;
    }

    .get_more_center_section {

        height: 100%;
    }

    .mobile_flex {
        flex-direction: column;
    }
}

@media (max-width: 430px) {

    .home_section_names {
        left: 247px !important;
        bottom: 20px;
    }

    /* .admin_canvas_div .col-8 {
        padding-left: 35px;
    } */
    .admin_canvas_img_height {
        height: 400px;
    }

    .admin_canvas_div .media .pi_container .custom_shirt {
        width: 22%;
        /* left: 3%; */
        position: absolute;
        top: -22px !important;
        bottom: 0;
        margin: 0 !important;
        /* margin: auto; */
    }

}

@media (max-width: 380px) {
    .home_section_names {
        left: 212px !important;
        bottom: 20px;
    }

    .max_width_content {
        max-width: 200px;
    }

    .admin_canvas_img_height {
        height: 300px;
    }

}

@media (max-width: 360px) {

    .admin_canvas_img_height {
        height: 350px;
    }

    .canvas_details_icons {
        right: 11px !important;
    }

    .canvas-finish {
        right: 13px;
    }

}

@media (min-width: 993px) {

    .canvas_headings_align {
        grid-gap: 5px;
        gap: 5px;
        flex-direction: column;
    }

    .collapse_show,
    .profile_offcanvas {
        display: block !important;
    }

    .mobile_canvas_part {
        width: 60% !important;
    }

    .mobile_canvas_content {
        width: 40% !important;
    }

    .carousel-indicators {
        display: flex !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        display: none !important;
    }
}

@media (min-width:1024px) {

    .para_width {
        max-width: 512px !important;
        text-align: right;
    }

    .style_banner_content {
        bottom: 35vh;
    }

}

@media (min-width:1440px) {

    .carousel-head {
        font-size: 44px;
    }

    .carousel-subtext {
        font-size: 15px;
        max-width: 540px;
    }

    .cart-left {
        padding: 0 40px 0 0 !important;
    }

    .cart-right {
        padding: 0 0 0 72px !important;
        border-left: 1px solid #EFEFEF;
        border-left: 1px solid var(--cart-divider);
    }

}
