@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');

body {
  font-family: 'Roboto Condensed', sans-serif;
}

#banner_slider {
  position: relative;
  top: 0px;
  z-index: 0;
}

.sc_m ul {
  padding-left: 0;
  text-align: center;
}

.megamenu ul li {
  display: block !important;
}

.font_48 {
  font-size: 48px;
}

#menu_desk {
  box-shadow: 4px 3px 4px rgb(208 208 208 / 50%);
  padding-bottom: 1px;
  /* position: sticky; */
  top: 0;
  z-index: 10;
  background: #fff;
  padding-top: 0px;
}

#banner_slider .carousel-inner {
  background: #f9fbff;
}

.sc_m ul li {
  display: inline-block;
  padding: 5px 5px;
}

.r_lg {
  text-align: right;
}

.car_t li input {
  width: 200px;
  border-radius: 50px;
  height: 30px;
  padding: 0px 14px;
}

ul.car_t li {
  display: inline-block;
  padding: 4px 3px;
}

ul.car_t {
  padding-left: 0;
  float: right;
  margin-right: 0;
}

.menu2 ul li a {
  font-size: 18px;
  color: #283941;
  font-weight: 500;
}

.menu2 ul {
  padding-left: 0;
  z-index: 1000;
}

.menu2 ul li {
  display: inline-block;
  padding: 6px 14px;
}

.megamenu.show {
  display: block !important;
}

.second-menu .megamenu.show {
  transform: translate3d(0px, 41px, 0px) !important;
}

.second-menu .dropdown-menu {
  /* min-width: 84.3rem; */
  border-bottom: 1px solid #ccc;
}

.img-list-menu li {
  display: inline-block;
  float: left;
  width: 30%;
}

.tal_img {
  position: absolute;
}

.min-header {
  background: #283941;
}

.min-header p,
.min-header ul {
  margin-bottom: 0;
}

.l_ft p {
  font-size: 14px;
}

.sc_m ul li a,
.r_lg p a,
.l_ft p,
.r_lg p span {
  color: #fff;
}

.fa-fw.fa-eye,
.fa-fw.fa-eye-slash {
  position: relative;
  left: -10px;
  float: right;
  top: -40px;
}

.sign-cs {
  top: -28px !important;
}


.menu1 ul.car_t {
  margin-bottom: 0;
}

.menu1 ul.car_t li {
  padding: 5px 8px;
}

.menu1 ul.car_t li a i {
  font-size: 22px;
}

.menu1 ul.car_t li a {
  color: #283941;
}

.menu1 ul.car_t li span i {
  font-size: 22px;
}

.menu1 ul.car_t li span {
  color: #283941;
}

#banner_slider .carousel-caption h5 {
  font-size: 35px;
  font-weight: 400;
  color: #484848;
}

#banner_slider .carousel-caption {
  text-align: left;
  bottom: 35%;
}

#banner_slider .carousel-caption button {
  border: 1px solid #484848;
  color: #484848;
  border-radius: 50px;
  margin-top: 20px;
}

.collections .row {
  padding: 75px 15px;
}

.collections .card {
  border: 0;
}

.collections .card h6 a {
  font-size: 20px;
  color: #000000;
}

.collections .card h6 {
  padding: 15px 15px;
  text-align: center;
}

.vir_appoin p {
  margin-top: 50px;
}

.vir_appoin p a {
  background: #283941;
  padding: 12px 25px;
  border-radius: 50px;
  color: #fff;
}

.img-collection {
  max-width: 200px;
}

.collections .card h6 a span {
  position: relative;
  right: -20px;
  color: #b18e61;
}

.tal_img {
  position: absolute;
  max-width: 320px;
  left: calc(15px - -3%);
  bottom: 0;
}

.how h2,
.test_i h2,
.s_heading h2,
.mail_letter h2,
#loginpop h2 {
  text-align: left;
  padding: 20px 0 0;
  color: #283941;
  font-weight: 300;
  font-size: 32px;
  font-family: "Roboto Condensed", sans-serif;
}

.s_heading+.table {
  font-family: "Roboto Condensed", sans-serif;
}

.how h2::after,
.test_i h2::after,
.s_heading h2::after,
.mail_letter h2:after,
#loginpop h2::after {
  content: "";
  display: none;
  height: 20px;
  /* background-image: url(../images/border-bttom.png); */
  background-repeat: no-repeat;
  background-position: center bottom;
}

.how .card h3 {
  color: #283941;
  font-size: 20px;
}

.how .card img {
  max-width: 35px;
  margin-bottom: 15px;
}

.how .card {
  border: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  background: transparent;
}

.how .card h5 {
  margin-bottom: 0;
  font-size: 18px;
  color: #7c7c7c;
  font-weight: 400;
}

.how .card p {
  font-size: 15px;
  font-weight: 400;
  padding-top: 8px;
}

.how {
  position: relative;
  background: #f9fbff;
  /* background-image: url(../images/brand-bg.png); */
  background-position: 73% 0px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0;
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50%;
  border: 3px solid #dedede;
  background: #333333;
  list-style-type: none !important;
  cursor: pointer !important;
}

button.owl-next span,
button.owl-prev span {
  position: relative;
  top: -13px;
}

button.owl-next:focus,
button.owl-prev:focus {
  outline: 0 !important;
}

button.owl-next {
  position: absolute;
  right: 0;
  top: calc(100% - 50%);
  background: #ccc !important;
  width: 50px;
  height: 50px;
  font-size: 50px !important;
  color: #fff !important;
}

button.owl-prev {
  position: absolute;
  left: 0;
  top: calc(100% - 50%);
  background: #ccc !important;
  width: 50px;
  height: 50px;
  font-size: 50px !important;
  color: #fff !important;
}

.fabric .carousel-indicators li {
  width: 15px;
  height: 15px;
}

.carousel-indicators .active {
  opacity: 1;
  border: 3px solid #fff;
}

.cus_btn {
  background: rgb(108 125 32);
  color: #fff;
  border-radius: 50px;
}

.how .cus_btn {
  background: #17303a;
}

.btn.h_cus {
  background: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  display: block;
  margin-bottom: 10px;
  width: 200px;
  box-shadow: 3px 3px 8px rgb(107 107 107 / 50%);
  transition: 0.3s;
}

.collections .card h6 a:hover {
  color: #283941;
  text-decoration: none;
}

.cus_btn:hover {
  background: #c28136;
  color: #fff;
  transition: 0.3s;
}

.me_sr h3 {
  color: #b18e61;
}

.me_sr h3 {
  color: #b18e61;
  line-height: 1.5;
  letter-spacing: 1.5px;
  font-weight: 700;
}

.me_sr p {
  padding: 12px 0px;
  color: #828282;
}

.me_sr .card {
  border: 0;
}

.me_sr .card {
  border: 0;
  padding: 50px 5px;
}

.m_p {
  max-width: 450px;
  margin: 0 auto;
}

.me_sr .card p {
  font-size: 14px;
  color: #828282;
}

.me_sr {
  padding: 50px 0;
}

.test_i h4 {
  padding: 50px 20px 0px 20px;
}

.test_i .star {
  padding: 50px 20px;
}

.test_i h6 {
  padding: 20px 30px;
  border-bottom: 1px solid #9f9f9f;
}

.test_i .carousel-control-prev-icon,
.test_i .carousel-control-next,
#banner_slider .carousel-control-prev-icon,
#banner_slider .carousel-control-next {
  display: none;
}

.test_i .carousel-indicators .active {
  opacity: 1;
  border: 3px solid #000;
}

.test_i {
  position: relative;
  background: #f1f1f1;
  /* background-image: url(../images/brand-bg.png); */
  background-position: -42% 0;
  background-repeat: no-repeat;
  /* background-size: cover; */
  margin: 50px 0;
}

.sho_p ul.nav-tabs {
  max-width: 768px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
}

.sho_p ul.nav-tabs li {
  text-align: center;
  display: block;
  align-items: center;
  padding: 5px 20px;
}

.sho_p ul.nav-tabs li a.active {
  border-bottom: 1px solid #333;
}

.sho_p ul.nav-tabs li a {
  border: 0;
}

.sho_p .tab-pane {
  padding: 50px 0;
}

.sho_p .tab-pane .card h4,
.sho_p .tab-pane .card h5 {
  margin: 2px 8px;
  font-size: 17px;
  font-weight: 400;
  color: #808080;
}

.sho_p .tab-pane .card p button {
  border: 1px solid #333;
  margin: 10px 8px;
}

.sho_p .tab-pane .card p {
  margin-bottom: 0;
}

.sho_p .tab-pane .card {
  border: 0;
}

.sho_p .tab-pane .card p button:hover {
  background: #333;
  color: #fff;
}

.vi_btn h6 {
  margin-top: 50px;
}

.vi_btn h6 a {
  background: rgb(108, 125, 32);
  color: #fff;
  padding: 8px 25px;
  border-radius: 50px;
  box-shadow: 1px 2px 6px rgb(126 126 126 / 60%);
}

.vi_btn h6 a:hover {
  background: #c47d3a;
  text-decoration: none;
  transition: 0.3s;
}

.mail_letter p {
  font-size: 16px;
  font-weight: 400;
}

.mail_letter .input-group input {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.mail_letter .input-group {
  max-width: 450px;
  margin: 0 auto;
}

.mail_letter .input-group-append .btn {
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.footer {
  background: #f8f8f8;
  padding: 25px 0;
  text-align: center;
}

.footer .col-lg-3 {
  text-align: left;
  padding: 5px 51px;
}

ul.fabric_lft#fab-font-ht {
  height: 53vh;
  overflow: auto;
}

.fabric_lft.fabric_style#fabric_style {
  height: 53vh;
  overflow: auto;
}

.footer h4 {
  color: #283941;
  margin-bottom: 25px;
  font-weight: 300;
}

.footer ul li {
  padding: 6px 20px;
  list-style-type: none;
}

.footer ul {
  padding-left: 0;
}

.foot ul {
  margin-bottom: 0;
  text-align: right;
}

.foot ul li {
  display: inline-block;
}

.foot h6 {
  color: #283941;
  font-size: 14px;
  text-align: right;
}

.foot ul li a i {
  background: white;
  padding: 7px 7px;
  border-radius: 50px;
  box-shadow: 2px 1px 0px rgb(179 179 179 / 50%);
  color: #283941;
  transition: 0.3s;
}

.footer ul li a:hover {
  list-style-type: none;
  text-decoration: none;
  color: #000;
}

.foot .fa-facebook-f {
  padding: 7px 10px;
}

.footer ul li a {
  color: #333;
  font-weight: 400;
}

.foot ul li a i:hover {
  background: #283941;
  color: #fff;
  transition: 0.3s;
}

.btn.h_cus:hover {
  background: #6c7d20;
  color: #fff;
  transition: 0.3s;
}

.megamenu {
  width: 100%;
  border: 0;
  border-radius: 0;
}

.megamenu ul li a {
  font-weight: 400;
}

/* inner pages */
.side_banner {
  /* background: url(../images/banner_1.png); */
  background-size: cover;
  background-position: center;
}

.s_blazer {
  /* background: url(../images/banner_3.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.pant {
  /* background: url(../images/banner_2.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.suit_ban {
  /* background: url(../images/banner_4.png); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.side_banner .i_banner {
  padding: 195px 120px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.s_blazer h1,
.s_blazer p {
  color: #333 !important;
}

.s_blazer button {
  background: #929292;
  border: 0;
  padding: 5px 13px;
  border-radius: 24px;
  color: #333;
}

.side_banner button {
  background: #fff;
  border: 0;
  padding: 5px 13px;
  border-radius: 24px;
}

.i_banner p {
  font-size: 20px;
  color: #dbdbdb;
}

.h_d_y_s .card img {
  max-width: 75px;
}

.h_d_y_s .card img {
  max-width: 88px;
  width: 101px;
  height: 100px;
  margin: 0 auto;
}

.h_d_y_s .card {
  text-align: center;
  /* min-height: 240px; */
  border: 0;
}

.dgn_btn button:nth-child(2) {
  border: 0;
  background: transparent;
  margin-left: 20px;
}

.dgn_btn button:first-child {
  border: 1px solid #333;
  border-radius: 50px;
  padding: 5px 25px;
}

.h_d_y_s {
  margin: 20px 0;
}

.prdut_list .card p {
  margin-bottom: 0;
  font-size: 14px;
}

.prdut_list .card p span {
  color: #867e7e;
  font-weight: 400;
}

.prdut_list .card h5 {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 1px;
}

.prdut_list .card ul li button:hover,
.suit_banner .card ul li button:hover {
  background: white;
  transition: 0.3s;
  border: 1px solid #333;
  color: #333;
}

.prdut_list .card ul li button,
.suit_banner .card ul li button {
  border: 0;
  background: #6c7d20;
  color: #fff;
  border: 1px solid transparent;
  transition: 0.3s;
}

.prdut_list .card ul,
.suit_banner .card ul {
  padding-left: 0;
}

.prdut_list .card ul li:nth-child(3) button,
.suit_banner .card ul li:nth-child(3) button {
  background: #fff !important;
  color: #2b2b2b;
  border: 1px solid #8e8e8e;
}

.prdut_list .card {
  border: 0;
  margin-bottom: 50px;
  height: 140%;
}

.prdut_list .card ul li,
.suit_banner .card ul li {
  display: inline-block;
}

.suit_banner .card p {
  padding: 25px 0px;
}

.suit_banner .card h1 {
  font-size: 35px;
  color: #b18e61;
  font-weight: 700;
}

.suit_banner .card h3 {
  color: #b18e61;
}

.suit_banner .card {
  padding: 18px 25px;
  border: 0;
}

button.close {
  position: absolute;
  top: 25px;
  right: 35px;
  z-index: 1000;
  padding: 0 !important;
}

#loginpop label {
  width: 100%;
  text-align: left;
}

/* #loginpop input {
  margin: 15px auto;
  width: 100%;
} */

#loginpop .custom-control.custom-switch {
  text-align: center;
  margin: 25px auto;
  width: 145px;
}

#loginpop {
  text-align: center;
}

.btn-blue {
  width: 100%;
}

.btn-blue {
  background: #264350;
  width: 95%;
  margin: 15px 0px;
}

.btn-red {
  background: #ff465f;
  width: 88%;
  margin: 15px 0px;
}

.btn-sky {
  background: #61b0ce;
  width: 88%;
}

.btn-sky,
.btn-red,
.btn-blue {
  margin: 15px 0px;
  color: #fff;
  padding: 5px 0;
}

.btn-sky:hover,
.btn-red:hover,
.btn-blue:hover {
  color: #fff !important;
}

.form {
  max-width: 900px;
  margin: 30px auto;
  background: #f8f8f8;
  padding: 40px;
  border-radius: 8px;
  font-family: "Roboto Condensed", sans-serif;
}

#m_sign_up ul {
  padding: 0;
}

#m_sign_up h3 {
  font-size: 32px;
}

#m_sign_up h5,
#m_sign_up ul li {
  letter-spacing: 1px;
  list-style-type: none;
}

ul.my_detas li {
  display: inline-block;
}

ul.my_detas li i {
  color: #5d5d5d;
  font-size: 12px;
  padding: 0px 8px;
}

ul.my_detas {
  padding: 0;
  text-align: left;
}

ul.my_detas li a {
  color: #717171;
  font-size: 14px;
}

.btn.btn-gren {
  background: #264350;
  color: #fff;
}

.c-measurements ul li.active {
  border-top: 48px solid #264350 !important;
}

.nos {
  margin: 20px 30px 0px 30px;
  padding: 20px 0px 5px 0px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #264350;
  background-color: #264350;
}

.scnd .form-group {
  margin-left: 15px;
}

.scnd .btn-gren {
  font-size: 14px;
  padding: 6px 30px;
  border-radius: 0;
}

#loginpop p a {
  color: #264350;
  font-weight: 500;
  font-size: 16px;
}

#loginpop .modal-content {
  border-radius: 0.8rem;
}

.o_list li a {
  text-decoration: underline;
  color: #333;
}

.o_list li {
  list-style-type: none;
}

.fabric_lft li span {
  font-size: 13px;
}

.fabric_lft li h6 {
  font-size: 13px;
  margin: 0;
  width: 75%;
  /* min-height: 30px; */
}

.fabric_lft li p {
  margin: 0;
  line-height: 1.3;
}

.fabric_lft li a img,
.fabric_lft li img {
  max-width: 60px;
}

.fabric_lft li {
  display: inline-block;
  text-align: left;
  width: 31%;
  padding: 10px 4px;
  margin: 3px 0px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .mess-modal .modal-body {
    height: 560px;
    overflow: auto;
  }
}

.fab-fnt li {
  width: 48%;
  padding: 4px 0;
  margin: 0;
}

.fa-shopping-bag {
  position: relative;
}

b.notifi {
  position: absolute;
  background: #fff;
  font-size: 10px;
  right: -13px;
  z-index: 0;
  border: 2px solid #707070;
  padding: 2px 7px;
  top: -15px;
  border-radius: 50px;
  color: #ee1c1c;
}

ul.fabric_lft {
  background: #ffffff;
  padding: 0px 5px;
}

ul.fabric_lft,
.fabric_list {
  padding: 0;
  text-align: center;
}

#nek1 {
  min-height: calc(100vh - 25vh);
}

/* .vest-cart .folded-pant img {
  top: calc(100% - 100%) !important;
} */

ul.fabric_lft.fabric_border {
  overflow-y: scroll;
  margin: 0;
  height: 50vh;
  padding: 0;
  overflow-x: hidden;
}

ul.fabric_lft.me_chest {
  overflow-y: scroll;
  margin: 0;
  height: 80%;
  padding: 0;
  overflow-x: hidden;
}

ul.fabric-splits .bg-success {
  background: #66771d !important;
  border: 0;
  border-radius: 0;
  width: 70px;
  font-size: 14px;
}

.sticky_arrow {
  position: sticky;
  top: -1px;
  left: 0px;
  width: 100%;
  z-index: 1000;
  background-color: white;
  height: 30px;
}

.w-90 {
  width: 83%;
  display: inline-block;
}

#fab-font-ht .w-90 {
  width: 90%;
}

#fab-font-ht label {
  display: block;
}

b.clse {
  cursor: pointer;
  margin: 0 8px;
}

ul.fabric-splits .input-group {
  width: 100%;
  padding: 7px 5px;
}

.fabr-header h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  padding: 8px 15px;
  background: #fff;
  margin: 0;
}

.fabr-header {
  background: #fff;
  padding-bottom: 1px;
  position: sticky;
  top: 0;
  box-shadow: none;
  border-bottom: 1px solid #d4d4d4;
  z-index: 5;
}

.meas_001 label {
  width: 100%;
  display: block;
  text-align: left;
  margin: 20px auto;
  font-size: 21px;
}

.meas_001 h2 {
  font-size: 48px;
  font-weight: 300;
}

form.meas_001 {
  background: #f1f1f1;
  padding: 50px;
}

#m_001 {
  margin-top: 47px;
  background: #264350;
  color: #fff;
}

.grub-btn .form-control:focus {
  outline: none;
  box-shadow: none;
}

.fabric_list li {
  display: inline-block;
  padding: 2px 15px;
}

.fabric_list+#carouselExampleIndicators ol.carousel-indicators {
  bottom: -10px;
}

.fabric_list+#carouselExampleIndicators .carousel-indicators li {
  border: 5px solid #fff;
}

.fabric_list+#carouselExampleIndicators .carousel-indicators .active {
  border: 5px solid #fff;
  box-shadow: 2px 2px 3px rgb(130 130 130 / 50%);
}

.fabric_list+#carouselExampleIndicators .carousel-inner img {
  /* max-width: 285px; */
  /* width: calc(100% - 55%); */
  margin: 0 auto;
}

.fabric_rght h3 {
  color: #b18e61;
  font-size: 24px;
  margin-bottom: 20px;
}

.fabric_rght p {
  margin: 0;
}

.fabric_rght h6 button {
  border-radius: 50px;
  width: 180px;
  margin: 15px 0;
}

.fabric_lft li a {
  color: #333;
  position: relative;
  width: 100%;
  display: block;
}

.fabric_lft li a b {
  position: absolute;
  right: 0;
  top: calc(100% - 50%);
  font-size: 13px;
  font-weight: 500;
}

.fabric_list li a {
  color: #494949;
}

.fabric_styl2 li.active,
.fabric_style li.active {
  position: relative;
}

.fabric_style li.active:after {
  content: "";
  position: absolute;
  top: 10px;
  border: 5px solid #264350;
  border-radius: 50px;
  right: 10px;
}

.fabric_styl2 li.active:after {
  content: "";
  position: absolute;
  top: 10px;
  border: 5px solid #d6d4d8;
  border-radius: 50px;
  right: 10px;
}

.fabric_list li.active a {
  font-weight: 700;
}

.fabric_list+#carouselExampleIndicators .carousel-inner {
  min-height: 450px;
}

.fabric_style li .media-body {
  text-align: right;
  align-items: revert;
  font-size: 11px;
}

.fabric_style li {
  display: inline-block;
  width: 48%;
  padding: 10px 30px;
  border-bottom: 1px solid #ccc;
  z-index: 0;
  margin: 0 2px;
}

.fabric_style li .media {
  align-items: center;
}

.fabric_styl2 {
  list-style-type: none;
  padding: 10px 7px;
  background: #d6d4d8;
  width: 30%;
  text-align: center;
  min-height: calc(100vh - 30vh);
  overflow-y: scroll;
  max-height: 450px;
  margin: 0;
}

.fabric_styl2 li.active p {
  width: 100%;
  display: block;
  float: left;
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 0;
  margin: 0px 0px;
}

.fabric_styl2 li p {
  font-size: 12px;
}

.fabric_styl2::-webkit-scrollbar {
  width: 5px;
  background: #eeeeee;
}

.fabric_styl2::-webkit-scrollbar-track {
  background: transparent;
}

.fabric_styl2::-webkit-scrollbar-thumb {
  background: transparent;
}

.fabric_lft::-webkit-scrollbar {
  width: 2px;
  background: #eeeeee;
}

.grub-bt1 .form-control::placeholder {
  font-size: 12px;
}

/* Track */
.fabric_lft::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
.fabric_lft::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 25px;
}

#nek3 li.active {
  border: 1px solid #264350;
}

#nek3 li.inactive {
  border: 1px solid red;
}

/* Handle on hover */
.fabric_lft::-webkit-scrollbar-thumb:hover,
.fabric_styl2::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

.fabric_style {
  width: 100%;
}

ul.fabric-splits {
  padding: 0;
}

.fab_split {
  display: flex;
}

.fabric_styl2 li {
  margin: 12px 6px;
  background: #fff;
  padding: 15px 15px;
  font-size: 13px;
}

.mono li {
  width: 24%;
}

.mono li img {
  max-width: 65px;
}

.btn_desg li {
  width: 35%;
  text-align: center;
}

.cuff_desg li p {
  font-size: 12px;
  padding: 0 15px;
}

.btn-black {
  background: #333;
  color: #fff;
  border-radius: 50px;
  padding: 6px 16px;
  margin: 0px 5px;
  font-size: 14px;
}

.share {
  background: transparent;
  border: 0;
  font-size: 22px;
}

/* shopping cart */
.shopping_crt ul li a {
  font-size: 14px;
  padding: 5px 15px;
  color: #333;
  position: relative;
}

.shopping_crt ul li {
  display: inline-block;
}

.shopping_crt ul {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;
}

.border-btm-cus {
  border-bottom: 1px solid #333;
}

.s_addr_001 h2 {
  border-bottom: 1px solid #ccc;
  padding: 20px 0px;
  margin-bottom: 30px;
}

.cart_001 {
  font-size: 27px;
}

.shopping_crt form {
  max-width: 900px;
  margin: 0 auto;
}

.shopping_crt ul li.active a {
  font-weight: 600;
}

.shopping_crt ul li a:after {
  content: "<";
  position: absolute;
  right: 0px;
}

.shopping_crt ul li:last-child a:after {
  content: none;
}

.shopping_crt .media img {
  max-width: 50%;
  padding: 0px 4px;
}

.shopping_crt .media-body h3 {
  color: #264350;
  font-weight: 700;
}

.shopping_crt .media-body h3,
.shopping_crt .media-body h5,
.shopping_crt .media-body p {
  margin: 8px 0px;
}

.shopping_crt .media-body p {
  font-size: 14px;
}

.btn-s-cus {
  border: 1px solid #6b6b6b;
  border-radius: 50px;
  font-size: 12px;
  margin: 4px 8px 0px 0px;
}

.text-pink {
  color: #ff465f;
}

.shipp-crt {
  text-align: end;
  width: 100%;
}

.shipp-crt tr td {
  padding: 10px 20px;
  text-align: left;
}

.c-measurements h3 {
  color: #264350;
  text-align: center;
  font-size: 18px;
  margin-bottom: 25px;
}

.c-measurements ul {
  margin: 0 auto;
  padding-top: 100px;
}

.c-measurements ul li {
  display: inline-block;
  width: 32%;
  border: 1px solid #bebebe;
  padding: 25px 25px;
  vertical-align: middle;
  min-height: 320px;
  margin: -3px -0.45px;
}

.c-measurements ul li h5 b {
  color: #264350;
}

.c-measurements ul li:nth-child(2) {
  min-height: 430px;
  border: 2px solid #7e7e7e;
}

.c-measurements ul li button.btn.btn-gren {
  border-radius: 50px;
  width: 120px;
  font-size: 14px;
  margin-top: 20px;
}

.c-measurements p {
  margin: 0;
  padding-top: 17px;
}

.c-measurements ul li h6 {
  position: absolute;
  top: 0;
  color: red;
}

.c-measurements ul li {
  position: relative;
  width: 33%;
}

.c-measurements ul li h6 {
  position: absolute;
  top: -35px;
  text-align: center;
  color: #fff;
  left: 50%;
  margin-left: -35px;
}

.c-measurements ul li h4 {
  color: #264350;
  font-size: 18px;
  font-weight: 600;
}

.c-measurements ul li p {
  font-size: 15px;
  text-align: center;
}

.c-measure-quick p,
.c-measure-quick h4 {
  text-align: center;
}

.c-measure-quick form .row {
  margin: 20px 0px;
}

.c-measure-quick form {
  max-width: 768px;
  margin: 30px auto;
}

.c-measure-quickp li p {
  margin: 8px 0px;
  text-align: left;
  min-height: 25px;
  position: relative;
}

span.plus {
  left: -23px;
}

span.minus {
  margin-left: -23px;
  left: 23px;
}

.c-measure-quickp li {
  border: 0 !important;
}

.c-measure-quickp li h3 {
  color: #909090;
  padding: 12px 0px;
}

.firt {
  border-right: 1px solid #aaa;
}

.payment form table {
  width: 100%;
}

.payment form h4,
.scnd h4 {
  font-size: 18px;
}

.payment form table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.payment form table tr td {
  padding: 10px 0;
}

.scnd table {
  width: 100%;
  border: 1px double #6c7d20;
  margin: 25px 40px;
}

.scnd table tr td {
  padding: 15px;
}

.order h2 {
  color: #264350;
  font-size: 22px;
  font-weight: 600;
}

.order table tr th img,
.accordion table tr th img {
  max-width: 100px;
  padding: 15px;
  background: #e3e3e3;
}

.order table tr td,
.accordion table tr td {
  vertical-align: middle;
}

.order table,
.accordion table {
  display: inline-table;
  overflow: auto;
}

/* address */
.y-addrs .card-footer p {
  margin: 0;
  font-size: 14px;
}

.y-addrs .card-footer {
  border: 0;
  background: transparent;
}

.y-addrs .card {
  margin-bottom: 25px;
  box-shadow: 1px 2px 6px rgb(187 187 187 / 50%);
  min-height: 310px;
}

.y-addrs .card-body h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.y-addrs .card p.active {
  margin: 0px;
  padding: 5px 15px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 15px;
  color: #ababab;
  font-weight: 600;
}

.card.tdt .card-body {
  vertical-align: middle;
  margin: auto;
  display: inline-table;
  transform: translateY(50%);
}

.default {
  background: whitesmoke;
  border: 1px solid #264350;
}

.tdt .card-body h3 b {
  font-size: 50px;
  color: #ccc;
  font-weight: 100;
}

.tdt .card-body h3 {
  text-align: center;
}

/* designs */
.bars {
  display: block;
}

.bars .opt {
  margin: 10px 0;
}

.bars .title {
  text-transform: uppercase;
  font-size: 11px;
  display: inherit;
}

.bars .bar {
  position: absolute;
  right: 10px;
  margin-top: -6px;
  color: #000;
}

.bars .bar .subar.active {
  opacity: 1;
}

.bars .bar .subar {
  background-color: #c19d56;
  height: 5px;
  width: 55px;
  float: left;
  margin-right: 3px;
  opacity: 0.3;
}

.pop_up_design {
  width: 100%;
  margin-left: 0;
  float: right;
  display: inline;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10000;
}

.pop_up_design .card {
  z-index: 7;
  max-width: 500px;
  height: 300px;
  position: relative;
  top: 70px;
  left: 90px;
  overflow: auto;
  padding: 15px;
}

.bars .bar .subar span {
  top: 8px;
  position: absolute;
  font-size: 12px;
}

.bg_imgg {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
}

.bg_imgg img {
  height: 484px;
  width: 100%;
}

.clse_btn {
  color: #ffffff;
  z-index: 26;
  position: absolute;
  right: 0;
  padding: 6px 12px;
  font-size: 25px;
  background: #264350;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 33px;
}

.pop_up_design {
  display: none;
}

/* plus , minus */

.minus,
.plus {
  width: 25px;
  height: 25px;
  background: #264350;
  padding: 0px 0px 0px 0px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: relative;
  top: 0px;
  color: #fff;
  border-radius: 50px;
}

.number input {
  width: 70px;
  height: 24px;
  border-radius: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.accordion ul li img {
  max-width: 50px;
}

.accordion ul {
  padding: 0;
  margin: 0;
}

.accordion ul li {
  display: inline-block;
  padding: 0px 25px;
  vertical-align: bottom;
}

.accordion h3 {
  font-size: 18px;
}

.accordion .panel p,
.accordion .panel address {
  margin: 10px 5px;
  font-size: 16px;
}

.accordion .panel p b {
  font-size: 16px;
}

.order-r-stats table th {
  font-size: 15px;
  font-weight: 700;
  padding: 8px 0px;
}

.order-r-stats table tr td {
  width: 70%;
  padding: 8px 0px;
}

.accordion .card-header {
  padding: 0;
  background: transparent;
}

.accordion .card-header button {
  color: #4f4c4c;
  font-weight: 500;
}

.accordion .card-body {
  padding: 35px 35px;
}

.accordion {
  border: none !important;
}

.order-h3 {
  font-size: 23px;
  color: #2a627b;
  font-weight: 700;
  margin-top: 20px;
}

.order-h3 a {
  font-size: 14px;
  padding: 12px;
  color: #333;
  font-weight: 400;
}

/* progress status */
#progressbar {
  overflow: hidden;
  counter-reset: step;
  text-align: center;
  display: flex;
  justify-content: start;
  align-items: center;
}


#progressbar li {
  list-style-type: none;
  color: #4E4E4E;
  text-transform: uppercase;
  font-size: 12px;
  width: 160px;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar .process_state {
  counter-increment: step;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 26px;
  display: block;
  list-style-type: none;
  color: #4E4E4E;
  background: #4E4E4E;
  border: 2px solid #4E4E4E;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar .process_state.notfilled {
  counter-increment: step;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 26px;
  display: block;
  list-style-type: none;
  color: #4E4E4E;
  background: #fff;
  border: 1px solid #4E4E4E;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar .process_state:after {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  content: "";
  width: 160px !important;
  height: 2px;
  background-color: #4E4E4E;
}

#progressbar .process_not::after {
  width: 0px !important;
  height: 0px;
}

/*progressbar connectors*/
/* #progressbar li:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #e7e7e7;
  position: absolute;
  left: -50%;
  top: 8px;
  z-index: -1;
  put it behind the numbers
} */

.order-status-confrm {
  font-family: "Roboto Condensed", sans-serif;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

li.active.step2:after {
  /* content: url(../images/order-icon.png); */
  position: absolute;
  top: 0;
}

#progressbar li.active span {
  position: absolute;
  top: 18px;
  left: calc(100% - 75%);
}

#progressbar li.active .icons-active {
  display: block;
  position: absolute;
  top: -50px;
  left: calc(20% - -15px);
  margin-left: 35px;
}

/* #progressbar li.active:before {
  position: relative;
  top: -8px;
} */

#progressbar li.step2:after {
  top: 8px;
}

#progressbar li.active.step3 {
  margin-bottom: 18px;
}

.icons-active {
  display: none;
}

.cus-slider2,
.cus-slider3,
.cus-slider4,
.cus-slider5,
.cus-slider6,
.cus-slider7,
.cus-slider8,
.cus-slider9,
.cus-slider10,
.cus-slider11,
.cus-slider12,
.cus-slider13,
.cus-slider14 {
  display: none;
}

.nec_k {
  background: #fff;
  height: calc(100vh - 13vh);
}

.nec_k ul.fabric_lft li {
  width: 45% !important;
  text-align: center;
  border: 1px solid #ccc;
  margin: 5px 5px;
  padding: 5px;
}

.nec_k_list li a {
  font-size: 14px;
}

.fa-check-circle {
  display: none;
}

.tick {
  position: relative;
}

.tick .fa-check-circle {
  display: inline;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #264350;
}

.nec_k_list li {
  padding: 5px 5px;
}

.cus-slider1-prve,
.cus-slider2-prve,
.cus-slider3-prve,
.cus-slider4-prve,
.cus-slider5-prve,
.cus-slider6-prve,
.cus-slider7-prve,
.cus-slider8-prve,
.cus-slider9-prve,
.cus-slider10-prve,
.cus-slider11-prve,
.cus-slider12-prve,
.cus-slider13-prve,
.cus-slider14-prve {
  background: #a5a5a5;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: #fff;
}

.cus-slider1-nxt,
.cus-slider2-nxt,
.cus-slider3-nxt,
.cus-slider4-nxt,
.cus-slider5-nxt,
.cus-slider6-nxt,
.cus-slider7-nxt,
.cus-slider8-nxt,
.cus-slider9-nxt,
.cus-slider10-nxt,
.cus-slider11-nxt,
.cus-slider12-nxt,
.cus-slider13-nxt,
.cus-slider14-nxt {
  background: #264350;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: white;
}

.cus-slider1-nxt-error,
.cus-slider2-nxt-error,
.cus-slider3-nxt-error,
.cus-slider4-nxt-error,
.cus-slider5-nxt-error,
.cus-slider6-nxt-error,
.cus-slider7-nxt-error,
.cus-slider8-nxt-error,
.cus-slider9-nxt-error,
.cus-slider10-nxt-error,
.cus-slider11-nxt-error,
.cus-slider12-nxt-error,
.cus-slider13-nxt-error,
.cus-slider14-nxt-error {
  background: #ccc;
  max-width: 150px;
  margin: 0 auto;
  padding: 8px 2px;
  border-radius: 50px;
  border: 0;
  color: white;
}

#nek1 h4 {
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin: 0;
}

.desk_neck {
  margin-bottom: 5px;
}

#nek1 p {
  font-size: 14px;
  margin-bottom: 10px;
}

input.value {
  height: 48px;
  text-align: center;
}

#btns li {
  list-style-type: none;
}

#btns li .btn {
  background: #ccc;
  margin: 10px 0px;
  width: 120px;
  border-radius: 50px;
}

#btns li .btn-gren {
  background: #264350 !important;
}

#f-two,
#f-three {
  display: none;
}

a.f-one.active,
a.f-two.active,
a.f-three.active {
  font-weight: 600;
}

.close-icon {
  display: none;
}

.collections .card .col-12 {
  text-align: center;
  padding: 21px 18px;
  min-height: 200px;
}

.collections .card .col-12:hover {
  box-shadow: 2px 2px 6px rgb(208 208 208 / 50%);
}

.grub-btn {
  padding: 7px 0;
}

.grub-btn .input-group-append .btn {
  padding: 4px 0px;
  font-size: 13px;
}

.grub-btn .form-control {
  height: 30px;
  border: 0;
  padding: 0 0 0 34px;
}

.btn-s-cus i {
  display: none;
}

.neck-add {
  display: none;
}

.neck-add i {
  font-size: 30px;
  color: #6c7d20;
  padding: 20px 0;
}

.mbl_neck {
  display: none;
}

#nek1 img {
  margin: 0 auto;
  height: calc(100vh - 58vh);
}

#measure {
  padding: 0;
}

.options_render li a img {
  max-width: 40px;
}

.options_render li a {
  color: #333;
  font-size: 14px;
}

.options_render li {
  display: block;
  padding: 6px 10px;
}

#cuff {
  display: none;
  position: absolute;
  left: 0;
  height: calc(100vh - 26vh);
  background: #fff;
  z-index: 5;
  overflow: auto;
}

#cuff::-webkit-scrollbar {
  width: 5px;
  background: #eee;
}

#cuff::-webkit-scrollbar-thumb {
  background: #aaa;
}

#cuff::-webkit-scrollbar-track {
  background: #eee;
}

.cuff_desg li img {
  cursor: pointer;
}

#cuff li img {
  transform: rotate(0deg);
  height: 105px;
  width: 100%;
}

#cuff li {
  display: inline-grid;
  width: 30%;
  margin: 10px;
}

#cuff p {
  padding: 6px 12px;
}

#cuff h4 a i {
  font-size: 25px;
  color: #333;
}

#cuff h4 a {
  text-align: revert;
  float: right;
  margin-right: 0;
}

#cuff h4 {
  font-size: 18px;
  font-weight: 400;
  width: 100%;
}

p.sub_titles {
  margin: 0;
  font-size: 13px;
  line-height: 1;
}

p.titles {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.btn_desg_vbl li img {
  max-width: 90px;
}

.btn_desg li img {
  cursor: pointer;
}

.btn_desg_vbl {
  display: none;
}

.btn_desg_vbl li {
  width: 48%;
}

/* j6-21 */

.fabric {
  padding: 5px 5px 0 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mbl-shar-ico {
  display: none;
}

.second-header .nw_menu {
  padding-bottom: 10px !important;
}

.second-header .nw_login {
  padding: 5px 25px 0 15px !important;
}

#nw_noti {
  position: absolute;
  top: 2px;
  font-size: 11px;
  width: 16px;
  height: 16px;
  background: #fff;
  text-align: center;
  color: #000;
  border-radius: 15px;
  border: 1px solid #333;
  right: 5px;
}

#fab_fxd_n p {
  font-size: 14px;
  margin: 0;
  padding: 8px 22px;
}

.sec_mnu .nav-link.active:before {
  left: 0;
  content: "";
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid #6d6767;
  width: 80px;
  left: 0px;
  bottom: -10px;
}

.sec_mnu .nav-link.active {
  /* border-bottom: 2px solid #333; */
  position: relative;
}

#fab_fxd_n p:nth-child(2) {
  text-align: right;
}

.nw_rt {
  text-align: right;
}

.nw_snd_btn button {
  background: #fff !important;
  border: 1px solid #ccc !important;
}

.nw_snd_btn1 {
  background: #fff !important;
  border: 1px solid transparent !important;
}

.nw_drp {
  width: 100%;
  border: 0;
}

#customSwitch1 {
  margin: 0 !important;
  height: 28px;
  width: 40px !important;
  left: 0px;
  top: 2px;
  z-index: 333;
}

#loginpop button p i,
.form .btn-blue p i {
  font-size: 21px;
}

#loginpop button p,
.form .btn-blue p {
  margin: 0;
  margin-right: 10px;
}

.payment input.form-control:disabled {
  background: transparent;
  border: 0;
  border-bottom: 3px solid #377f9f;
  border-radius: 0;
  margin-bottom: 35px;
  padding: 0px 0px;
}

.payment,
.order {
  font-family: "Roboto Condensed", sans-serif;
}

.order p {
  font-size: 19px;
}

.order_001 a {
  font-size: 24px;
  text-decoration: revert;
}

.c-measure-quickp ul {
  max-width: 485px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.mm-man ul {
  max-width: 485px !important;
}

.mm-man ul li {
  width: 50% !important;
}

.scnd h4 {
  padding: 0 15px;
}

.s_001_img {
  max-width: 65px;
  margin: 0 12px;
}

.nw_tbl_mbl #profile_tbl {
  text-align: center;
  width: 80%;
}

.nw_tbl_mbl #profile_tbl thead tr th {
  width: 150px;
}

.c-measure-quickp {
  height: 60vh;
  overflow: auto;
}

#measure .modal-body p {
  font-size: 20px;
  font-weight: 500;
}

#measure .modal-content {
  border-radius: 15px;
  max-width: 500px;
  padding: 17px;
  margin-top: 5vh !important;
  margin: 0 auto;
  border: none;
}

#measure .modal-body .modal-title {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}

#measure .modal-body .m_content p {
  font-size: 18px;
}

#measure .modal-body .m_content p b {
  color: #264350;
}

#measure .modal-body button {
  width: 100%;
}

#measure .modal-body .fa-long-arrow-alt-right {
  float: right;
  margin-top: 4px;
}

.nos .media-body .row .col-lg-2 {
  margin: 20px 0px 0px -20px;
}

#see_all {
  width: 80%;
  text-align: center;
}

#see_all a {
  color: #333;
}


ul.nw_zoom {
  z-index: 100;
  position: absolute;
  top: 5%;
  right: 0%;
  padding: 0;
}

ul.nw_zoom li img {
  width: 30px;
  margin: 8px 2px;
}

ul.nw_zoom li {
  list-style-type: none;
  text-align: center;
  margin-top: 20px;
}

ul.nw_zoom li p {
  font-size: 14px;
}

.modal-dialog.modal-lg.zoom_full_width {
  height: 100%;
}

.media .pi-shirt-containers {}

.media .pi_container .custom_shirt {
  width: 17%;
  left: 3%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cart_suit {
  width: 14% !important;
  left: 4% !important;
}

/* .modal-content.canva_zoom_hgt {
  min-height: 90%;
  height: 100%;
} */

@media only screen and (max-width: 1024px) {

  .c-measure-quickp {
    height: 530px;
    overflow: auto;
  }

  .fabric_styl2 li img {
    max-width: 125px;
    height: 56px;
    position: relative;
    top: 4px;
    left: -2px;
  }

  #cuff li {
    display: inline-block;
    width: 20%;
    margin: 10px;
  }
}

/* @end j6-21 */
@media only screen and (min-width: 768px) {
  .nw_menu .navbar-nav .dropdown-menu {
    position: absolute;
    width: 800px !important;
    left: 0px;
    transform: translate3d(-400px, 29px, 0px);
  }
}

/* @media only screen and (min-width: 540px) and (max-width: 720px) {
  img.img-fluid {
    max-height: 250px !important;
  }
}
@media only screen and (min-width: 280px) and (max-width: 653px) {
  img.img-fluid {
    max-height: 300px !important;
  }
} */

@media only screen and (min-width: 1250px) {
  .fabric_list+#carouselExampleIndicators .carousel-inner img {
    /* max-width: 250px; */
    /* width: calc(100% - 55%);
    margin: 0 auto; */
    width: calc(100% - 58%);
    margin: 0px auto;
    position: absolute;
    top: -30px;
  }

  .fabric_border {
    min-height: calc(100vh - 50vh) !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 991px) {
  .fabric_rght h6 button {
    border-radius: 50px;
    width: 140px;
    margin: 15px 0;
  }
}

@media only screen and (min-width: 1450px) {
  #nek1 img {
    margin: 0 auto;
    height: calc(100vh - 50vh);
  }
}

@media only screen and (min-width: 991px) {

  #l-r-dir,
  p.apply-btn {
    display: none;
  }

  .m2logo,
  .m2right {
    position: absolute;
  }

  .m2right {
    right: 0;
  }

  .m2logo {
    right: calc(100% - 60%);
  }

  .menu2_rgt {
    position: absolute;
    right: 0;
    top: 8px;
  }

  .menu2_cnt {
    position: absolute;
  }

  .fab-menu {
    padding: 0 120px;
    margin-top: -10px;
  }
}

@media only screen and (min-width: 1024px) {

  .fb-img,
  .fab-d-b {
    display: none;
  }

  .fab-d-n {
    display: inline;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {

  ul.nw_zoom li p {
    font-size: 12px;
  }

  .shopping_crt ul li a span {
    display: none;
  }

  .fabric_lft::-webkit-scrollbar {
    width: 2px;
    background: #eeeeee;
  }

  .c-measurements ul li {
    display: flex;
    min-height: auto;
    border: 0;
    border-bottom: 1px solid #bebebe;
    margin: 0;
  }

  .c-measurements ul li h6 {
    display: none;
  }

  .c-measurements ul li.active {
    border-top: 0 !important;
  }

  .c-measurements ul li p {
    padding: 0;
    text-align: left;
  }

  .c-measurements ul li {
    padding: 0;
  }

  .c-measurements ul li h3 {
    width: 30%;
    text-align: center;
  }

  .c-measurements ul li:nth-child(2) {
    min-height: auto;
    border: 0;
    border-bottom: 1px solid #bebebe;
  }

  .c-measure-list {
    display: inline;
    width: 65%;
    text-align: left;
    float: left;
  }

  .c-measurements ul li button.btn {
    display: none;
  }

  .nos p {
    display: none;
  }

  .nos button.btn {
    font-size: 12px;
    margin: 5px 7px;
  }

  #profile_tbl {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .nw_tbl_mbl {
    display: block;
    width: 100%;
    overflow-x: auto;
    max-width: 475px;
  }

  #profile_tbl {
    width: 375px;
    text-align: center;
  }

  #profile_tbl tr td,
  #profile_tbl tr th {
    font-size: 14px;
  }

  #profile_tbl tr td button {
    font-size: 13px;
  }

  .nw_ord_nm {
    display: none;
  }

  span.fb-txt,
  span.txt-arrow {
    display: none;
  }

  #nw_mbl_icon a i {
    display: inline;
  }

  .fabric_styl2::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }

  .meas_001 h2 {
    font-size: 23px;
    font-weight: 500;
  }

  form.meas_001 {
    padding: 20px 10px 0px 10px;
  }

  #m_001 {
    margin-top: 18px;
  }

  .meas_001 label {
    margin: 15px auto;
    font-size: 18px;
  }

  .media .pi_container .custom_shirt {
    /* left: 0;
    top: calc(100% - 100%);
    position: absolute; */
    width: 24%;
    /* left: 3%; */
    position: absolute;
    top: 49px !important;
    bottom: 0;
    margin: 0 !important;
    /* margin: auto; */
  }

  .cart_suit {
    width: 24% !important;
  }

}

@media only screen and (min-width: 1440px) {
  .media .pi_container .custom_shirt {
    width: 11%;
    left: 3%;
    position: absolute;
    top: 0;
    bottom: 15px;
    margin: auto;
  }

  .cart_suit {
    width: 11% !important;
    left: 3% !important;
  }
}

@media only screen and (max-width: 1024px) {

  .left-bar {
    display: block;
  }

  #fab_fxd_n p {
    width: 50%;
  }

  .custom-collar li.active:after {
    right: 10px !important;
    border: 6px solid #ccc !important;
  }

  .btn_desg li.active:after {
    right: 65px !important;
  }

  .casual-icons {
    position: fixed;
    top: 80px;
    right: 0;
    z-index: 9999 !important;
    margin: 10px 0;
    cursor: pointer;
  }

  .mbl-fxd-fab {
    height: auto !important;
  }

  .mbl-shar-ico {
    position: fixed !important;
    top: 58px !important;
  }

  #cuff {
    display: none;
    position: absolute;
    left: 0;
    height: 50%;
    background: #fff;
    z-index: 1;
    overflow: auto;
    bottom: -40px;
    width: 100%;
  }

  .canvas-loaderAlign {
    left: calc(100% - 50%);
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 30px;
    height: 30px;
  }

  .casual-icon {
    position: absolute;
    top: 46px;
    right: 15px;
    z-index: 999;
    bottom: auto;
    font-size: 12px;
    text-align: center;
  }

  .mbl-fxd-fab {
    position: fixed;
    bottom: 40px;
    width: 100%;
    background: #f7f7f7;
    padding-top: 6px;
    left: 0;
    z-index: 1023;
  }

  .fab-cnt-p,
  .fab-d-n {
    display: none;
  }

  .mbl-shar-ico {
    position: fixed;
    top: 58px;
    right: 0;
  }

  .fabric_rght {
    margin-top: 90px;
    padding-top: 20px;
  }

  .fabric_list+#carouselExampleIndicators ol.carousel-indicators {
    bottom: -95px;
  }

  span.fb-txt,
  span.txt-arrow {
    display: none;
  }

  .fabric_rght p {
    font-size: 12px;
    margin-bottom: 0 !important;
    text-align: right;
  }

  .casual-icons img {
    max-width: 46px !important;
  }

  #fab_fxd_n li {
    width: 49% !important;
  }

  .fabric_rght h4 {
    font-size: 18px;
  }

  .prc-fab {
    width: 30%;
    float: left;
  }

  .fabric_rght h3 {
    color: #283941;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .fabric_rght h6 button {
    border-radius: 50px;
    width: 78px;
    margin: 5px -5px;
    font-size: 11px;
    position: absolute;
    top: 0;
    right: 15px;
  }

  .m_p {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .tal_img {
    display: none;
  }

  .sho_p .tab-pane .card {
    margin-bottom: 15px;
  }

  .btn-s-cus {
    margin: 10px 8px 0px 0px;
  }

  .c-measurements ul li {
    width: 100%;
    margin-bottom: 25px;
    padding: 12px 12px;
  }

  #measure .modal-body {
    padding: 0px;
  }

  #measure .modal-body form {
    height: 100%;
    overflow-x: auto;
  }

  #measure .modal-title {
    font-size: 16px;
  }

  .c-measure-quickp ul {
    max-width: 550px;
    overflow: auto;
    display: block;
    width: 100%;
  }

  .c-measure-quickp ul li {
    display: inline-block;
    width: 33% !important;
    padding: 0px 6px;
  }

  .c-measurements ul li p {
    font-size: 12px;
  }

  .accordion ul li {
    display: block;
    padding: 12px 0;
  }

  /* #progressbar {
    display: none;
  } */

  #progressbar li {
    width: 160px;
    height: 60px;
  }

  #progressbar li p {
    font-size: 9px;
  }

  #progressbar .process_state:after {
    position: absolute;
    top: 10px;
    display: flex;
    align-items: center;
    content: "";
    width: 115px !important;
    height: 2px;
    background-color: #4E4E4E;
  }

  #progressbar .process_not::after {
    width: 0 !important;
    height: 0 !important;
  }

  .order-h3 {
    font-size: 18px;
  }

  .order-status-confrm .btn-gren {
    font-size: 12px;
  }

  .fabric_rght {
    margin-top: auto;
    padding-top: 20px;
  }


  .fabric_style li .media img {
    max-width: 50px;
  }

  .nec_k_list {
    position: static !important;
    background: white !important;
    padding: 0;
    margin: auto !important;
    text-align: center;
  }

  .nec_k {
    padding: 10px 0 !important;
  }

  span.f-close {
    position: absolute;
    right: 30px;
    top: 0px;
    display: none;
  }

  .close-icon {
    display: contents;
    position: absolute;
    right: 0px;
  }

  .fabric_list+#carouselExampleIndicators .carousel-inner img {
    height: auto;
  }

  span.f-close i {
    font-size: 18px;
  }

  .bars .bar .subar {
    background-color: #c19d56;
    height: 5px;
    width: 30px;
    float: left;
    margin-right: 3px;
    opacity: 0.3;
  }

  #nek1 img {
    margin: 0 auto;
    height: 200px;
  }

  #nek1 {
    min-height: auto;
  }
}

@media only screen and (max-width: 1250px) {
  .desk_menu {
    display: block;
  }

  .menu2 ul li {
    display: block;
  }

  #menu_desk {
    z-index: 10000;
  }

  .close_icon {
    position: absolute;
    top: 30px;
    right: 15px;
    font-size: 28px;
  }

  ul.car_t {
    float: left;
  }

  .sc_m,
  .car_t li input,
  .logo {
    display: none;
  }

  #menu_desk .col-lg-4.my-auto {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .second-header .nw_menu {
    box-shadow: none;
    width: 100%;
  }

  .sec_mnu .nav-link.active:before {
    display: none;
  }
}

@media only screen and (max-width: 575px) {

  .btn_desg li {
    width: 25%;
    text-align: center;
  }

  .fabric_style li {
    padding: 10px 20px;
  }

  .fabric_style li .media img {
    max-width: 34px;
  }

  .fabric_style li .media-body,
  .fabric_styl2 li {
    font-size: 12px;
    width: 160px !important;
    height: 78px;
    padding: 0px 8px;
  }

  .fabr-header h3 {
    display: none;
    margin-top: 45px;
  }

  .scnd table {
    margin: 25px 0px;
  }

}

@media only screen and (max-width: 991px) {

  .fabric_lft li {
    padding: 10px 3px 10px 0;
  }

  .fabric_lft li.cursor-pointer {
    width: 50% !important;
  }

  .fabric_styl2 li {
    margin: 3px 6px;
  }

  .fabric_style li .media-body {
    text-align: center;
    align-items: revert;
    position: relative;
    top: 35px;
  }

  .cus-slider1-nxt,
  .cus-slider2-nxt,
  .cus-slider3-nxt,
  .cus-slider4-nxt,
  .cus-slider5-nxt {
    height: 35px;
    padding: 4px 2px;
    color: #fff;
  }

  .cus-slider1-prve,
  .cus-slider2-prve,
  .cus-slider3-prve,
  .cus-slider4-prve,
  .cus-slider5-prve {
    height: 35px;
    padding: 4px 2px;
    color: #fff;
  }

  .min-header {
    display: none;
  }

  .mbl-fxd {
    position: fixed;
    top: 65px;
    right: 0px;
  }

  .c-measure-quickp li p {
    min-height: 38px;
    position: relative;
  }

  .c-measure-quickp {
    padding: 0;
  }

  #measure .btn.btn-secondary {
    margin: 12px 0px;
    padding: 3px 10px;
  }

  ul#btns {
    text-align: center;
    padding: 0;
  }

  .mbl_neck {
    display: block;
  }

  .desk_neck {
    display: none;
  }

  .neck-add {
    display: block;
    margin-bottom: 0;
    text-align: center;
  }

  ul.fabric_list {
    padding-top: 6px;
    padding-bottom: 6px;
    position: fixed;
    bottom: 0;
    background: #ccc;
    width: 100%;
    left: 0;
    margin-bottom: 0;
    z-index: 10;
  }

  /* .fab-stys {
    display: none;
  } */
  .nec_k_list li a {
    font-size: 26px;
    padding: 0 10px;
  }

  .nec_k_list li a i {
    font-size: 24px;
  }

  .nec_k_list li a {
    color: #939393;
  }

  .cus-slider1 h4,
  .cus-slider2 h4,
  .cus-slider3 h4,
  .cus-slider4 h4,
  .cus-slider5 h4 {
    padding: 10px 0;
  }

  .fab-stys {
    display: block;
  }

  #nek3 {
    display: -webkit-box !important;
    /* display: -ms-flexbox; */
    -webkit-box-direction: normal;
    overflow-x: scroll !important;
    position: relative;
  }

  .l-dir {
    border: 1px solid #ccc;
    background: #283941;
    color: #fff;
    padding: 4px 10px;
    font-size: 15px;
    margin: 0 10px;
  }

  .r-dir {
    border: 1px solid #ccc;
    background: #283941;
    color: #fff;
    padding: 4px 10px;
    font-size: 15px;
    margin: 10 0px;
  }

  #l-r-dir {
    position: absolute;
    bottom: calc(100% - 50%);
    right: 15px;
    z-index: 666;
    margin: 0;
    width: 90%;
  }

  button.r-dir {
    position: absolute;
    right: -12px;
  }

  button.l-dir {
    position: absolute;
    left: -32px;
  }

  .nec_k ul.fab-stys li {
    width: 27% !important;
    height: 70%;
    text-align: center;
    border: 1px solid #ccc;
  }

  .fabric_lft li a img,
  .fabric_lft li img {
    max-width: 40px;
  }

  .l-icons {
    max-width: 25px !important;
  }

  .fabric_lft li h6 {
    font-size: 11px;
  }

  .fabric_lft li p span {
    font-size: 12px;
  }

  .fabric_lft li p {
    margin: 0;
  }

  .fab-stys li {
    display: block;
  }

  .btn-s-cus {
    border-radius: 5px;
  }

  .btn-s-cus i {
    display: block;
  }

  .btn-s-cus span {
    display: none;
  }

  .shopping_crt ul {
    text-align: center;
    padding: 0;
  }

  .shopping_crt ul li a i {
    font-size: 22px;
    margin: 0 12px;
  }

  .second-menu .megamenu.show {
    transform: translate3d(-0px, -11px, 0px) !important;
  }

  .second-menu .dropdown-menu {
    min-width: 0px;
  }

  #banner_slider {
    position: relative;
    top: 0px;
  }

  .mobile_menu {
    padding: 12px 0;
    /* display: none; */
  }

  .mobile_menu i {
    font-size: 24px;
  }

  .foot ul {
    margin-bottom: 0;
    text-align: center;
  }

  .footer ul li {
    padding: 6px 8px;
  }

  .foot h6 {
    text-align: center;
  }

  /* shirts */
  .side_banner .i_banner {
    padding: 20px 45px;
    color: #fff;
  }

  .side_banner .i_banner p {
    font-size: 16px;
  }

  .side_banner .i_banner h1 {
    font-size: 26px;
  }

  .side_banner button {
    padding: 5px 15px;
    border-radius: 28px;
    font-size: 14px;
  }

  .how h2,
  .test_i h2,
  .s_heading h2,
  .mail_letter h2 {
    font-size: 22px;
  }

  .h_d_y_s .card p,
  .prdut_list .card ul li button {
    font-size: 14px;
  }

  .dgn_btn button:nth-child(2) {
    margin-top: 20px;
  }

  p {
    font-size: 14px;
  }

  .cus-slider1 p {
    padding: 5px 12px;
  }

  #btns li {
    list-style-type: none;
    display: inline-block;
    margin-top: 10px;
  }

  #btns li .btn {
    background: #ccc;
    margin: 5px 8px;
    width: 100px;
    border-radius: 50px;
  }

  input.value {
    height: 35px;
    border: 1px solid black;
  }

  p.apply-btn a {
    color: #fff;
  }

  p.apply-btn {
    position: absolute;
    bottom: -8px;
    width: 100%;
    text-align: center;
    padding: 6px 2px;
    background: #0085aa;
    left: 0;
  }
}

#nek3 li img {
  width: auto;
  height: auto;
}

@media only screen and (min-width: 1250px) {

  .mobile_menu,
  #close_x,
  #close_mega {
    display: none;
  }

  .measurement-modal .modal-body {
    min-height: calc(100vh - 15vh);
    min-height: 460px;
    overflow: auto;
  }
}

@media only screen and (max-width: 401px) and (min-width: 768px) {
  .shopping_crt .media img {
    max-width: 180px;
    padding: 10px 10px;
  }

  /* .rec-carousel{
      max-height: 250px;
  } */
}


@media only screen and (min-width: 1025px) {
  .right-bar {
    display: none;
  }

  .shopping_crt ul li a i {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .shopping_crt .media img {
    max-width: 70%;
    padding: 10px 10px;
    margin: 0px;
  }

  /* .media .pi-shirt-containers {
    height: 260px !important;
  } */

  .desk_menu {
    height: 20%;
    box-shadow: none;
  }
}

#initials_svg {
  right: 0;
  z-index: 1;
  left: 0;
  margin-top: 25%;
  position: absolute;
  width: 100%;
}

.active-mono::after {
  content: "";
  position: absolute;
  border-bottom: 4px solid red;
  bottom: 2px;
  width: 40px;
}

.custom-collar li {
  width: 24%;
  text-align: center;
  float: left;
  position: relative;
}

.custom-collar li.active,
.btn_desg li.active {
  position: relative;
}

.custom-collar li.active:after,
.btn_desg li.active:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 35px;
  border: 6px solid #264350;
  border-radius: 50%;
}

.casual-icons {
  margin: 20px 0 20px 0;
}

.btn_desg li.active:after {
  right: 130px;
}

.casual-icons img {
  max-width: 76px;
}

.casual-icons p {
  margin-bottom: 5px;
}

.mbl-fxd-fab {
  /* border-left: 1px solid #dcdcdc; */
  padding: 0 5px;
  height: 100%;
  margin: 0 auto;
}

.fabric_rght {
  border-left: 1px solid #dcdcdc;
}

.mbl-shar-ico {
  position: absolute;
  top: 50px;
}

.fabric_lft .cursor-pointer img {
  width: 100%;
  height: 100px;
  max-width: initial;
  border-radius: 5px;
}

.fabric_lft li.cursor-pointer {
  width: 32.5%;
}

.fabric_lft li.cursor-pointer p {
  position: absolute;
  top: 80px;
  text-align: center;
  width: 100%;
  color: #fff;
}

.fabric_lft li.cursor-pointer h6 {
  text-align: center;
  width: 100%;
  padding-top: 5px;
}

p#s_icons {
  position: absolute;
  left: 18px;
  top: 12px;
}

.fab_split .fabric_lft li img {
  max-width: 60px;
  height: auto;
}

.accordion__button {
  text-align: center !important;
  background-color: #ffffff !important;
  font-size: 25px;
}

.accordion__button:before {
  position: relative;
  left: 200px;
  display: none !important;
}

#cuff_close {
  float: right;
  margin-top: 10px;
}

.card .card_ll img {
  height: 382px;
}

/* filters search options */
#filters_collapse1 ul li,
#filters_collapse2 ul li,
#filters_collapse3 ul li,
#filters_collapse4 ul li,
#filters_collapse5 ul li,
#filters_collapse6 ul li {
  display: inline-block;
  padding: 15px;
  width: 49%;
}

#filters_collapse1 ul,
#filters_collapse2 ul {
  padding: 0 15px;
}

#filters_collapse2 ul li {
  display: inline-block;
  padding: 12px 45px;
  width: 50px;
  height: 50px;
}

#filters_collapse2 ul li span {
  /* background: url(../images/colors_filter_sprite_shirt.svg) no-repeat; */
  background-size: 100%;
  border: 2px solid transparent;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  display: block;
}

#filters_collapse2 ul li span.grey {
  background-position: 0 -1px !important;
}

#filters_collapse2 ul li span.blue {
  background-position: 0 -36px !important;
}

#filters_collapse2 ul li span.black {
  background-position: 0 -70px !important;
}

#filters_collapse2 ul li span.brown {
  background-position: 0 -105px !important;
}

#filters_collapse2 ul li span.green {
  background-position: 0 -313px !important;
}

#filters_collapse2 ul li span.white {
  background-position: 0 -139px !important;
}

#filters_collapse2 ul li span.purple {
  background-position: 0 -382px !important;
}

#filters_collapse2 ul li span.orange {
  background-position: 0 -174px !important;
}

#filters_collapse2 ul li span.beige {
  background-position: 0 -207px !important;
}

#filters_collapse2 ul li span.yellow {
  background-position: 0 -242px !important;
}

#filters_collapse2 ul li span.pink {
  background-position: 0 -277px !important;
}

#filters_collapse2 ul li span.red {
  background-position: 0 -346px !important;
}

span.title_c {
  float: right;
  font-size: 25px;
  color: #6d6d6d;
  font-weight: 100;
  line-height: 1;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.titles {
  padding: 16px 12px;
}

#accordion .card-header,
#accordion .card {
  background: #fff;
  border: 0;
}

.modal_custom {
  overflow: auto !important;
}

#accordion .card-header .btn::after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
  font-size: 17px;
  color: #717171;
}

#accordion .card-header .collapsed::after {
  content: "\f106";
}

#filters {
  position: absolute;
  left: 0;
  z-index: 100;
  background: #fff;
  top: 0px;
  box-shadow: 3px 3px 3px 1px rgb(202 202 202 / 50%);
  height: 100%;
  z-index: 99999;
}

#accordion {
  overflow-y: scroll;
  height: 445px;
}

#accordion .card-header {
  border-bottom: 1px solid #ccc;
  padding: 0;
}

#accordion .card-header .btn {
  padding: 15px 15px;
  width: 100%;
  color: #333;
  text-decoration: none;
  text-align: left;
}

#accordion .card-header .btn:focus {
  outline: 0;
  box-shadow: none;
}

#fab_fxd_n li button {
  width: 90%;
  border: 0;
  padding: 6px 12px;
  font-size: 18px;
  color: #000;
  letter-spacing: 2px;
  border-radius: 8px;
}

#fab_fxd_n li {
  display: inline-block;
  width: 50%;
  text-align: center;
}

#measure-form .add_content {
  height: 50vh;
  overflow: auto;
}

#fab_fxd_n {
  position: fixed;
  bottom: 1px;
  width: 50%;
  background: #fff;
  margin: 0;
  left: 0;
  /* z-index: 100; */
  padding: 0;
}

#fab_fxd_n li:last-child button {
  background: #333333;
  color: #fff;
}

li.cursor-pointer.active {
  background: white;
  border: 1px solid #264350;
  border-radius: 5px
}

li.cursor-pointer.active p {
  padding-top: 10px;
}

.fabric_styl2 .cursor-pointer.active {
  display: inherit;
}

.navTemplateDesign {
  z-index: 9;
}

@media (min-height: 801px) and (max-height: 900px) {
  .fabric {
    height: 78vh;
  }
}

@media (min-height: 901px) and (max-height: 1100px) {
  .fabric {
    height: 100%;
  }
}

@media (min-height: 600px) and (min-width: 600px) {
  .sport-jacket {
    height: 100vh;
  }
}

.nw_login .empty {
  height: 23px;
}

.pant_canvas ul.nw_zoom {
  z-index: 100;
  position: absolute;
  top: 5%;
  right: 0%;
  padding: 0;
}

@media screen and (max-width: 400px) and (min-width: 300px) {

  /* .carousel-indicators {
    display: none !important;
  } */

  .shopping_crt,
  .shopping_crt .media .col-4 {
    padding: 0;
  }
}

@media screen and (max-width: 426px) and (min-width: 400px) {

  /* .carousel-indicators {
    display: none !important;
  } */

  .shopping_crt,
  .shopping_crt .media .col-4 {
    padding: 0;
  }

  /* .rec-carousel {
    max-height: 250px !important;
  } */
}


@media screen and (max-width: 1025px) and (min-width: 768px) {
  .nw_menu .navbar-brand {
    line-height: 1 !important;
    font-size: 30px !important;
  }

  #fab_fxd_n {
    width: 50%;
  }
}

.verify-casual .carousel,
.verify-casual .carousel .carousel-inner,
.verify-casual .carousel .carousel-inner .carousel-item {
  height: 100%;
}

#f-one ul.fabric-splits {
  margin: 0;
}

.fabric #f-one {
  padding: 0;
}


@media screen and (max-width: 1024px) and (min-width: 769px) {

  ul.nw_zoom li img {
    width: 40px;
    margin: 8px 2px;
  }
}


@media screen and (max-width: 426px) and (min-width: 376px) {
  ul.nw_zoom li img {
    width: 26px;
    margin: 0px 2px;
  }

}

@media (max-width: 575.98px) {

  /* .accordion .card-body{
    padding: 0 !important;
  } */
  #cart_deatils .modal-content {
    margin-top: 0vh !important;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
  }



  ul.fabric_lft.me_chest {
    height: 100%;
  }

  .shopping_crt .media img {
    max-width: 80%;
    padding: 0px 4px;
  }

  .close span {
    font-size: 30px;
  }

  #measure .modal-content {
    border-radius: 0;
    max-width: 500px;
    padding: 20px;
    /* height: 130vh; */
    margin-top: 0 !important;
    margin: 0;
    width: 100vw;
    border: none;
  }

  .nos_pop .modal-body {
    height: 480px !important;
    overflow: auto;
  }

  .fabric_lft.fabric_style#fabric_style {
    height: 15vh;
    overflow: auto;
  }

  #fab_fxd_n {
    width: 100% !important;
    /* position: initial !important; */
  }

  #measure-form .add_content {
    height: 75vh;
    overflow: auto;
  }

  /* .widget-app-container{
    bottom: -14px !important;
  } */

  /* .rec-carousel {
    max-height: 250px !important;
  } */
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .second-header {
    height: 10vh;
  }

  ul.fabric_lft.fabric_border {
    height: 27vh;
  }

  .fabric .verify-casual {
    height: 50vh !important;
  }

  .fabric #f-one {
    height: 32vh !important;
  }

  .vh_80 {
    height: 100vh !important;
  }

  .fabric_lft.fabric_style#fabric_style {
    height: 23vh;
  }

  ul.fabric_lft#fab-font-ht {
    height: 30vh;
  }

  .fabric .suit-monogram {
    width: 100%;
    top: 60px;
    left: 0;
  }

  .ml-3.breadcum.d-none.d-sm-block {
    display: none !important;
  }

  /* img .img-fluid {
    max-height: 400px !important;
  } */
}

@media screen and (max-width: 400px) and (max-height: 850px) {
  #fab_fxd_n {
    bottom: 20px;
    z-index: 5;
  }

  .widget-app-container {
    bottom: -45px;
    transform: scale(0.5)
  }

  .dvBAhE {
    right: -55px;
  }
}